import CalibrationStyled from "./styled";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { observer } from "mobx-react";
import { CheckboxOptionType, Form, Radio, Select } from "antd";
import { DefaultOptionType } from "antd/lib/select";

export type FluidsFilterField = "timeRange" | "mixer" | "recipe";

interface Props extends RouteComponentProps<any> {
  filters: FluidsFilterField[];
  onChange: (changedValues: any, values: any) => void;
}

const mixerOptions: DefaultOptionType[] = [
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
];
const recipesOptions: DefaultOptionType[] = [
  { value: "recipe-1", label: "מתכון 1" },
  { value: "recipe-2", label: "מתכון 2" },
];
const timeRangeOptions: CheckboxOptionType[] = [
  { value: "daily", label: "יומי" },
  { value: "weekly", label: "שבועי" },
  { value: "sum", label: "סיכום" },
];

interface filterFields {
  timeRange?: string;
  mixer?: string;
  cementType?: string;
  recipe?: string;
}
const filterInitialvalues: filterFields = {
  timeRange: "daily",
  cementType: null,
  mixer: null,
  recipe: null,
};

const FluidsPredictionFilter = observer(({ onChange, filters }: any) => {
  return (
    <Form
      onValuesChange={(changedValues, values) =>
        onChange(changedValues, values)
      }
      initialValues={filterInitialvalues}
    >
      {filters.includes("mixer") && (
        <Form.Item name="mixer">
          <Select options={mixerOptions} placeholder="בחר ערבל" />
        </Form.Item>
      )}

      {filters.includes("recipe") && (
        <Form.Item name="recipe">
          <Select options={recipesOptions} placeholder="בחר מתכון" />
        </Form.Item>
      )}
      {filters.includes("timeRange") && (
        <Form.Item name="timeRange">
          <Radio.Group
            buttonStyle="solid"
            options={timeRangeOptions}
            optionType="button"
          />
        </Form.Item>
      )}
    </Form>
  );
});
export default withRouter(FluidsPredictionFilter);
