import BaseService from "../base.service";

class UsersService extends BaseService {
  constructor(baseUrl: string = "") {
    super(baseUrl);
  }

  importFromCsv = (users: any[]) => this.post(`/users/import`, users);
}

export default UsersService;
