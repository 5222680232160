import styled from "styled-components";

const MessurmentStyled = styled.div`
  background: #ca4007;
  height: 100vh;

  .flex.top {
    justify-content: space-between;
    padding: 22px;
  }

  .center {
    margin: 0 auto;
    width: 200px;
    align-items: center;
    flex-direction: column;

    .rpm svg {
      font-size: 166px;
      color: #800a0a;
    }

    .title-rpm {
      text-align: center;
      font-size: 26px;
    }

    .ant-select.ant-select-single.ant-select-show-arrow {
      margin: 0 auto;
      display: block;
    }

    .rpm-title {
      text-align: center;
      font-size: 45px;
      font-weight: bold;
      color: #fff;
      margin-top: 56px;
    }

    .messure-spped {
      text-align: center;
      margin-top: 20px;
      font-size: 34px;
    }
  }

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
      font-size: 32px;
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;
    padding: 20px;
    position: absolute;
    bottom: 20px;
    width: 100%;

    button.ant-btn {
      border-radius: 10px;
      margin-top: 10px;
      background: transparent;
      border: 1px solid #070707;
      color: #ffffff;
    }
  }
`;

export { MessurmentStyled };
