import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import enTranslation from "./locales/en.json";
import heTranslation from "./locales/he.json";
import elTranslation from "./locales/el.json";

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources: {
      he: { translation: heTranslation },
      en: { translation: enTranslation },
      el: { translation: elTranslation },
    },

    fallbackLng: "he",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
