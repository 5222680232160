import BaseModel from "../base.model";
import BaseHttpService from "../../services/base.http.service";
import BaseStore from "../base.store";
import { IUser } from "./user.interface";
import { observable, computed } from "mobx";

class User extends BaseModel implements IUser {
  @observable id: number | null = null;
  @observable kID: number | null = null;
  @observable identity_id: string | null = null;
  @observable name: string | null = null;
  @observable phone: string | null = null;
  @observable email: string | null = null;
  @observable role: number | null = null;
  @observable is_admin: number | null = null;
  @observable job: string | null = null;
  @observable one_time_password: string | null = null;
  @observable s_token: string | null = null;
  @observable status: string | null = null;
  @observable createdAt: string | null = null;
  @observable primaryColor: string | null = null;
  @observable secondaryColor: string | null = null;

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(httpService: BaseHttpService, store: BaseStore, data: any[]) {
    super(httpService, store, data);
    this._updateData(data);
  }

  responseModelsKey(): string {
    return "users";
  }

  get url(): string {
    return "/users";
  }

  @computed
  get toJson(): { [key: string]: any } {
    return this._toJson({
      id: this.id,
      identity_id: this.identity_id,
      name: this.name,
      phone: this.phone,
      email: this.email,
      role: this.role,
      is_admin: this.is_admin,
      job: this.job,
      one_time_password: this.one_time_password,
      s_token: this.s_token,
      status: this.status,
    });
  }
}

export default User;
