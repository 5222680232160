import styled from "styled-components";

const ConeLineStyled = styled.div<{ coneToPrediction: number }>`
  .cone-row-time {
    display: flex;
    felx-direction: row;
  }

  .row-prediction {
    flex-direction: row !important;
    gap: 20px;
  }

 

  td.ant-table-cell {
    padding: 0px !important;
  }

  th.ant-table-cell {
    padding: 3px !important;
  }

  .ant-statistic-content-value {
    font-size: 17px;
  }

  span.ant-statistic-content-value {
    color: ${(p: any) => {
      console.log(p.coneToPrediction);

      return p.coneToPrediction > 10 ? "#a12a2a" : "green";
    }};
  }
`;
export default ConeLineStyled;
