import { createBrowserHistory } from "history";
import { Provider } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Router } from "react-router";
import "./App.scss";
import PagesRouter from "./pages/PagesRouter/PagesRouter";
import AuthService from "./stores/auth/auth.service";
import AuthStore from "./stores/auth/auth.store";
import SensorService from "./stores/sensor/sensor.service";
import SensorStore from "./stores/sensor/sensor.store";
import UsersService from "./stores/users/user.service";
import UsersStore from "./stores/users/user.store";
import { QueryClient, QueryClientProvider } from "react-query";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

import SettingsContext from "./global/SettingsContext";
const apiUrl = process.env.REACT_APP_API_URL;
const usersStore = new UsersStore(new UsersService(apiUrl));
const authStore = new AuthStore(new AuthService(apiUrl), usersStore);
const sensorStore = new SensorStore(new SensorService(apiUrl));

const stores = {
  usersStore,
  authStore,
  sensorStore,
};
const queryClient = new QueryClient();

const availableLanguages = ["he", "en", "el"];

const history = createBrowserHistory();

function App() {
  const [lng, setlng] = useState("");

  useEffect(() => {
    const langInSearch = new URLSearchParams(window.location.search).get(
      "lang"
    );
    if (langInSearch && availableLanguages.includes(langInSearch)) {
      i18n.changeLanguage(langInSearch);
      setlng(langInSearch);
    } else {
      const currentLanguage = i18n.language.split("-")[0];
      setlng(currentLanguage);
    }
  }, []);

  return (
    <I18nextProvider i18n={i18n}>
      <SettingsContext.Provider value={{ lng }}>
        <Provider {...stores}>
          <QueryClientProvider client={queryClient}>
            <div className={`app ${lng}`}>
              <Router history={history}>
                <PagesRouter />
              </Router>
            </div>
          </QueryClientProvider>
        </Provider>
      </SettingsContext.Provider>
    </I18nextProvider>
  );
}

export default App;
