import styled from "styled-components";

const ConcreteSlumpStyled = styled.div`
  .title {
    display: flex;
    justify-content: space-between;
    position: relative;
  }



  .number-title {
    color: #fd651c;
    font-size: 29px;
    font-weight: bold;
  }

  .green {
    color: #057a55 !important;
  }

  .yellow {
    color: #faca15 !important;
  }

  .display-chart {
    background: #79947942;
}

  .orange {
    color: #fd651c !important;
  }
  .red {
    color: #e02424 !important;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .ratio-type {
    color: #9ca3af;
    font-weight: bold;
    border-bottom: #9ca3af 2px solid;
  }

  .apexcharts-xaxis-label {
    cursor: pointer;
  }
  .ratio-type-selected {
    color: #2d2d2d;
    border-bottom: #ff6700 4px solid;
  }

  button.ant-btn.ant-btn-dashed.ant-btn-sm.ant-btn-dangerous {
    border: navajowhite;
    font-size: 15px;
    height: 25px;
    color: #403e3e;
  }

  .flex.gap10 {
    border-bottom: 1px solid #c7c7c7;
    padding: 10px;
  }

  .flex1.flexCol div {
    line-height: 1.2;
  }

  .flex.boxes {
    width: 100%;
    margin-top: 22px;
    gap: 20px;
  }

  .total {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: calc(100% - 40px);
    align-items: center;
    justify-content: center;
    top: 180px;
  }


 

  .slider-change {
    width: 20px;
    height: 36px;
}
  .total div {
    font-size: 19px;
    font-weight: bold;
  }

  .total-del {
    font-size: 38px !important;
  }

  .box1 .title {
    margin-bottom: 32px;
  }

  .box1 {
    background: #fff;
    padding: 20px;
    min-width: 357px;
  }

  .flex.box2 {
    background: #fff;
    padding: 20px;
  }

  .item-results .flex.gap10 {
    background: white;
    padding: 10px;
    .value.flex {
      color: #ff6700;
      font-weight: bold;
      font-size: 20px;
      line-height: 1;
    }
  }
  .settingsIcon {
    font-size: 2rem;
  }

  .flex.page-title.gap20 {
    height: 85px;
  }

  .info-wrap .flex {
    margin: 0 auto;
    align-items: center;
  }

  .title.flex1 {
    justify-content: end;
  }

  .settingsContainer {
    padding: 5px;
    position: absolute;
    top: 50px;
    left: 0;
    border: 1px solid #000000;
    border-radius: 5px;
  }

  .settingsContainer > div {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  td.ant-table-cell {
    font-size: 14px;
  }

  .chart {
    width: 400px;
    height: 100px;
  }

  .ant-form-item:last-child {
    width: 200px;
  }

  .blue-level {
    background: #6c6c6c;
    padding: 5px;
    color: #fff;
    width: 148px;
    text-align: center;
    display: block;
  }

  .green-level {
    background: #8a8a8d;
    padding: 5px;
    color: #fff;
    width: 148px;
    text-align: center;
    display: block;
  }

  .orange-level {
    background: #ed7e33;
    padding: 5px;
    color: #fff;
    width: 148px;
    text-align: center;
    display: block;
  }

  .red-level {
    background: #323234;
    padding: 5px;
    color: #fff;
    width: 148px;
    text-align: center;
    display: block;
  }

  .apexcharts-legend {
    display: none;
  }

  @media screen and (max-width: 600px) {
    .unusuel-slump {
      flex-direction: column;
    }

    .flex.page-title.gap20 {
      height: 17px !important;
    }
    .unusuel-slump .flex.flexCol {
      width: 100%;
    }

    .box1.relative {
      min-width: 100%;
    }

    .flex.boxes {
      flex-direction: column;
    }

    #apexchartsnkja5gmk {
      width: 100% !important;
    }

    .total {
      left: 3vw;
  }

  .flex.gap5.align-center {
    display: none!important;
}

.apexcharts-toolbar {
    display: none;
}
  }

  .ant-table-cell {
    border-left: none !important;
    border-bottom: none !important;
  }
`;

export { ConcreteSlumpStyled };
