import {
  Button,
  Collapse,
  Drawer,
  Form,
  FormInstance,
  Input,
  Table,
  Tabs,
} from "antd";
import { observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { ConcreteTestingStyled } from "./styled";

const { TabPane } = Tabs;
const { Panel } = Collapse;

interface Props extends RouteComponentProps<any> {}

@observer
class ConcreteTesting extends React.PureComponent<Props, any> {
  @observable visible: boolean = false;
  @observable mixer_number: number = 1;
  @observable data: any = [];
  @observable rpm: any = [];
  @observable light_active: number = 1;
  @observable data_settings: any = {
    speed: 5,
    duration: 2,
    deviation: 5,
  };

  @observable rpm_duration: number = 0;

  @observable formRef = React.createRef<FormInstance>();

  componentDidMount = async () => {
    const check_data_settings = this.checkDataSettings();
    if (check_data_settings) {
      this.getDataSettings();
    } else {
      this.setDataSettings();
    }

    try {
      let index = 1;
      let data = this.data;
      let items: any = [];
      setInterval(() => {
        const rpm = (Math.random() * (4.12 - 6.2) + 6.2).toFixed(2);
        this.rpm = rpm;
        console.log(this.rpm_duration);

        if (this.rpm < this.data_settings.speed - 2) {
          this.rpm_duration = 0;
          this.light_active = 1;
        }

        if (this.rpm > this.data_settings.speed) {
          this.rpm_duration++;
          this.light_active = 2;
        }

        if (
          this.rpm > this.data_settings.speed &&
          this.rpm_duration > this.data_settings.duration
        ) {
          this.light_active = 3;
        }

        items.push({
          i: 1,
          id: index++,
          ratio: (Math.random() * (3.12 - 6.2) + 6.2).toFixed(2),
          rpm: rpm,
          filling_degree: (Math.random() * (5 - 9) + 9).toFixed(2),
        });
        this.data = items;
      }, 1000);
    } catch (error) {
      console.error(error);
    }
  };

  checkDataSettings = () => {
    let data_settings_Object = localStorage.getItem("data_settings");
    data_settings_Object = JSON.parse(data_settings_Object);

    this.formRef?.current?.setFieldsValue(data_settings_Object);
    return data_settings_Object;
  };

  getDataSettings = () => {
    let data_settings_Object = localStorage.getItem("data_settings");
    data_settings_Object = JSON.parse(data_settings_Object);
    this.data_settings = data_settings_Object;
    this.formRef?.current?.setFieldsValue(this.data_settings);
    return data_settings_Object;
  };

  setDataSettings = (data: null = null) => {
    localStorage.setItem(
      "data_settings",
      JSON.stringify(data ? data : this.data_settings)
    );
  };

  columns = [
    {
      title: "Id",
      width: 200,
      dataIndex: "id",
    },
    {
      title: "Rpm",
      dataIndex: "rpm",
    },
    {
      title: "Force",
      dataIndex: "filling_degree",
    },
  ];

  changeSettings = (values: any) => {
    console.log(values);
    this.setDataSettings(values);
  };

  messareForm = () => {
    return (
      <Form className="padding20 form-settings" onFinish={this.changeSettings}>
        <Form.Item label="קונוס 1" name="speed">
          <Input size="large" />
        </Form.Item>

        <Form.Item name="duration" label="קונוס 2">
          <Input size="large" />
        </Form.Item>

        <Form.Item name="deviation" label="קונוס 3">
          <Input size="large" />
        </Form.Item>

        <Button className="button-center full" htmlType="submit">
          שמור
        </Button>
      </Form>
    );
  };

  render() {
    return (
      <ConcreteTestingStyled>
        <div className="flex light-wrapper">
          <div className="lights">
            <div id="traffic-light">
              <input
                type="radio"
                name="traffic-light-color"
                id="color1"
                value="color1"
                className={this.light_active === 1 ? "active1" : ""}
              />
              <input
                type="radio"
                name="traffic-light-color"
                id="color2"
                value="color2"
                className={this.light_active === 2 ? "active2" : ""}
              />
              <input
                type="radio"
                name="traffic-light-color"
                id="color3"
                value="colo3"
                className={this.light_active === 3 ? "active3" : ""}
              />
            </div>
            <div className="wrapp-rpm">
              <div className="rpm flex">
                <div className="name">RPM: </div>
                <div className="number">{this.rpm}</div>{" "}
              </div>
              <Button
                className="br-blue2 start-action radius blue2 transparent full marginTop20 "
                htmlType="submit"
                disabled={this.light_active === 3 ? false : true}
              >
                התחל מדידה
              </Button>
            </div>
          </div>
        </div>

        <Tabs defaultActiveKey="1">
          <TabPane tab="נתוני אמת" key="1">
            <div className="flex full">
              <Table
                sortDirections={["descend"]}
                dataSource={this.data.reverse()}
                columns={this.columns}
              />
            </div>
          </TabPane>

          <TabPane tab="מדידות" key="2">
            <div className="full">
              <div className=" bold textCenter f14 marginBottom20">
                מציג את עשר הבדיקות האחרונות
              </div>
              <Collapse
                bordered={false}
                defaultActiveKey={["1"]}
                className="site-collapse-custom-collapse"
              >
                <Panel
                  header="12:30 - 13:00"
                  key="1"
                  className="site-collapse-custom-panel"
                >
                  {this.messareForm()}
                </Panel>
                <Panel
                  header="14:30 - 15:15"
                  key="2"
                  className="site-collapse-custom-panel"
                >
                  {this.messareForm()}
                </Panel>
                <Panel
                  header="17:30 - 18:00"
                  key="3"
                  className="site-collapse-custom-panel"
                >
                  {this.messareForm()}
                </Panel>
              </Collapse>
            </div>
          </TabPane>
          <TabPane tab="הגדרות" key="3">
            <Form
              ref={this.formRef}
              name="control-ref"
              className="padding20 form-settings"
              onFinish={this.changeSettings}
            >
              <Form.Item label="מהירות סיבוב" name="speed">
                <Input
                  placeholder={this.data_settings?.speed}
                  size="large"
                  addonAfter='סל"ד'
                />
              </Form.Item>

              <Form.Item name="duration" label="משך זמן">
                <Input
                  placeholder={this.data_settings?.duration}
                  size="large"
                  addonAfter="שניות"
                />
              </Form.Item>

              <Form.Item name="deviation" label="אחוז סטיה">
                <Input
                  placeholder={this.data_settings?.deviation}
                  size="large"
                  addonAfter="$"
                />
              </Form.Item>

              <Button className="button-center full" htmlType="submit">
                עדכן
              </Button>
            </Form>
          </TabPane>
        </Tabs>
      </ConcreteTestingStyled>
    );
  }
}
export default withRouter(ConcreteTesting);
