import styled from "styled-components";

const ConcreteViewStyled = styled.div`
  .sensor_group_1 {
    min-width: 250px;
    background: #fff;
    padding: 10px;
    position: absolute;
    width: 200px;
    right: 0;
    height: 100vh;
    top: 0;
  }
  .sensor_status_item .sensor {
    border-radius: 10px;
    padding: 9px;
    background: #e5e7eb;
    font-weight: bold;
    border: 1px solid transparent;
    :hover {
      border: 1px solid #ff6700;
    }
  }

  .title {
    font-size: 32px;
    line-height: 45px;
  }

  .height-scroll {
    flex-direction: column;
    gap: 15px;
    height: 78vh;
    overflow-y: scroll;
  }

  .sensor_status.scrollbar {
    width: 100%;
    overflow-y: auto;
    padding-bottom: 18px;
  }

  .close_bar {
    width: 70px;
    min-width: 70px;
  }

  .flex.select-filter {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    padding-bottom: 1px;
    border-bottom: 2px solid #c5c3c3;
    font-size: 18px;
    font-weight: 600;
  }

  .sensor_status.scrollbar {
    width: 100%;
    overflow-y: auto;
    padding-bottom: 18px;
  }

  .wrap-page {
    width: 64vw;
  }

  .full_data {
    width: 75vw !important;
    .sensor_status.scrollbar {
      width: 72.3vw;
    }
  }
  .sensor_status_item {
    height: 100vh;
    overflow-y: auto;
    padding: 0px !important;

    .sensor_name {
      background: #3c3b3a53;
      width: 100%;
      text-align: center;
    }

    ul {
      padding: 0px;

      .name {
        font-weight: 500;
      }
    }
  }

  .item.active:after {
    bottom: -3px;
    content: "";
    background: #ff6700;
    width: 154%;
    height: 4px;
    position: absolute;
    left: -6px;
    position: absolute;
  }

  .item.active {
    position: relative;
  }

  .arrow {
    max-width: 39px;
  }
`;

export { ConcreteViewStyled };
