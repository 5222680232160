import { Table } from "antd";
import moment from "moment";
import { observer } from "mobx-react";
import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import TrackNumber from "../../Dashboard/TrackNumber/TrackNumber";
import { MessagesStyled } from "./styled";

interface Props extends RouteComponentProps<any> {
  messages: any;
  sensors_data: any;
  changeTrackNumber: (track_number: string) => void;
}

@observer
class Messages extends React.PureComponent<Props, any> {
  columns = [
    {
      title: "הודעה",
      dataIndex: "flow_message",
    },

    {
      title: "תאריך",
      dataIndex: "timestamp",
      render: (type: string, record: any) => {
        return moment(record.timestamp).format("DD/MM/YY , HH:mm");
      },
    },
  ];

  componentDidMount = async () => {
    try {
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    return (
      <MessagesStyled>
        <TrackNumber
          showLabel={true}
          sensors={this.props.sensors_data}
          changeTackNumber={(track_number) =>
            this.props.changeTrackNumber(track_number)
          }
        />
        <Table
          dataSource={this.props.messages}
          columns={this.columns}
        />
      </MessagesStyled>
    );
  }
}
export default withRouter(Messages);
