import styled from "styled-components";

const TrackNumberStyled = styled.div`
  display: flex;
  gap: 20px;

  .ant-select-selector {
    width: 100%;
    height: 32px;
    padding: 0 11px;
    color: #ff6700;
    font-size: 20px;
    font-weight: bold;
  }

  @media screen and (max-width: 600px) {
    justify-content: center;
    margin-top: 34px;
  }
`;

export { TrackNumberStyled };
