import React from "react";
import { Redirect, Route } from "react-router-dom";

import { inject, observer } from "mobx-react";
import AuthStore from "../../stores/auth/auth.store";

interface Props {
  authStore?: AuthStore;
  component?: any;
  loginPath?: string;
  path?: string;
  location?: any;
}

@inject("authStore")
@observer
class PrivateRoute extends React.PureComponent<Props, any> {
  render() {
    const { component: Component, loginPath, ...rest } = this.props;

    // const isAuthenticated = this.props!.authStore!.isAuthenticated;
    let user = JSON.parse(localStorage.getItem("b_user"));
    const check_user = user?.hasOwnProperty("role_info");
    if (check_user) {
      this.props!.authStore!.storeUser(user);
    }
    // console.log("in private route",rest,check_user,loginPath);

    return (
      <Route
        exact
        {...rest}
        render={(props) =>
          // TODO: make it prettier
          check_user ? (
            typeof Component === "object" ? (
              Component
            ) : (
              <Component />
            )
          ) : (
            <Redirect to={loginPath} />
          )
        }
      />
    );
  }
}

export default PrivateRoute;
