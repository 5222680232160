import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { observer } from "mobx-react";
import { Tooltip } from "antd";
import SlumpSliderStyled from "./SlumpSliderStyled";
import { CaretDownOutlined } from "@ant-design/icons";

interface Props extends RouteComponentProps<any> {
  slumpRate: number;
}

@observer
class SlumpSlider extends React.PureComponent<Props, any> {

  slumRate = (slumpRate: number): number => {
    let slump = slumpRate / 25;
    let result = slump * 10;
    console.log({result})
    const return_data = Math.max(1, Math.min(90, result));
    console.log({return_data})
    // Ensure the return value is between 1 and 9
    return return_data;
  };
  render() {

    return (
      <SlumpSliderStyled slumpRate={this.slumRate(this.props.slumpRate)}>
        {this.props.slumpRate ? <CaretDownOutlined /> : null}
        <Tooltip title={this.props.slumpRate?.toFixed(2) ?? "-"}>
          <div className="parent">
            <div className="step step1">
              <b>1</b>
            </div>
            <div className="step step2">
              <b>2</b>
            </div>
            <div className="step step3">
              <b>3</b>
            </div>
            <div className="step step4">
              <b>4</b>
            </div>
            <div className="step step5">
              <b>5</b>
            </div>
            <div className="step step6">
              <b>6</b>
            </div>

            <div className="step step7">
              <b>7</b>
            </div>

            <div className="step step8">
              <b>8</b>
            </div>

            <div className="step step9">
              <b>9</b>
            </div>

          </div>
        </Tooltip>
      </SlumpSliderStyled>
    );
  }
}

export default withRouter(SlumpSlider);
