/* eslint-disable @typescript-eslint/no-useless-constructor */
import SensorService from "./sensor.service";
import Sensor from "./sensor.model";
import PageStore from "../page.store";

class SensorStore extends PageStore {
  constructor(sensorService: SensorService) {
    super(sensorService);
  }

  url(): string {
    return "/sensor";
  }

  get model(): any {
    return Sensor;
  }

  getSensors = () => {
    return this.apiService.getSensors();
  };
}

export default SensorStore;
