import styled from "styled-components";

const CalibrationStyled = styled.div`
  
  .calibrate-sensor-form {
    width: 60%;
  }
  .ant-input {
    width: 80%;
  }
  .calibration-arrow {
    padding-top: 30%;
    font-size: larger;
  }
  .ant-form-item{
    width: 80%;
  }
  .add-cement-icon{
    padding: 1% 2%;
    font-size: x-large;
  }
`;

export default CalibrationStyled;
