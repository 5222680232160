import styled from "styled-components";

const ReportsStyled = styled.div`
  .page-title.f20.blue.flex1 {
    max-width: 33px;
    padding: 24px;
  }

  .csv-link svg {
    width: 64px;
    height: 38px;
    margin-top: 20px;
    margin-left: 20px;
  }
`;

export { ReportsStyled };
