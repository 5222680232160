import { observer } from "mobx-react";
import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

import { t } from "i18next";
import { observable } from "mobx";
import moment from "moment";
import { Table, TableColumnsType } from "antd";
import { ReadDataStyled } from "./styled";
import TopFilter from "../common/TopFilter/TopFilter";

const axios = require("axios");
interface Props extends RouteComponentProps<any> {}

type Report = {
  id: number;
  item_number: number;
  createdAt: string;
  updatedAt: string;
  date?: string | null;
  mixture?: string | null;
  vehicle_number?: string | null;
  loading_start_time?: string | null;
  loading_end_time?: string | null;
  slump?: number | null;
  project_name?: string | null;
  project_code?: number | null;
  mixture_code?: number | null;
  cubic?: number | null;
  slump_type?: number | null;
  product_familty?: string | null;
  strength_est?: number | null;
  matching?: number | null;
  factory_id?: number | null;
  company_id?: number | null;
};

type Day = {
  date: string;
  data: Report[];
  matchedDeliveriesCount: number;
  reportsWithMatchCount: number;
  totalDeliveriesCount: number;
  totalReportsCount: number;
};

const columns = [
  {
    title: "מספר הזמנה",
    dataIndex: "item_number",
  },
  {
    title: "פרויקט",
    dataIndex: "project_name",
  },
  {
    title: "ערבל",
    dataIndex: "vehicle_number",
  },
  {
    title: "סוג הסומך",
    dataIndex: "slump_type",
  },
  {
    title: "סומך",
    dataIndex: "slump",
    render: (value: string, record: any) => {
      return (
        <div className="flex flexCol">
          <div>הזמנה - {value}</div>
          {record.matching === 1 && (
            <>
              <div>העמסה -({record.con_delivery_data?.slump_at_loading})</div>
              <div>
                פריקה - ({record.con_delivery_data?.slump_at_unloading1})
              </div>
            </>
          )}
        </div>
      );
    },
  },
  {
    title: "קוב",
    dataIndex: "cubic",
    render: (value: string, record: any) => {
      return (
        <div className="flex flexCol">
          <div>הזמנה - {value}</div>
          {record.matching === 1 && (
            <>
              <div>ממוצע -({record.con_delivery_data?.cubic_est})</div>
            </>
          )}
        </div>
      );
    },
  },
  {
    title: "מוצר",
    dataIndex: "product_familty",
  },
  {
    title: "תאריך",
    dataIndex: "date",
    render: (value: string, record: any) => {
      return moment(value).format("DD/MM/YY");
    },
  },
  {
    title: "תחילת העמסה",
    dataIndex: "date",
    render: (value: string, record: any) => {
      return record.loading_start_time;
    },
  },
  {
    title: 'זוהה ע"י חיישן',
    dataIndex: "matching",
    render: (value: string, record: any) => {
      console.log({ match: record.matching });
      return record.matching ? "כן" : "לא";
    },
  },
];

const groupedColumns: TableColumnsType<Day> = [
  {
    title: "תאריך",
    dataIndex: "date",
    render(_, record, index) {
      return record.date;
    },
  },
  {
    title: "הזמנות",
    render(_, record, index) {
      const matchPercentage =
        record.totalReportsCount === 0
          ? 100
          : Math.round(
              (record.reportsWithMatchCount / record.totalReportsCount) * 100
            );
      return (
        <div className="flex justify-between">
          <span>
            <div>זוהו</div>
            <div>{`${record.reportsWithMatchCount}/${record.totalReportsCount}`}</div>
          </span>
          <span>
            <div>אחוז התאמה</div>
            <div>{`${matchPercentage}%`}</div>
          </span>
        </div>
      );
    },
  },
  {
    title: "חיישן",
    render(_, record, index) {
      const matchPercentage =
        record.totalDeliveriesCount === 0
          ? 100
          : Math.round(
              (record.matchedDeliveriesCount / record.totalDeliveriesCount) *
                100
            );
      return (
        <div className="flex justify-between">
          <span>
            <div>זוהו</div>
            <div>{`${record.matchedDeliveriesCount}/${record.totalDeliveriesCount}`}</div>
          </span>
          <span>
            <div>אחוז התאמה</div>
            <div>{`${matchPercentage}%`}</div>
          </span>
        </div>
      );
    },
  },
];

@observer
class ImportData extends React.PureComponent<Props, any> {
  @observable data: Report[] = [];
  @observable general_data: any = [];
  @observable totalRecordsCount: any = [];
  @observable matchingRecordsCount: any = [];
  @observable unmatchedRecordsCount: any = [];
  @observable days: Day[] = [];
  @observable currentOpenDay: string = "";
  filters = {
    date: [moment().subtract(60, "days").unix() * 1000, moment().unix() * 1000],
  };

  componentDidMount = async () => {
    try {
      const rsp = await axios.get(
        `${process.env.REACT_APP_API_URL}/reports/days?start_date=${this.filters.date[0]}&end_date=${this.filters.date[1]}`
      );
      console.log({ data_new: rsp.data });
      this.general_data = rsp.data;
      this.data = rsp.data.data;
      this.totalRecordsCount = this.data.length;
      this.matchingRecordsCount = rsp.data.matchingRecordsCount;
      this.unmatchedRecordsCount =
        this.totalRecordsCount - this.matchingRecordsCount;

      this.days = rsp.data.days;

      console.log("this.days", rsp.data.days);
    } catch (error) {
      console.error(error);
    }
  };

  onFilterChange = async (filter: string, value: any) => {
    console.log("filters", filter, value);
    if (filter === "date") {
      this.filters[filter] = value;
    }
    try {
      const rsp = await axios.get(
        `${process.env.REACT_APP_API_URL}/reports/days?start_date=${this.filters.date[0]}&end_date=${this.filters.date[1]}`
      );
      console.log({ data_new: rsp.data });
      this.general_data = rsp.data;
      this.data = rsp.data.data;
      this.totalRecordsCount = this.data.length;
      this.matchingRecordsCount = rsp.data.matchingRecordsCount;
      this.unmatchedRecordsCount =
        this.totalRecordsCount - this.matchingRecordsCount;

      this.days = rsp.data.days;

      console.log("this.days", rsp.data.days);
    } catch (error) {
      console.error(error);
    }
  };

  getRowClassName = (record: any) => {
    // You can customize this logic based on your requirements
    if (record.matching === 1) {
      return "active-row"; // CSS class for active rows
    }
  };

  render() {
    return (
      <ReadDataStyled>
        <div className="content-page">
          <div className="flex  gap20 marginBottom20">
            <div className="flex item">
              <div className="title">כמות הזמנות</div>
              <div className="data"> {this.data.length}</div>
            </div>

            <div className="flex item">
              <div className="title">התאמות</div>
              <div className="data"> {this.matchingRecordsCount}</div>
            </div>

            <div className="flex item">
              <div className="title">חוסר התאמה</div>
              <div className="data"> {this.unmatchedRecordsCount}</div>
            </div>

            <div className="flex item">
              <div className="title">דאתא - סנסורים</div>
              <div className="data flex flexCol">
                <div>זוהה ע"י חיישן: {this.general_data.sensor_total} </div>
                <div>התאמה: {this.general_data.sensor_matching_total}</div>
                <div>
                  לא נמצא:{" "}
                  {this.general_data.sensor_total -
                    this.general_data.sensor_matching_total}{" "}
                </div>
                <div>
                  ערבלים:
                  {this.general_data.activeSensors?.map((item: any) => {
                    return item + ", ";
                  })}
                </div>
              </div>
            </div>
          </div>
          <TopFilter
            class={"concreate-filter"}
            background={false}
            filters={["date"]}
            changeTrackNumber={(track_number) => {
              console.log("changeTrackNumber", track_number);
            }}
            onFilterChange={this.onFilterChange}
          />
          <div className="flex">
            מציג תוצאות מתארכים:
            {moment(this.general_data.startDate).format("DD/MM/YY")} -{" "}
            {moment(this.general_data.endDate).format("DD/MM/YY")}
          </div>

          <Table
            rowKey={(record) => record.date}
            expandable={{
              expandRowByClick: true,
              expandedRowRender: (record) => {
                const problematicDeliveries = record.data.filter(
                  (item) => item.matching === 0
                );
                return (
                  <Table
                    dataSource={problematicDeliveries}
                    columns={columns}
                    pagination={false}
                    // rowClassName={this.getRowClassName}
                  />
                );
              },
              rowExpandable: (record) => {
                return (
                  record.data.length > 0 &&
                  record.matchedDeliveriesCount !== record.totalDeliveriesCount
                );
              },
            }}
            dataSource={this.days}
            columns={groupedColumns}
            pagination={false}
            rowClassName={this.getRowClassName}
          />
        </div>
      </ReadDataStyled>
    );
  }
}
export default withRouter(ImportData);
