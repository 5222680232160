import BaseModel from "../base.model";
import BaseHttpService from "../../services/base.http.service";
import BaseStore from "../base.store";
import { ISensor } from "./sensor.interface";
import {  computed } from "mobx";

class Sensor extends BaseModel implements ISensor {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(httpService: BaseHttpService, store: BaseStore, data: any[]) {
    super(httpService, store, data);
    this._updateData(data);
  }

  responseModelsKey(): string {
    return "sensor";
  }

  get url(): string {
    return "/sensor";
  }

  @computed get toJson(): { [key: string]: any } {
    return this._toJson({});
  }
}

export default Sensor;
