import styled from "styled-components";

const FactoryDisplayStyled = styled.div`
  .wrapper .box {
    width: 40%;
    background: #f0791c1f;
  }

  .filter-grid.doctorfilter {
    display: flex;
  }

  .wrapper {
    justify-content: center;
    margin-bottom: 21px;
  }

  .wrapper2 .greenBox {
    background: #9ada6130;
  }

  .wrapper2 .redBox {
    background: #9ada6130;
  }

  .slump {
    width: 25%;
  }

  .display_values {
    width: 400px;

    .ant-card {
      border: 1px solid #ddd8d8;
      border-radius: 8px;
    }

    .ant-card-body {
      padding: 12px;
    }
  }

  .flex.justify-between {
    flex-direction: row-reverse !important;
  }

  .chart-events {
    width: 100%;
    height: 395px;
    margin-bottom: 279px;

    canvas {
      width: 100% !important;
      margin-top: 79px;
    }
  }
`;

export { FactoryDisplayStyled };
