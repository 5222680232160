import styled from "styled-components";

const SignInStyled = styled.div`
  .content {
    background-image: url(/images/login_back.png);
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-size: cover;
    width: calc(100% - 420px);
    height: 100vh;
    position: relative;
  }

  .wraper-form {
    max-width: 350px;
    margin: 0 auto;
    margin-top: 20%;
  }

  .login-text {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
  }

  .login-text-desc {
    text-align: center;
    font-size: 15px;
    font-weight: 500;
  }

  .login-image {
    width: 50%;
    position: absolute;
    right: 0;
    height: 100vh;
  }

  .bg-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    right: 0;
    bottom: 0;
    background-color: #5156be !important;
    left: 0;
    top: 0;
    opacity: 0.9;
  }

  .form-login {
    background: #fff;
    z-index: 99;
    padding: 35px;
    min-width: 50%;
    position: fixed;
    left: 0;
    height: 100vh;
  }

  .full.flexCol {
    margin: 9px 0px;
  }

  .flex.logo-container img {
    max-width: 141px;
  }

  .ant-btn-primary.ant-btn-lg {
    color: #fff;
    background-color: #7a7fdc !important;
    border-color: #7a7fdc;
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.47rem 0.75rem;
    font-size: 0.875rem;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .logo-container {
    -webkit-flex-direction: revert;
    -ms-flex-direction: revert;
    flex-direction: revert;
    margin-bottom: 20vh;
  }

  .item-slider {
    text-align: right;
    max-width: 300px;
    color: #fff;
  }

  .item-slider img {
    border-radius: 50% !important;
    width: 55px;
    height: 55px;
  }

  .item-slider .flex {
    gap: 15px;
  }

  .name {
    font-weight: bold;
  }

  .ant-carousel {
    margin: 32vh auto;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    max-width: 310px !important;
  }

  .descriptin-qute {
    font-size: 21px;
  }

  .qute {
    color: #2ab57d;
    font-size: 49px;
    font-family: fantasy;
    line-height: 1;
  }

  ul.slick-dots.slick-dots-bottom {
    display: none !important;
  }

  .item-slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .form-login {
    svg {
      display: none;
    }
  }

  button.ant-btn.ant-btn-primary.ant-btn-lg.b-blue.radius.white.br-white.full.marginTop20 {
    background-color: #ff6700 !important;
  }

  @keyframes square {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-1000px) rotate(600deg);
    }
  }
  @media screen and (max-width: 600px) {
    .form-login {
      position: static !important;
    }

    .wraper-form {
      background: #fff;
      padding: 20px;
      border-radius: 6px;
      margin-top: 40px !important;
    }

    .form-login {
      svg {
        display: block;
        margin: 15% auto;
      }
    }

    label.ant-form-item-required {
      font-size: 15px !important;
      font-weight: 500 !important;
    }

    .login-text {
      font-size: 22px;
    }
    .ant-col.ant-form-item-label {
      padding: 0px;
    }

    .form-login:before {
      background-image: url(/images/assets/icons/background-login.svg);
      content: "";
      display: block;
      position: absolute;
      inset: 0;
      background-size: cover;
      background-position: center;
      z-index: -1;
    }

    .flex.logo-container img {
      max-width: 132px !important;
    }
    .logo-text {
      display: none;
    }

    .flex.logo-container {
      margin-bottom: 20px;
    }

    .ant-col.ant-form-item-label {
      text-align: revert;
    }

    label.ant-form-item-required {
      flex-direction: row-reverse;
    }
  }
`;

export { SignInStyled };
