import { message } from "antd";
import { observable } from "mobx";
import { inject, observer } from "mobx-react";
import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Calibration from "../../../components/Calibration/Calibration";
import AuthStore from "../../../stores/auth/auth.store";
import parseObjectToUrlParams from "../../../utils/parseObjectToUrlParams";
import AddItem from "../Dashboard/AddItem/AddItem";
import { SettingsStyled } from "./styled";
import { t } from "i18next";
const axios = require("axios");
interface Props extends RouteComponentProps<any> {
  authStore?: AuthStore;
}

@inject("sensorStore", "authStore")
@observer
class Settings extends React.PureComponent<Props, any> {
  @observable sensors: any;
  @observable factoryId: any;
  @observable sensor_number: string = "";
  @observable active_btn_water: boolean = false;
  @observable active_btn_mosaf: boolean = false;

  @observable active_btn_mosaf_water: boolean = false;

  @observable track_history_data: any = [];
  componentDidMount = async () => {
    try {
      this.factoryId = this.props!.authStore!.user.factory_id;
      let sensors = await axios.get(
        `${process.env.REACT_APP_API_URL}/sensors?factory_id=${this.factoryId}`
      );

      this.sensors = sensors.data.sensors_data;
      this.sensor_number = this.getSensorNumber();
    } catch (error) {
      console.error(error);
    }
  };

  startAction = async (type: string, on: boolean, liter: number) => {
    let type_name: string = "";

    switch (type) {
      case "מקדם מים":
        this.active_btn_water = on;
        type_name = "water";
        message.success("מקדם מים כויל בהצלחה");
        this.adminUdateWaterOrMosaf(on, liter, type_name);
        break;

      case "מקדם מוספים":
        this.active_btn_mosaf = on;
        type_name = "supplement";
        message.success("מקדם מוספים כויל בהצלחה");
        this.adminUdateWaterOrMosaf(on, liter, type_name);
        break;

      case "מקדם מים ידני":
        this.active_btn_mosaf_water = on;
        type_name = "water_manual";
        message.success("מקדם מים ידני כויל בהצלחה");
        this.adminUdateWaterOrMosaf(on, liter, type_name);
        break;
    }
  };

  adminUdateWaterOrMosaf = async (
    action: boolean,
    liter: number,
    type: string
  ) => {
    try {
      if (action) {
        let queries = parseObjectToUrlParams({
          flow_command: `SET_FACTOR_${liter}`,
          sensor_id: this.sensor_number,
          type: type,
          name: this.props!.authStore!.getUserName(),
          role: this.props!.authStore!.user.role_info,
        });

        let url = `${process.env.REACT_APP_API_URL}/flow_commands/new_command?${queries}`;

        let updateWaterOrMosaf_data = await axios.get(url);
      }
    } catch (error) {
      console.log(error);
    }
  };

  getSensorNumber = () => {
    const track_number = localStorage.getItem("track_number");
    return track_number;
  };

  render() {
    return (
      <SettingsStyled>
        {" "}
        <div className="content-page">
          <div className="flex">
            <div className="page-title f20 blue flex1">{t("settings")}</div>
            <div>
              {t("sensorNumber")} {this.sensor_number}
            </div>
          </div>

          <div className="setting-group marginHor50 marginBottom20">
            <div className="page-title f20 blue flex1">כיול מקדמים</div>

            <div className="flex gap20 marginTop20">
              <AddItem
                min={1}
                max={10000}
                type={"factor"}
                name="מקדם מים"
                explainText="?כמות"
                submitText={"החל"}
                sensor_number={this.sensor_number}
                submitActiveText={"בוצע"}
                active_btn={this.active_btn_water}
                startAction={this.startAction}
              />
              <AddItem
                min={1}
                max={10000}
                type={"factor"}
                name="מקדם מוספים"
                explainText="?כמות"
                submitText={"החל"}
                sensor_number={this.sensor_number}
                submitActiveText={"בוצע"}
                active_btn={this.active_btn_mosaf}
                startAction={this.startAction}
              />

              <AddItem
                min={1}
                max={10000}
                type={"factor"}
                name="מקדם מים ידני"
                explainText="?כמות"
                sensor_number={this.sensor_number}
                submitText={"החל"}
                submitActiveText={"בוצע"}
                active_btn={this.active_btn_mosaf_water}
                startAction={this.startAction}
              />
            </div>
          </div>

          <Calibration />
        </div>
      </SettingsStyled>
    );
  }
}
export default withRouter(Settings);
