import { Button, Dropdown, InputNumber, Slider, Switch, Table, Tooltip } from "antd";
import axios, { AxiosResponse } from "axios";
import { t } from "i18next";
import { observable } from "mobx";
import { inject, observer } from "mobx-react";
import moment from "moment";
import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import parseObjectToUrlParams from "../../../../utils/parseObjectToUrlParams";
import TopFilter from "../../common/TopFilter/TopFilter";
import { ConcreteSlumpStyled } from "./styled";
import ChartOverview from "./components/ChartOverview";
import ChartOverviewBars from "./components/ChartOverviewBars";
import AuthStore from "../../../../stores/auth/auth.store";
import { OverViewTable } from "./components/OverviewTable";
import { cn } from "../../../../utils";
import Icon from "@ant-design/icons";

interface Props extends RouteComponentProps<any> {
  previewMODE?:boolean;
  concreateCode?:string;
  displayMode?:string;
  authStore?: AuthStore;
}

export type ChartData = {
  ">1L": {
    positive: number;
    negative: number;
  };
  "1L": {
    positive: number;
    negative: number;
  };
  "2L": {
    positive: number;
    negative: number;
  };
  "3L": {
    positive: number;
    negative: number;
  };
};

export type SlumpChange = {
  id: number;
  order_id: string;
  slump_orderd: number | null;
  slump_at_loading: number | null;
  slump_at_unloading1: number | null;
  sensor_id: number;
  factory_id: number;
  mixture_code: string;
  date_upload: number;
  date_download: number;
};

async function getSlumpChanges(
  group_id: string,
  urlParams: Record<string, any> = {}
): Promise<SlumpChange[]> {
  const rsp = await axios.get(
    `${process.env.REACT_APP_API_URL}/calculate/slump/?group_id=${group_id}&` +
      parseObjectToUrlParams(urlParams)
  );
  return rsp.data.percentages;
}

function categorizeAbsDifference(value1: number, value2: number , slumpChangeValue:number) {
  const difference = Math.abs(value1 - value2) - slumpChangeValue;

  // 200
  //  -200

    if (difference < 13) {
    return ">1L";
  } else if (difference >= 13 && difference < 25) {
    return "1L";
  } else if (difference >= 25 && difference < 50) {
    return "2L";
  } else if (difference >= 50) {
    return "3L";
  }
}
function categorizeDifference(value1: number, value2: number, slumpChangeValue:number) {
  const difference = (value1 - value2)  - slumpChangeValue;;

  if (difference < 0) {
    return;
  }

  if (difference < 13) {
    return ">1L";
  } else if (difference >= 13 && difference < 25) {
    return "1L";
  } else if (difference >= 25 && difference < 50) {
    return "2L";
  } else if (difference >= 50) {
    return "3L";
  }
}

function slumpChangeToChartData(changes: SlumpChange[] , slumpChangeValue:number) {
  const orderToLoading: ChartData = {
    ">1L": { negative: 0, positive: 0 },
    "1L": { negative: 0, positive: 0 },
    "2L": { negative: 0, positive: 0 },
    "3L": { negative: 0, positive: 0 },
  };

  const loadingToUnloading: ChartData = {
    ">1L": { negative: 0, positive: 0 },
    "1L": { negative: 0, positive: 0 },
    "2L": { negative: 0, positive: 0 },
    "3L": { negative: 0, positive: 0 },
  };

  const orderToUnloading: ChartData = {
    ">1L": { negative: 0, positive: 0 },
    "1L": { negative: 0, positive: 0 },
    "2L": { negative: 0, positive: 0 },
    "3L": { negative: 0, positive: 0 },
  };

  for (const change of changes) {
    const slumpOrderd = change.slump_orderd;
    const slumpAtLoading = change.slump_at_loading;
    const slumpAtUnloading1 = change.slump_at_unloading1;

    

    if (slumpOrderd !== null && slumpAtUnloading1 !== null) {
      const absDifference = categorizeAbsDifference(
        slumpOrderd,
        slumpAtUnloading1,
        slumpChangeValue
      );
      const positive = categorizeDifference(slumpOrderd, slumpAtUnloading1 , slumpChangeValue);
      const negative = categorizeDifferenceMinus(
        slumpOrderd,
        slumpAtUnloading1,
        slumpChangeValue
      );

      if (positive !== undefined) {
        
        orderToUnloading[absDifference].positive = orderToUnloading[absDifference].positive +1;
      }

      if (negative !== undefined ) {
        orderToUnloading[absDifference].negative++;
      }
     
    }

    if (slumpOrderd !== null && slumpAtLoading !== null) {
      const absDifference = categorizeAbsDifference(
        slumpOrderd,
        slumpAtLoading,
        slumpChangeValue
      );
      const positive = categorizeDifference(slumpOrderd, slumpAtLoading , slumpChangeValue);

      const negative = categorizeDifferenceMinus(slumpOrderd, slumpAtLoading , slumpChangeValue);

      if (positive) {
        orderToLoading[absDifference].positive++;
      }

      if (negative) {
        orderToLoading[absDifference].negative++;
      }
    }

    if (slumpAtLoading !== null && slumpAtUnloading1 !== null) {
      const absDifference = categorizeAbsDifference(
        slumpAtLoading,
        slumpAtUnloading1,
        slumpChangeValue
      );
      const positive = categorizeDifference(slumpAtLoading, slumpAtUnloading1 , slumpChangeValue);

      const negative = categorizeDifferenceMinus(
        slumpAtLoading,
        slumpAtUnloading1,
        slumpChangeValue
      );

      if (positive) {
        loadingToUnloading[absDifference].positive++;
      }

      if (negative) {
        loadingToUnloading[absDifference].negative++;
      }
    }
  }

  return {
    orderToLoading,
    loadingToUnloading,
    orderToUnloading,
  };
}

function categorizeDifferenceMinus(value1: number, value2: number , slumpChangeValue:number) {
  const difference = (value1 - value2) - slumpChangeValue;
  if (difference > 0) {
    return;
  }
  if (difference >= -13 && difference < 0) {
    return "->1L";
  } else if (difference >= -25 && difference < -13) {
    return "-1L";
  } else if (difference >= -50 && difference < -25) {
    return "-2L";
  } else if (difference < -50) {
    return "-3L";
  }
}

export type RatioType =
  | "orderToLoading"
  | "loadingToUnloading"
  | "orderToUnloading";

const ratioTypes = [
  {
    key: "orderToUnloading",
    titleKey: "orderToSupply",
  },
  {
    key: "orderToLoading",
    titleKey: "orderToProduction",
  },
  {
    key: "loadingToUnloading",
    titleKey: "productionToSupply",
  },
];

function groupChangeByDay(changes: SlumpChange[], between: RatioType , slumpChangeValue:number) {
  const groupByDay: {
    day: string;
    data: ChartData;
  }[] = [];

  for (const change of changes) {
    const day = moment(change.date_upload).format("DD/MM/YYYY");
    const dayIndex = groupByDay.findIndex((item) => item.day === day);
    if (dayIndex === -1) {
      groupByDay.push({
        day,
        data: {
          ">1L": { negative: 0, positive: 0 },
          "1L": { negative: 0, positive: 0 },
          "2L": { negative: 0, positive: 0 },
          "3L": { negative: 0, positive: 0 },
        },
      });
    }

    const dayData = groupByDay.find((item) => item.day === day);
    if (between === "orderToLoading") {
      if (change.slump_orderd !== null && change.slump_at_loading !== null) {
        const difference = categorizeAbsDifference(
          change.slump_orderd,
          change.slump_at_loading,
          slumpChangeValue
        );
        const positive = categorizeDifference(
          change.slump_orderd,
          change.slump_at_loading,
          slumpChangeValue
        );

        const negative = categorizeDifferenceMinus(
          change.slump_orderd,
          change.slump_at_loading,
          slumpChangeValue
        );
        if (positive) {
          dayData.data[difference].positive++;
        }
        if (negative) {
          dayData.data[difference].negative++;
        }
      }
    }

    if (between === "loadingToUnloading") {
      if (
        change.slump_at_loading !== null &&
        change.slump_at_unloading1 !== null
      ) {
        const difference = categorizeAbsDifference(
          change.slump_at_loading,
          change.slump_at_unloading1,
          slumpChangeValue
        );
        const positive = categorizeDifference(
          change.slump_at_loading,
          change.slump_at_unloading1,
          slumpChangeValue
        );

        const negative = categorizeDifferenceMinus(
          change.slump_at_loading,
          change.slump_at_unloading1,
          slumpChangeValue
        );

        if (positive) {
          dayData.data[difference].positive++;
        }
        if (negative) {
          dayData.data[difference].negative++;
        }
      }
    }

    if (between === "orderToUnloading") {
      if (change.slump_orderd !== null && change.slump_at_unloading1 !== null) {
        const difference = categorizeAbsDifference(
          change.slump_orderd,
          change.slump_at_unloading1,
          slumpChangeValue
        );

        const positive = categorizeDifference(
          change.slump_orderd,
          change.slump_at_unloading1,
          slumpChangeValue
        );

        const negative = categorizeDifferenceMinus(
          change.slump_orderd,
          change.slump_at_unloading1,
          slumpChangeValue
        );

        if (positive) {
          dayData.data[difference].positive++;
        }

        if (negative) {
          dayData.data[difference].negative++;
        }
      }
    }
  }
  return groupByDay;
}

export const slumpLegend = [
  {
    key: ">1L",
    title: "less than 1 slump Level",
    titleKey: "lessThan1SlumpLevel",
    color: "#008000",
    colorClass: "green",
  },
  {
    key: "1L",
    title: "1 slump Level",
    titleKey: "oneSlumpLevel",
    color: "#FFD700",
    colorClass: "yellow",
  },
  {
    key: "2L",
    title: "2 slump Level",
    titleKey: "twoSlumpLevel",
    color: "#FFA500",
    colorClass: "orange",
  },
  {
    key: "3L",
    title: "3 slump Level",
    titleKey: "threeSlumpLevel",
    color: "#FF0000",
    colorClass: "red",
  },
] as const;

const defaultStartDate = moment().subtract(30, "days").startOf("day").valueOf();
const defaultEndDate = moment().subtract(1, "days").endOf("day").valueOf();
@inject("authStore")
@observer
class ConcreteSlump extends React.PureComponent<Props, any> {
  @observable generalData = {
    sensors_count: 0,
    factories_count: 0,
    deliveries_count: 0,
  };

  @observable slumpChangeValue: any = 0;
  @observable displayDiff: boolean  = false;
  @observable mainChartData: ChartData | null  = null;
  @observable mainChartDataAfterChange: ChartData | null  = null;
  @observable chartOrderLoadingData: ChartData | null = null;
  @observable chartLoadingUnloadingData: ChartData | null = null;
  @observable chartOrderUnloadingData: ChartData | null = null;
  @observable positiveNegative: "positive" | "negative" | "both" = "both";
  @observable dailyOrderToLoadingSlumpChanges: {
    day: string;
    data: ChartData;
  }[] = [];
  @observable dailyLoadingToUnloadingSlumpChanges: {
    day: string;
    data: ChartData;
  }[] = [];
  @observable dailyOrderToUnloadingSlumpChanges: {
    day: string;
    data: ChartData;
  }[] = [];
  @observable barsRatioType: RatioType = "orderToLoading";
  @observable viewType: "chart" | "table" = "chart";
  @observable factory_id: any;

  @observable uniqueSensors: number[] | null = null;
  @observable uniqueFactories: number[] | null = null;
  @observable uniqueMixtureCodes: string[] | null = null;
  @observable mixtureCodes: string[] | null = null;
  
  @observable averageDifference: number | null = null;

  @observable slumpChange: SlumpChange[] = [];
  @observable slumpChangeAllMixtures: SlumpChange[] = [];

  @observable dateRange: any = [defaultStartDate, defaultEndDate];
  @observable activeCode: any = "-";

  @observable levelMappings = {
    "1L": t("oneSlumpLevel"),
    "2L": t("twoSlumpLevel"),
    "3L": t("threeSlumpLevel"),
    ">1L": t("lessThan1SlumpLevel"),
  };

  @observable urlParams: Record<string, any> = {
    date: { start: defaultStartDate, end: defaultEndDate },
  };

  async componentDidMount() {
    console.log({a:this.props.concreateCode})
    const group_id = this.props!.authStore!.getUserGroipID();
    try {

      if(this.props.concreateCode) {
        this.urlParams.mixture_code = this.props.concreateCode
      }

      this.slumpChange = await getSlumpChanges(group_id, this.urlParams);
      const params = {
        ...this.urlParams,
      };
      delete params.mixture_code;
      
      this.slumpChangeAllMixtures = await getSlumpChanges(group_id, params);
    } catch (error) {
      console.log({ error });
    }

    this.getGeneralData();
  }

  getGeneralData = async () => {
    this.generalData.deliveries_count = this.slumpChange.length;

    const sensors = this.slumpChange.map((entry) => entry.sensor_id);
    const factories = this.slumpChange.map((entry) => entry.factory_id);
    const mixtureCodes = this.slumpChangeAllMixtures.map(
      (entry) => entry.mixture_code
    );

    this.mixtureCodes = mixtureCodes;
    this.uniqueSensors = Array.from(new Set(sensors));
    this.uniqueFactories = Array.from(new Set(factories));
    this.uniqueMixtureCodes = Array.from(new Set(mixtureCodes));


    const changesPerUniqueMixtures = this.uniqueMixtureCodes.map((mixture) => {
      return {
        arr: this.slumpChangeAllMixtures.filter(
          (entry) => entry.mixture_code === mixture
        ),
        mixture,
      };
    });


    this.generalData.sensors_count = this.uniqueSensors.length;
    this.generalData.factories_count = this.uniqueFactories.length;

    console.log({a:this.slumpChange , b:this.slumpChangeValue})

    const { loadingToUnloading, orderToLoading, orderToUnloading } =
      slumpChangeToChartData(this.slumpChange , this.slumpChangeValue );

    this.chartLoadingUnloadingData = loadingToUnloading;
    this.chartOrderUnloadingData = orderToUnloading;
    this.chartOrderLoadingData = orderToLoading;
    
    
    console.log({as:this.slumpChangeValue})

    if(this.slumpChangeValue > 0){
      this.mainChartDataAfterChange = orderToLoading
    }else{
      this.mainChartData = orderToLoading;
    }

    

    this.dailyOrderToLoadingSlumpChanges = groupChangeByDay(
      this.slumpChange,
      "orderToLoading",
      this.slumpChangeValue
    );

    this.dailyLoadingToUnloadingSlumpChanges = groupChangeByDay(
      this.slumpChange,
      "loadingToUnloading",
      this.slumpChangeValue
    );

    this.dailyOrderToUnloadingSlumpChanges = groupChangeByDay(
      this.slumpChange,
      "orderToUnloading",
      this.slumpChangeValue
    );



    let totalDifference = 0;

    // Loop through each object in the array and calculate the difference
    this.slumpChange.forEach(slump => {
      const difference = slump.slump_at_unloading1 - slump.slump_orderd ; // Calculate raw difference
      totalDifference += difference;
    });

    // Calculate the average difference
    this.averageDifference = Number((totalDifference / this.slumpChange.length).toFixed(0));


  };



  


  handleSliderChange = async (value: any) => {
    this.slumpChangeValue = value;
    this.getGeneralData();
  };


  onFilterChange = async (filterName: string, filterValue: any) => {
    this.slumpChangeValue =0;
    this.displayDiff =false;
    switch (filterName) {
      case "date":
        this.dateRange = filterValue;
        break;

      case "factory_id":
        this.factory_id = filterValue;
        break;
    }


    if (this.dateRange.length > 1) {
      this.urlParams.date = {
        start: this.dateRange[0],
        end: this.dateRange[1],
      };
    }
    if (this.factory_id) {
      this.urlParams.factory_id = this.factory_id;
    }

    if (filterName === "sensor_id") {
      this.urlParams.sensor_id = filterValue;
    }

    if (filterName === "loading_concreate_type") {
      this.urlParams.mixture_code = filterValue.key;
    }

    if (filterName === "addons") {
      this.urlParams.suspected_water_addition = filterValue;
      console.log(this.urlParams);
    }


    if(filterName ==="loading_concreate_type"){
        this.activeCode = filterValue.key;
    }

    try {
      this.slumpChange = await getSlumpChanges(
        this.props!.authStore!.getUserGroipID(),
        this.urlParams
      );
      let params = { ...this.urlParams };
      this.slumpChangeAllMixtures = await getSlumpChanges(
        this.props!.authStore!.getUserGroipID(),
        params
      );

      this.getGeneralData();
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    return (
      <ConcreteSlumpStyled>
        <div className="container">
          <div>
            {!this.props.previewMODE? 
            <>
            <div className="title"> {t("slumpSettings")}</div>
            <div className="flex page-title gap20 ">
              
              
              <TopFilter
                class={"concreate-filter"}
                background={false}
                filters={[
                  "date",
                  "factory",
                  "sensor",
                  "addons",
                  "loading_concreate_type",
                ]}
                changeTrackNumber={(track_number) =>
                  this.onFilterChange("sensor_id", track_number)
                }
                onFilterChange={this.onFilterChange}
                filterConcreteTypesList={(mix) => {
                  if (!this.uniqueMixtureCodes) return true;

                  return this.uniqueMixtureCodes?.some(
                    (item) => item == mix.code
                  );
                }}
                filterConcreteTypesCount={(mix) => {
                  if (!this.mixtureCodes) return 0;
                  const count = this.mixtureCodes.filter(
                    (item) => item == mix.code
                  ).length;
                  if (count > 0) {
                    // console.log("filter mix count ", mix.code, count);
                  }

                  return this.mixtureCodes.filter((item) => item == mix.code)
                    .length;
                }}
              />


            </div>
            </>
            :null}

            <div className=" f14 flex gap20 wrap item-results">
              <div className="flex gap10">
                <div className="name bold">{t("factories")}:</div>
                <div className="value flex">
                  <Tooltip
                    title={
                      <div className="flex flexCol">
                        {this.uniqueFactories &&
                          this.uniqueFactories.map((value, index) => (
                            <div key={index}>{value}</div>
                          ))}
                      </div>
                    }
                  >
                    {this.generalData.factories_count}
                  </Tooltip>
                </div>
              </div>
              <div className="flex gap10">
                <div className="name bold">{t("mixers")} :</div>
                <div className="value flex">
                  <Tooltip
                    title={
                      <div className="flex flexCol">
                        {this.uniqueSensors &&
                          this.uniqueSensors.map((value, index) => (
                            <div key={index}>{value}</div>
                          ))}
                      </div>
                    }
                  >
                    {this.generalData.sensors_count}
                  </Tooltip>
                </div>
              </div>

              <div className="flex gap10">
                <div className="name bold">{t("delivery's")} :</div>
                <div className="value flex">
                  {this.generalData.deliveries_count}
                </div>
              </div>
              <div className="p-2 bg-white border-b border-[#c7c7c7]">
                <div className="rounded-md overflow-hidden">
                  <button
                    className={cn(
                      "rounded-none bg-slate-400 hover:bg-slate-500",
                      {
                        "bg-main-orange hover:bg-orange-500":
                          this.positiveNegative === "both",
                      }
                    )}
                    onClick={() => (this.positiveNegative = "both")}
                  >
                    {t("all")}
                  </button>
                  <button
                    className={cn(
                      "rounded-none bg-slate-400 hover:bg-slate-500",
                      {
                        "bg-main-orange hover:bg-orange-500":
                          this.positiveNegative === "positive",
                      }
                    )}
                    onClick={() => (this.positiveNegative = "positive")}
                  >
                   {t("negative")} 
                  </button>
                  <button
                    className={cn(
                      "rounded-none bg-slate-400 hover:bg-slate-500",
                      {
                        "bg-main-orange hover:bg-orange-500":
                          this.positiveNegative === "negative",
                      }
                    )}
                    onClick={() => (this.positiveNegative = "negative")}
                  >
                  {t("positive")}
                  </button>
                </div>
              </div>

              <div className="flex gap10">
                <div className="name bold">{t("recipe_code")} :</div>
                <div className="value flex">
                {this.props.concreateCode ? this.props.concreateCode :this.activeCode}
                </div>
              </div>
            </div>


           

            <div className="flex boxes">
              <div className="flex flexCol box1 relative  ">
                <div className="flex justify-between">
                  <div className="title">{t("deliveriesOverView")}</div>
          

                  
                </div>
                
                {this.mainChartData && (
                  <ChartOverview
                    series={
                      this.positiveNegative === "both"
                        ? [
                            this.mainChartData[">1L"].negative +
                              this.mainChartData[">1L"].positive,
                            this.mainChartData["1L"].negative +
                              this.mainChartData["1L"].positive,
                            this.mainChartData["2L"].negative +
                              this.mainChartData["2L"].positive,
                            this.mainChartData["3L"].negative +
                              this.mainChartData["3L"].positive,
                          ]
                        : [
                            this.mainChartData[">1L"][this.positiveNegative],
                            this.mainChartData["1L"][this.positiveNegative],
                            this.mainChartData["2L"][this.positiveNegative],
                            this.mainChartData["3L"][this.positiveNegative],
                          ]
                    }
                    labels={[
                      this.levelMappings[">1L"],
                      this.levelMappings["1L"],
                      this.levelMappings["2L"],
                      this.levelMappings["3L"],
                    ]}
                  />
                )}
                <div className="total">
                  <div>{t("total")}</div>
                  <div className="total-del">
                    {this.mainChartData &&
                      (this.positiveNegative === "both"
                        ? this.mainChartData[">1L"].negative +
                          this.mainChartData[">1L"].positive +
                          this.mainChartData["1L"].negative +
                          this.mainChartData["1L"].positive +
                          this.mainChartData["2L"].negative +
                          this.mainChartData["2L"].positive +
                          this.mainChartData["3L"].negative +
                          this.mainChartData["3L"].positive
                        : this.mainChartData[">1L"][this.positiveNegative] +
                          this.mainChartData["1L"][this.positiveNegative] +
                          this.mainChartData["2L"][this.positiveNegative] +
                          this.mainChartData["3L"][this.positiveNegative])}
                  </div>

                       
                       {this.positiveNegative !== "both"  && 
                         t("from") + " " + this.generalData.deliveries_count
                         }
                  
                </div>

                
                <div className="flex flexCol padding20">
                  {this.mainChartData &&
                    Object.entries(this.mainChartData).map(([key, value]) => {
                      let valueSum = value.negative + value.positive;
                      if (this.positiveNegative === "positive") {
                        valueSum = value.positive;
                      }
                      if (this.positiveNegative === "negative") {
                        valueSum = value.negative;
                      }
                      const heightPercentage =
                        (valueSum / this.generalData.deliveries_count) * 100;
                      // Check if heightPercentage is greater than 0
                      if (heightPercentage > 0) {
                        let colorClass = slumpLegend.find(
                          (item) => item.key === key
                        )?.colorClass;
                        return (
                          <div
                            className="flex gap10 pointer"
                            key={key}
                            onClick={() => {
                              window.location.href = `/concrete/delivery?level=${key}`;
                            }}
                          >
                            <div className={"number-title " + colorClass}>
                              {heightPercentage.toFixed(0)}%
                            </div>
                            <div className="flex1 flexCol">
                              <div>
                                {this.levelMappings[key as keyof ChartData]}
                              </div>
                              <div className="bold">
                                {this.positiveNegative === "both" &&
                                  `${value.negative + value.positive} ${t(
                                    "deliveries"
                                  ).toLowerCase()}`}
                                {this.positiveNegative === "positive" &&
                                  `${value.positive} ${t(
                                    "deliveries"
                                  ).toLowerCase()}`}
                                {this.positiveNegative === "negative" &&
                                  `${value.negative} ${t(
                                    "deliveries"
                                  ).toLowerCase()}`}
                              </div>
                            </div>
                          </div>
                        );
                      }
                      // Return null if heightPercentage is not greater than 0
                      return null;
                    })}
                </div>

                <div className="flex box-slider"  onClick={()=> {
                    this.displayDiff=!this.displayDiff;

                    this.handleSliderChange(this.averageDifference)
                  }}
                    style={{  display: "flex",
                      background: "#dadadad9",
                      height: "30px",
                      alignItems: "center",
                      padding:"20px",
                      border: "1px solid #aca6a6",
                      flexDirection: "column",
                      justifyContent: "center",
                      borderRadius: "8px"}}>
                      {/* <div className="fix-title"></div> */}
                      {/* <Slider
                        min=  {-200}
                        max={200}
                        onChange={this.handleSliderChange}
                        value={this.slumpChangeValue}
                      />         */}

                      <div className="pointer">
                      {t("Fix")}  - <b>({this.averageDifference} {t("mm")})</b>
                      </div>

                     
                    </div>
              </div>
              {this.displayDiff ? (
  <div className="display-chart">

    <div className="flex justify-between margin20">
        <div className="title">{t("Fix")}</div>
    </div>
    {this.mainChartDataAfterChange && (
      <ChartOverview
        series={
          this.positiveNegative === "both"
            ? [
                this.mainChartDataAfterChange[">1L"].negative +
                  this.mainChartDataAfterChange[">1L"].positive,
                this.mainChartDataAfterChange["1L"].negative +
                  this.mainChartDataAfterChange["1L"].positive,
                this.mainChartDataAfterChange["2L"].negative +
                  this.mainChartDataAfterChange["2L"].positive,
                this.mainChartDataAfterChange["3L"].negative +
                  this.mainChartDataAfterChange["3L"].positive,
              ]
            : [
                this.mainChartDataAfterChange[">1L"][this.positiveNegative],
                this.mainChartDataAfterChange["1L"][this.positiveNegative],
                this.mainChartDataAfterChange["2L"][this.positiveNegative],
                this.mainChartDataAfterChange["3L"][this.positiveNegative],
              ]
        }
        labels={[
          this.levelMappings[">1L"],
          this.levelMappings["1L"],
          this.levelMappings["2L"],
          this.levelMappings["3L"],
        ]}
      />
    )}


    <div className="flex flexCol padding20">
   

      {this.mainChartDataAfterChange &&
        Object.entries(this.mainChartDataAfterChange).map(([key, value]) => {
          let valueSum = value.negative + value.positive;
          if (this.positiveNegative === "positive") {
            valueSum = value.positive;
          }
          if (this.positiveNegative === "negative") {
            valueSum = value.negative;
          }
          const heightPercentage =
            (valueSum / this.generalData.deliveries_count) * 100;

          if (heightPercentage > 0) {
            let colorClass = slumpLegend.find(
              (item) => item.key === key
            )?.colorClass;

            return (
              <div
                className="flex gap10 pointer"
                key={key}
                onClick={() => {
                  window.location.href = `/concrete/delivery?level=${key}`;
                }}
              >
                <div className={"number-title " + colorClass}>
                  {heightPercentage.toFixed(0)}%
                </div>
                <div className="flex1 flexCol">
                  <div>{this.levelMappings[key as keyof ChartData]}</div>
                  <div className="bold">
                    {this.positiveNegative === "both" &&
                      `${value.negative + value.positive} ${t(
                        "deliveries"
                      ).toLowerCase()}`}
                    {this.positiveNegative === "positive" &&
                      `${value.positive} ${t(
                        "deliveries"
                      ).toLowerCase()}`}
                    {this.positiveNegative === "negative" &&
                      `${value.negative} ${t(
                        "deliveries"
                      ).toLowerCase()}`}
                  </div>
                </div>
              </div>
            );
          }
          return null;
        })}
    </div>
  </div>
) :
 <div className="flex flexCol box2">
              <div className="title">{t("slumpDetailsSummary")}</div>
              <div className="flex justify-between">
                <div className="flex ratio-types">
                  {ratioTypes.map((item) => (
                    <span
                      className={`cursor-pointer padding5 ratio-type ${
                        this.barsRatioType === item.key
                          ? "ratio-type-selected"
                          : ""
                      }`}
                      key={item.key}
                      onClick={() => {
                        this.barsRatioType = item.key as RatioType;
                        console.log({ter:this.barsRatioType})
                        if( this.barsRatioType ==="orderToUnloading"){
                          this.mainChartData = this.chartOrderUnloadingData;
                        }else if( this.barsRatioType ==="loadingToUnloading"){
                          this.mainChartData = this.chartLoadingUnloadingData;
                        }else if( this.barsRatioType ==="orderToLoading"){
                          this.mainChartData = this.chartOrderLoadingData;
                        }


                        console.log({ter:this.barsRatioType})
                      }}
                    >
                      {t(item.titleKey)}
                    </span>
                  ))}
                </div>
                <div className="flex gap5 align-center">
                  {this.viewType === "chart" ? (
                    <img src="/images/chart-active.svg" alt="chart active" />
                  ) : (
                    <img
                      src="/images/chart-inactive.svg"
                      alt="chart"
                      className="pointer"
                      onClick={() => (this.viewType = "chart")}
                    />
                  )}
                  <span
                    style={{
                      backgroundColor: "#9CA3AF",
                      width: "1px",
                      height: "20px",
                    }}
                  ></span>
                  {this.viewType === "table" ? (
                    <img src="/images/table-active.svg" alt="table active" />
                  ) : (
                    <img
                      src="/images/table-inactive.svg"
                      className="pointer"
                      alt="table"
                      onClick={() => (this.viewType = "table")}
                    />
                  )}
                </div>
              </div>
              {this.viewType === "chart" && (
                <>
                  <ChartOverviewBars
                    series={([">1L", "1L", "2L", "3L"] as const).map(
                      (key) => {
                        let arr = this.dailyOrderToLoadingSlumpChanges;
                        if (this.barsRatioType === "orderToLoading") {
                          arr = this.dailyOrderToLoadingSlumpChanges;
                        }
                        if (this.barsRatioType === "loadingToUnloading") {
                          arr = this.dailyLoadingToUnloadingSlumpChanges;
                        }
                        if (this.barsRatioType === "orderToUnloading") {
                          arr = this.dailyOrderToUnloadingSlumpChanges;
                        }
                        return {
                          name: this.levelMappings[key as keyof ChartData],
                          data: arr.map((item) => {
                            if (this.positiveNegative === "positive")
                              return item.data[key].positive;
                            if (this.positiveNegative === "negative")
                              return item.data[key].negative;
                            return (
                              item.data[key].negative +
                              item.data[key].positive
                            );
                          }),
                        };
                      }
                    )}
                    xaxis={{
                      categories: this.dailyOrderToLoadingSlumpChanges.map(
                        (item) => moment(item.day, "DD/MM/YYYY").valueOf()
                      ),
                      labels: {
                        formatter: function (value) {
                          return moment(value).format("DD/MM");
                        },
                      },
                      tickPlacement: "on",
                    }}
                    // yaxis={{ max: 10, tickAmount: 5 }}
                    events={{
                      xAxisLabelClick: (event, chartContext, v) => {
                        const labels =
                          this.dailyOrderToLoadingSlumpChanges.map(
                            (item) => item.day
                          );

                        const label = labels[v.labelIndex];
                        window.location.href = `/concrete/delivery?date=${label}`;
                      },
                    }}
                  />
                  <div className="flex gap10 justify-center">
                    {slumpLegend.map((item) => (
                      <span key={item.key} className="flex gap5 align-center">
                        <span
                          style={{
                            backgroundColor: item.color,
                            height: "10px",
                            width: "10px",
                            borderRadius: 5,
                          }}
                        ></span>
                        <span>{t(item.titleKey)}</span>
                      </span>
                    ))}
                  </div>
                </>
              )}
              {this.viewType === "table" && (
                <OverViewTable
                  barsRatioType={this.barsRatioType}
                  positiveOrNegative={this.positiveNegative}
                  dailyLoadingToUnloadingSlumpChanges={
                    this.dailyLoadingToUnloadingSlumpChanges
                  }
                  dailyOrderToLoadingSlumpChanges={
                    this.dailyOrderToLoadingSlumpChanges
                  }
                  dailyOrderToUnloadingSlumpChanges={
                    this.dailyOrderToUnloadingSlumpChanges
                  }
                />
              )}
            </div>}
            </div>

            
          </div>
        </div>
      </ConcreteSlumpStyled>
    );
  }
}
export default withRouter(ConcreteSlump);
