import styled from "styled-components";

const SidebarStyled = styled.div`
  .sidebar {
    background: #ffffff;
    position: fixed;
    height: 100%;
  }
  .logo svg {
    height: 116px;
    margin: 0 auto;
    width:80%;
  }

  .logo {
    width: 250px;
    margin: 0 auto;
  }

  svg.clos-bar {
    width: 50px;
    height: 50px;
    cursor: pointer;
  }

  .fotter-data {
    width: 100%;
    position: absolute;
    bottom: 0;
    justify-content: center;
    display: flex;
    text-align: left;
  }
  .sub-menu .flex {
    margin-top: 3px;
    font-weight: 500;
  }

  label {
    font-weight: bold;
    padding-right: 10px;
  }

  .title {
    font-size: 11px;
    margin-bottom: 10px;
  }

  .menu {
    padding: 16px;
    padding-top: 0px;
  }

  .active svg {
    fill: rgba(81, 86, 190, 0.2) !important;
  }

  svg {
    height: 22px;
    width: 28px;
    color: #545a6d;
    display: flex;
  }

  .box-fot {
    width: 30px;
    position: absolute;
    background: #edeefb;
    width: 200px;
    padding: 20px;
    border-radius: 4px;
    bottom: 25px;
    height: 300px;
  }

  .box-fot img {
    width: 72%;
    margin: 0 auto;
    display: block;
    border-radius: 3px;
  }

  .box-title {
    color: #7a7fdc;
    text-align: center;
    font-size: 16px !important;
    margin-top: 8px;
  }

  .help-number.flex {
    margin-top: 30vh;
    flex-direction: unset;
  }
  .description {
    font-size: 12px;
    text-align: center;
  }

  .box-fot button {
    height: 31px;
    margin: 11px 10px;
  }

  .current-path {
    color: #ff670094 !important;
  }

  .indent-sub-category {
    margin-right: 1;
  }
  .sub-menu {
    padding-right: 38px;
  }

  .flex.menu-title {
    margin-top: 27px;

    label {
      cursor: pointer;
    }
  }

  .flex.menu-title:nth-child(1) {
    margin-top: 0px;
  }

  .en .sub-menu {
    padding-right: inherit !important;
    padding-left: 38px !important;
  }
`;

export { SidebarStyled };
