import { observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import parseObjectToUrlParams from "../../../utils/parseObjectToUrlParams";
import TopFilter from "../common/TopFilter/TopFilter";
import TrackHistory from "../Dashboard/TrackHistory/TrackHistory";
import { ReportsStyled } from "./styled";
import { Badge, Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { CSVLink } from "react-csv";
import moment from "moment";
import getFlowNumber from "../../../helpers/getFlowNumber";
import roleToString from "../../../helpers/roleToString";
import { t } from "i18next";

const axios = require("axios");

interface Props extends RouteComponentProps<any> {}

@observer
class Reports extends React.PureComponent<Props, any> {
  @observable track_history_data: any = [];
  @observable csv_data: any = [];
  @observable sensor_number: string = "";
  @observable who: string;
  @observable type: string;
  @observable flow: any;
  @observable dateRange: any = [];
  @observable tableHeader: any = [
    { label: t("id"), key: "id" },
    { label: t("sensorNumber"), key: "sensor_id" },
    { label: t("name"), key: "name" },
    { label: t("role"), key: "role" },

    { label: t("type"), key: "type" },
    { label: t("kamot"), key: "flow_command" },
    { label: t("realAdd"), key: "flow" },
    { label: t("date"), key: "time_stamp" },
  ];

  componentDidMount = async () => {
    try {
      this.getTrackHistory(this.sensor_number);
    } catch (error) {
      console.error(error);
    }
  };

  onFilterChange = async (filterName: string, filterValue: any) => {
    switch (filterName) {
      case "who":
        this.who = filterValue !== "all" ? filterValue : undefined;
        break;

      case "type":
        if (filterValue !== "all") {
          filterValue === "technologist"
            ? (this.type = "0")
            : (this.type = filterValue);
        } else this.type = undefined;
        break;

      case "flow":
        this.flow = Number(filterValue) * 100;
        break;

      case "date":
        this.dateRange = filterValue;

        break;

      default:
        break;
    }

    let urlParams: Record<string, any> = {
      sensor_id: this.sensor_number,

      // time_stamp: 1637858101151
    };
    this.who && (urlParams.who = this.who);
    this.type && (urlParams.type = this.type);
    this.flow && (urlParams.flow = this.flow);
    if (this.dateRange.length > 1) {
      urlParams.date = {
        start: this.dateRange[0],
        end: this.dateRange[1],
      };
    }

    console.log(urlParams);

    try {
      let sensor_number = await axios.get(
        `${process.env.REACT_APP_API_URL}/flow_commands?` +
          parseObjectToUrlParams(urlParams)
      );
      this.track_history_data = sensor_number.data;
    } catch (error) {
      console.error(error);
    }
  };

  getTrackHistory = async (track_number: string) => {
    try {
      this.sensor_number = track_number;
      const now = Date.now();
      const lastMonthData = now - 24 * 30 * 60 * 60 * 1000;
      const lastMont = new Date(lastMonthData).getTime();

      let track_history_data = await axios.get(
        `${process.env.REACT_APP_API_URL}/flow_commands?` +
          parseObjectToUrlParams({
            sensor_id: track_number,
            date: {
              start: lastMont,
              end: now,
            },
          })
      );

      console.log(track_history_data);
      track_history_data.data.forEach((record: any) => {
        let object = {
          flow: record.flow > 0 ? record.flow / 100 : 0,
          flow_command:
            getFlowNumber(record.flow_command) > 0
              ? getFlowNumber(record.flow_command) / 100
              : 0,
          id: record.id,
          name: record.name,
          role: roleToString(record.role),
          sensor_id: record.sensor_id,
          time_stamp: moment(record.time_stamp).format("DD/MM//YYYY HH:mm"),
          type: record.type === "water" ? t("water") : t("mosaf"),
          who: 0 ? t("factory") : t("driver"),
        };
        this.csv_data.push(object);
      });

      this.track_history_data = track_history_data.data;
    } catch (error) {
      console.error(error);
    }
  };

  columns = [
    {
      title: t("action"),
      dataIndex: "createdAt",
      render: (type: string, record: any) => {
        if (record.who === 0) {
          return record.flow_command === "STOP_FLOW" ? (
            <Badge className="flex" status="warning">
              {t("stop")}
            </Badge>
          ) : (
            <Badge className="flex" status="success">
              {t("start")}{" "}
            </Badge>
          );
        } else {
          return (
            <Badge className="flex" status="error">
              {t("manual")}{" "}
            </Badge>
          );
        }
      },
    },
    {
      title: t("addon"),
      dataIndex: "createdAt",
      render: (type: string, record: any) => {
        return record.type === "water" ? "מים" : "מוסף";
      },
    },
    {
      title: t("amount"),
      dataIndex: "flowCommand",
      render: (type: string, record: any) => {
        let result: any = /[^_]*$/.exec(record!.flow_command)[0];
        result = result / 100;
        if (isNaN(result) || record.who === 1) {
          result = 0;
        }

        return result;
      },
    },
    {
      title: t("realAdd"),
      dataIndex: "flow",
      render: (type: string, record: any) => {
        // if (isNaN(result)) {
        //   result = "-";
        // }
        return record.flow / 100;
      },
    },
    {
      title: t("name"),
      dataIndex: "name",
      render: (type: string, record: any) => {
        return record.who === 0 ? record.name : "-";
      },
    },
    {
      title: t("role"),
      dataIndex: "role",
      render: (type: string, record: any) => {
        if (record.who === 1) {
          return t("driver");
        }
        return roleToString(record.role) || "no role";
      },
    },
    {
      title: t("date"),
      dataIndex: "time_stamp",
      render: (type: string, record: any) => {
        return moment(record.time_stamp).format("DD/MM/YY , HH:mm");
      },
    },
  ];

  render() {
    return (
      <ReportsStyled>
        <div className="content-page">
          <div className="flex page-title">
            <TopFilter
              class={"listcustomermessage-filter"}
              background={false}
              filters={["sensor", "date", "type", "who", "amount"]}
              changeTrackNumber={(track_number) =>
                this.getTrackHistory(track_number)
              }
              onFilterChange={this.onFilterChange}
            />

            <CSVLink
              className="csv-link"
              filename={`bsq_report_${
                this.sensor_number
              }_${moment().format()}.csv`}
              headers={this.tableHeader}
              data={this.csv_data}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="64"
                viewBox="0 0 56 64"
                width="56"
              >
                <path
                  clip-rule="evenodd"
                  d="m5.106 0c-2.802 0-5.073 2.272-5.073 5.074v53.841c0 2.803 2.271 5.074 5.073 5.074h45.774c2.801 0 5.074-2.271 5.074-5.074v-38.605l-18.903-20.31h-31.945z"
                  fill="#45b058"
                  fill-rule="evenodd"
                />
                <path
                  d="m20.306 43.197c.126.144.198.324.198.522 0 .378-.306.72-.703.72-.18 0-.378-.072-.504-.234-.702-.846-1.891-1.387-3.007-1.387-2.629 0-4.627 2.017-4.627 4.88 0 2.845 1.999 4.879 4.627 4.879 1.134 0 2.25-.486 3.007-1.369.125-.144.324-.233.504-.233.415 0 .703.359.703.738 0 .18-.072.36-.198.504-.937.972-2.215 1.693-4.015 1.693-3.457 0-6.176-2.521-6.176-6.212s2.719-6.212 6.176-6.212c1.8.001 3.096.721 4.015 1.711zm6.802 10.714c-1.782 0-3.187-.594-4.213-1.495-.162-.144-.234-.342-.234-.54 0-.361.27-.757.702-.757.144 0 .306.036.432.144.828.739 1.98 1.314 3.367 1.314 2.143 0 2.827-1.152 2.827-2.071 0-3.097-7.112-1.386-7.112-5.672 0-1.98 1.764-3.331 4.123-3.331 1.548 0 2.881.467 3.853 1.278.162.144.252.342.252.54 0 .36-.306.72-.703.72-.144 0-.306-.054-.432-.162-.882-.72-1.98-1.044-3.079-1.044-1.44 0-2.467.774-2.467 1.909 0 2.701 7.112 1.152 7.112 5.636.001 1.748-1.187 3.531-4.428 3.531zm16.994-11.254-4.159 10.335c-.198.486-.685.81-1.188.81h-.036c-.522 0-1.008-.324-1.207-.81l-4.142-10.335c-.036-.09-.054-.18-.054-.288 0-.36.323-.793.81-.793.306 0 .594.18.72.486l3.889 9.992 3.889-9.992c.108-.288.396-.486.72-.486.468 0 .81.378.81.793.001.09-.017.198-.052.288z"
                  fill="#fff"
                />
                <g clip-rule="evenodd" fill-rule="evenodd">
                  <path
                    d="m56.001 20.357v1h-12.8s-6.312-1.26-6.128-6.707c0 0 .208 5.707 6.003 5.707z"
                    fill="#349c42"
                  />
                  <path
                    d="m37.098.006v14.561c0 1.656 1.104 5.791 6.104 5.791h12.8l-18.904-20.352z"
                    fill="#fff"
                    opacity=".5"
                  />
                </g>
              </svg>
            </CSVLink>
          </div>
          <div className="box">
            <TrackHistory
              trackHistory={this.track_history_data}
              columns={this.columns}
            />
          </div>
        </div>
      </ReportsStyled>
    );
  }
}
export default withRouter(Reports);
