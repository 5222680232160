import { observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import ConcreteFluidsPrediction from "../../../../components/ConcreteFluidsPrediction/ConcreteFluidsPrediction";
import { ConcreteViewStyled } from "./styled";

interface Props extends RouteComponentProps<any> {}

@observer
class ConcretePrediction extends React.PureComponent<Props, any> {
  @observable visible: boolean = false;
  @observable mixer_number: number = 1;

  componentDidMount = async () => {
    try {
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    return (
      <ConcreteViewStyled>
        <ConcreteFluidsPrediction />
      </ConcreteViewStyled>
    );
  }
}
export default withRouter(ConcretePrediction);
