import styled from "styled-components";

const StatusStyled = styled.div`
  .status.flex {
    position: absolute;
    bottom: 8px;
    width: 88%;
  }

  .loader .title {
    line-height: 3;
  }
  .loader img {
    width: 57px;
  }

  .value {
    font-weight: bold;
    padding: 4px 10px;
    font-size: 16px;
    line-height: 1;
  }
`;

export { StatusStyled };
