import {
  DownloadOutlined,
  EditOutlined,
  FilterOutlined,
  LineChartOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { Button, Modal, Table, message, Form, Input, Tooltip } from "antd";
import { observable } from "mobx";
import { inject, observer } from "mobx-react";
import moment from "moment";
import React from "react";
import { CSVLink } from "react-csv";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import SlumpSlider from "../../../../components/SlumpSlider/SlumpSlider";
import parseObjectToUrlParams from "../../../../utils/parseObjectToUrlParams";
import TopFilter from "../../common/TopFilter/TopFilter";
import TrackHistory from "../../Dashboard/TrackHistory/TrackHistory";
import CementeTypes from "./PageDisplay/PageDisplay";
import MoreInfo from "./MoreInfo/MoreInfo";
import { ConcreteDeliveryStyled } from "./styled";
import axios from "axios";
import {
  MeasurementsListModal,
  StartUploadingModal,
} from "../../Messurment/components/modals";
import { t } from "i18next";
import AuthStore from "../../../../stores/auth/auth.store";
import {
  numberToFactory,
  numberToFactorySingle,
} from "../../../../helpers/numberToFactory";
import PageDisplay from "./PageDisplay/PageDisplay";

interface Props extends RouteComponentProps<any> {
  authStore?: AuthStore;
  deliveries?: any;
  report?: boolean;
  report_name?: string;
  goBack?: () => void;
}

@inject("authStore")
@observer
class ConcreteDelivery extends React.PureComponent<Props, any> {
  @observable visible: boolean = false;
  @observable concrete_data: any[] = [];
  @observable mixer_number: number = 1;
  @observable sensor_number: string;
  @observable track_history_data: any = [];
  @observable csv_data: any = [];
  @observable expandedRowKeys: any = [];
  @observable filters: any = [];
  @observable settingData: any = {};
  @observable who: string;
  dateRange: Date[] = [];

  @observable showUploadingModal: boolean = false;
  @observable showMessurmentModal: boolean = false;
  @observable udated_record_id: number = 0;

  @observable showConos: boolean = false;

  @observable tableHeader: any = [
    { label: "מספר רשומה", key: "order_id" },
    { label: "מספר מפעל", key: "factory_id" },
    { label: "מספר ערבל", key: "sensor_id" },
    { label: "תאריך", key: "createdAt" },
    { label: "שעת העמסה", key: "date_upload" },
    { label: "שעת פריקה", key: "date_download" },
    { label: "סומך - הזמנה", key: "slump_orderd" },
    { label: "סומך - יצור", key: "slump_at_loading" },
    { label: "סומך - פריקה", key: "slump_at_unloading1" },
  ];

  componentDidMount = async () => {
    try {
      this.getDeliveryData();
      this.settingData = JSON.parse(localStorage.getItem("deliveryData"));
    } catch (error) {
      console.error(error);
    }
  };

  getDeliveryData = async (filter?: any) => {
    const group_id = this.props!.authStore!.getUserGroipID();
    console.log({ group_id });
    const rsp = await axios.get(
      `${process.env.REACT_APP_API_URL}/concrete/deleviry/group/${group_id}`
    );

    this.track_history_data = rsp.data.filter(
      (record: any) => record.delivery_duration > 1
    );
  };

  onFilterChange = async (filterName: string, filterValue: any) => {
    console.log("filterName", filterName, "filterValue", filterValue);

    if (filterName === "loading_concreate_tree") {
      const { type, value } = filterValue;
      if (type === "all") {
        this.filters.mixture_code = undefined;
      } else {
        this.filters.mixture_code = type;
      }
    } else {
      this.filters[filterName] = filterValue;
    }

    
    if (filterName === "addons") {
      this.filters.suspected_water_addition = filterValue;
    }

    const group_id = this.props!.authStore!.getUserGroipID();

    let delevery_data = await axios.get(
      `${process.env.REACT_APP_API_URL}/concrete/deleviry/group/${group_id}?` +
        parseObjectToUrlParams(this.filters)
    );
    this.track_history_data = delevery_data.data;
  };

  formatDates(data: any[]) {
    return data.map((item) => ({
      ...item,
      createdAt: moment(item.createdAt).format("DD/MM/YYYY"),
      date_upload: moment(item.date_upload).format("HH:mm"),
      date_download: moment(item.date_download).format("HH:mm"),
    }));
  }

  handleRowExpand = (expanded: any, record: any) => {
    if (expanded) {
      this.expandedRowKeys = [record.key];
    } else {
      this.expandedRowKeys = [];
    }
  };

  columns = [
    {
      title: t("order_id"),
      dataIndex: "order_id",
      render: (value: string, record: any) => {
        // const random_concreate = Math.floor(Math.random() * (1459 - 500 + 1)) + 500;
        return value && value !== "0" ? (
          <div className="flex">
            <div className="match"></div>
            <div>{value}</div>
          </div>
        ) : (
          record.id
        );
      },
    },

    {
      title: t("sensor"),
      dataIndex: "sensor_id",
    },

    {
      title: t("factory"),
      dataIndex: "factory_id",
      render: (value: string, record: any) => {
        return numberToFactorySingle(String(value));
      },
    },

    {
      title: t("date"),
      dataIndex: "createdAt",
      render: (value: string, record: any) => {
        return moment(value).format("DD/MM/YY");
      },
    },
    {
      title: t("dateLoading"),
      dataIndex: "createdAt",
      render: (value: string, record: any) => {
        const time_upload = moment(value).format("HH:mm");
        const time_downloading = moment(record.date_download);
        const formattedTime = time_downloading.isValid()
          ? moment(time_downloading).format("HH:mm")
          : t("working");
        return `${time_upload} - ${formattedTime}`;
      },
    },
    {
      title: t("duration"),
      dataIndex: "date_download",
      render: (value: string, record: any) => {
        return record.delivery_duration
          ? record.delivery_duration + " " + t("minutes")
          : "-";
      },
    },

    {
      title: t("cubic"),
      dataIndex: "cubic",
      render: (value: number, record: any) => {
          if (value >= 10 && value <= 99 && value !== 10) {
              const numStr = value.toString();
              return numStr[0] + '.' + numStr[1];
          }
          return value;
      },
    },

    {
      title: t("planning"),
      dataIndex: "slump_orderd",
      render: (value: string, record: any) => {
        return value ? value : "-";
      },
    },

    {
      title: t("production"),
      dataIndex: "slump_at_loading",
      render: (value: string, record: any) => {
        return value ? value : "-";
      },
    },

    {
      title: t("supply"),
      dataIndex: "slump_at_unloading1",
      render: (value: string, record: any) => {
        return value ? value : "-";
      },
    },

    // {
    //   title: t("orderedStrength"),
    //   dataIndex: "ordered_strength",
    //   render: (value: string, record: any) => {
    //     return value ? value : "-";
    //   },
    // },

    // {
    //   title: t("strengthCheck2"),
    //   dataIndex: "strength_28",
    //   render: (value: string, record: any) => {
    //     return value ? value : "-";
    //   },
    // },
    {
      title: t("moreInfo"),
      dataIndex: "more",
      render: (type: string, record: any) => {
        return (
          <div className="flex gap10">
            {this.settingData &&
              this.settingData.temp_count < record.high_temp && (
                <Tooltip title={t("high_temperature")}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_599_4701)">
                    <rect width="20" height="20" rx="10" fill="#E02424" />
                    <path
                      d="M10.2224 5.23801H14.6668M10.2224 7.61897H12.4446M10.2224 9.99992H14.6668M6.66683 4.28563C6.66683 3.75965 7.0648 3.33325 7.55572 3.33325C8.04664 3.33325 8.44461 3.75965 8.44461 4.28563V12.1028C9.22945 12.4702 9.77794 13.3093 9.77794 14.2856C9.77794 15.6006 8.78302 16.6666 7.55572 16.6666C6.32842 16.6666 5.3335 15.6006 5.3335 14.2856C5.3335 13.3093 5.88198 12.4702 6.66683 12.1028V4.28563Z"
                      stroke="white"
                      strokeWidth="1.33333"
                      strokeLinecap="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_599_4701">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                </Tooltip>
              )}

            {this.settingData &&
              this.settingData.slump_count <
                record.slump_at_loading - record.slump_at_unloading1 && (
                <Tooltip title={t("high_slump_difference")}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_599_4702)">
                    <rect width="20" height="20" rx="10" fill="#E02424" />
                    <path
                      d="M15.3332 15.3334H5.33317C4.96498 15.3334 4.6665 15.0349 4.6665 14.6667V4.66675M6.71779 5.48726L14.5127 13.2821M14.5127 13.2821V10.8206M14.5127 13.2821H12.0511"
                      stroke="white"
                      stroke-width="1.33333"
                      stroke-linecap="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_599_4702">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                </Tooltip>
              )}

             {record.factory === 0 && (
                <Tooltip title={t("diffrent_site_loading")}>
                  <div className="icon-red">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" style={{fill: "white"}}><path d="M10 20S3 10.87 3 7a7 7 0 1 1 14 0c0 3.87-7 13-7 13zm0-11a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"/></svg>
                  </div>
                </Tooltip>
              )}

            {record.suspected_water_addition ? (
               <Tooltip title={t("water_addon_by_driver")}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1044_7353)">
                  <rect width="30" height="30" rx="15" fill="#E02424" />
                  <path
                    d="M22 17.8571C22 21.802 18.866 25 15 25C11.134 25 8 21.802 8 17.8571C8 13.9123 15 5 15 5C15 5 22 13.9123 22 17.8571Z"
                    stroke="white"
                    stroke-width="2"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1044_7353">
                    <rect width="30" height="30" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              </Tooltip>
            ) : null}
          </div>
        );
      },
    },
    // {
    //   title: t("moreInfo"),
    //   dataIndex: "more",
    //   render: (type: string, record: any) => {
    //     return (
    //       <div className="flex">
    //         <Link to={`/concrete/delivery/view/?id=${record.id}`}>
    //           <LineChartOutlined />
    //         </Link>
    //       </div>
    //     );
    //   },
    // },
  ];

  openPop = async (id: number) => {
    this.udated_record_id = id;
    this.showUploadingModal = true;
    this.showConos = true;
  };

  openMessuermentPop = async (id: number) => {
    this.udated_record_id = id;
    this.showMessurmentModal = true;
  };

  addLoading = async (values: any) => {
    try {
      if (this.udated_record_id === 1) {
        debugger;
        const rsp = await axios.post(
          `${process.env.REACT_APP_API_URL}/concrete/deleviry/`,
          values
        );
        console.log(rsp);
        message.success("רשומה התווספה בהצלחה");
      } else {
        const rsp = await axios.post(
          `${process.env.REACT_APP_API_URL}/concrete/deleviry/${this.udated_record_id}`,
          values
        );
        message.success("רשומה התעדכנה בהצלחה");
      }
    } catch (error) {
      console.error(error);
    }
  };

  onFinish = async (values: any) => {
    try {
      console.log(values);

      const rsp = await axios.post(
        `${process.env.REACT_APP_API_URL}/concrete/deleviry/${this.udated_record_id}`,
        values
      );
      console.log(rsp);
      message.success("רשומה התווספה בהצלחה");
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    return (
      <ConcreteDeliveryStyled>
        <div className="container deleviry_history">
          {!this.props.report ? (
            <>
              <div className="flex">
                <div className="title">{t("history")} </div>
                {/* <div className="add-delevery" onClick={() => this.openPop(1)}>
                  +
                </div> */}
              </div>

              <TopFilter
                class={"concreate-filter"}
                background={false}
                filters={[
                  "date",
                  "factory",
                  "sensor",
                  "addons",
                  "loading_concreate_tree",
                ]}
                csv={{
                  show: true,
                  tableHeader: this.tableHeader,
                  info: this.formatDates(
                    this.track_history_data ? this.track_history_data : []
                  ),
                }}
                changeTrackNumber={(track_number) =>
                  this.onFilterChange("sensor_id", track_number)
                }
                onFilterChange={this.onFilterChange}
              />
            </>
          ) : (
            <div className="flex gap10 marginBottom20">
              <div onClick={this.props.goBack}>
                {" "}
                <RightOutlined />
              </div>
              <div className="bold">{this.props.report_name}</div>
            </div>
          )}
          <div className="box">
            <Table
              className="deleviry"
              dataSource={
                this.props.deliveries && this.props.deliveries.length > 0
                  ? this.props.deliveries
                  : this.track_history_data
              }
              expandable={{
                expandedRowRender: (record) => {
                  return this.expandedRowKeys.includes(record.key) ? (
                    <div style={{ margin: 0 }}>
                      <PageDisplay record={record} />
                      {/* <MoreInfo record={record} /> */}
                    </div>
                  ) : null;
                },
                onExpand: this.handleRowExpand,
                rowExpandable: (record) => record.id,
              }}
              rowKey={(record) => record.id}
              columns={this.columns}
            />
          </div>
        </div>
        <StartUploadingModal
          showModal={this.showUploadingModal}
          hide={() => (this.showUploadingModal = false)}
          onFinish={(values) => this.addLoading(values)}
        />
        <Modal
          visible={this.showMessurmentModal}
          onCancel={() => (this.showMessurmentModal = false)}
          footer={null}
          destroyOnClose
        >
          <Form
            onFinish={this.onFinish}
            className="padding20 form-settings"
            initialValues={{ cone1: 0, cone2: 0, cone3: 0 }}
          >
            <Form.Item
              label="קונוס 1"
              name="cone1"
              rules={[{ required: true, message: "שדה חובה" }]}
            >
              <Input type="number" />
            </Form.Item>
            <Form.Item
              label="קונוס 2"
              name="cone2"
              rules={[{ required: true, message: "שדה חובה" }]}
            >
              <Input type="number" />
            </Form.Item>
            <Form.Item
              label="קונוס 3"
              name="cone3"
              // rules={[{ required: true, message: "שדה חובה" }]}
            >
              <Input type="number" />
            </Form.Item>
            <Button className="button-center full" htmlType="submit">
              שמור מדידה
            </Button>
          </Form>
        </Modal>
      </ConcreteDeliveryStyled>
    );
  }
}
export default withRouter(ConcreteDelivery);
