import BaseService from "../base.service";

class SensorService extends BaseService {
  constructor(baseUrl: string = "") {
    super(baseUrl);
  }

  getSensors = () => {
    return this.get(
      `?action=get_sensors_data&username=nadav&password=th37Ds!n`
    );
  };
}

export default SensorService;
