import styled from "styled-components";

const TrackHistoryStyled = styled.div`
  th.ant-table-cell {
    font-size: 12px;
  }

  td.ant-table-cell {
    font-size: 14px;
    padding: 4px;
  }

  tr:nth-child(odd) {
    background: #f7f7f7;
  }

  .ant-badge-dot {
    width: 10px;
    height: 10px;
    position: absolute;
    top: 10px;
  }

  span.flex.ant-badge.ant-badge-status {
    padding-right: 20px;
  }

  th.ant-table-cell {
    text-align: right;
  }
  tr.ant-table-row.ant-table-row-level-0 {
    text-align: right !important;
  }
`;

export { TrackHistoryStyled };
