import { observer } from "mobx-react";
import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import CementState from "../../../components/CementState/CementState";
import { ConcreteStyled } from "./styled";

interface Props extends RouteComponentProps<any> {}

@observer
class Concrete extends React.PureComponent<Props, any> {
  render() {
    return (
      <ConcreteStyled>
        <CementState cementType="עדש" />
      </ConcreteStyled>
    );
  }
}
export default withRouter(Concrete);
