
      import React from "react";
      import { withRouter,RouteComponentProps } from "react-router-dom";
	  import {ProfileStyled } from './styled';
	  
	  

      interface Props extends RouteComponentProps<any> {
		
	  };
	  

	  

      class Profile extends React.PureComponent<Props, any> {
		
		componentDidMount = async () => {
		try {
	
			

		} catch (error) {
			console.error(error);
		}
		};


		render() {
          return (
            <ProfileStyled>
                
            Profile
 
            </ProfileStyled>
          );
        }
      }
      export default withRouter(Profile);
      