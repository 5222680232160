import React, { useEffect, useState } from 'react';
import { GoogleMap, LoadScript, Marker, Polyline } from '@react-google-maps/api';
import { toJS } from 'mobx';

const containerStyle = {
  width: '100%',
  height: '500px',
};


const RoadMapComponent = ({locations}:any) => {
  const locations_array = toJS(locations);
  const [map, setMap] = useState(null);
  const center = locations_array ? locations_array[0] :{ lat: 37.77, lng: -122.42 };

  console.log(center)
  console.log({locations_array})
  const handleMapLoad = (mapInstance:any) => {
    setMap(mapInstance);
  };

  useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();
      locations_array?.forEach((location:any) => bounds.extend(location));
      map.fitBounds(bounds);
    }
  }, [map]);

  return (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={13}
        onLoad={handleMapLoad}
      >
        {locations_array.length > 0 && (
          <>
            <Marker
              position={locations_array[0]}
              icon="http://maps.google.com/mapfiles/ms/icons/green-dot.png"
              title="Start"
            />
            <Marker
              position={locations_array[locations_array.length - 1]}
              icon="http://maps.google.com/mapfiles/ms/icons/red-dot.png"
              title="Finish"
            />
            <Polyline
              path={locations_array}
              options={{
                geodesic: true,
                strokeColor: '#FF0000',
                strokeOpacity: 1.0,
                strokeWeight: 2
              }}
            />
          </>
        )}
      </GoogleMap>
  );
};

export default RoadMapComponent;
