import {
  AutoComplete,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Select,
} from "antd";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import debounce from "../../../../utils/debounce";
import { observable } from "mobx";
import { inject, observer } from "mobx-react";
import { TopFilterStyled } from "./styled";
import AuthStore from "../../../../stores/auth/auth.store";
import { t } from "i18next";
import moment from "moment";
import { CSVLink } from "react-csv";
const axios = require("axios");

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Option: AutoCompleteOption } = AutoComplete;

// const onSearch = (value: any) => console.log(value);

type Filter =
  | "date"
  | "type"
  | "sensor"
  | "factory"
  | "loading_concreate_strength"
  | "who"
  | "amount"
  | "search"
  | "addons"
  | "loading_concreate_type"
  | "loading_concreate_multi_type"
  | "loading_concreate_tree"
  | "strength"
  | "order_id"
  | "project";

type Mix = {
  id: number;
  mixture: string;
  products: {
    name: string;
    id: number;
  }[];
};

type MixFamily = {
  name: string;
  mixtures: Mix[];
};

type FilterTree = MixFamily[];
type Mixture = {
  code: string;
  mixture: string;
};
type Props = RouteComponentProps<any> & {
  authStore?: AuthStore;
  class?: string;
  onChange?: (filters: any[]) => void;
  filters: Filter[];
  background?: boolean;
  csv?: any;
  changeTrackNumber: (track_number: any) => void;
  onFilterChange?: (filter: string, value: any) => void;
  filterConcreteTypesList?: (type: Mixture) => boolean;
  filterConcreteTypesCount?: (type: Mixture) => number;
};

async function getSensors({
  group_id,
  factory_id,
}: {
  group_id?: string;
  factory_id?: string | number;
}) {
  let sensors = await axios.get(`${process.env.REACT_APP_API_URL}/sensors`, {
    params: { group_id, factory_id },
  });

  return sensors.data.sensors_data;
}



async function getFactories(group_id?: string) {
  const rsp = await axios.get(
    `${process.env.REACT_APP_API_URL}/factories/getByCompany/${group_id}`
  );

  return rsp.data;
}

async function getMixtures(group_id?: string)  {
  const rsp = await axios.get(
    `${process.env.REACT_APP_API_URL}/mixture/from-con-delivery/${group_id}`
  );

  return rsp.data;
}

async function getMixturesTree(group_id?: string)  {
  const rsp = await axios.get(
    `${process.env.REACT_APP_API_URL}/mixture/products-tree/${group_id}`
  );
  return rsp.data;
}

function Mixtures(props: {
  mixtures: {
    key: string;
    name: string;
  }[];
  onClose?: (mixture: { key: string; name: string }) => void;
}) {
  if (props.mixtures.length === 0) {
    return null;
  }
  return (
    <div className="flex flexRow align-center f18 bold gap20">
      <div>Mixtures</div>
      <div className="flex flexRow align-center gap20">
        {props.mixtures.map((mixture) => (
          <div
            key={mixture.key}
            className="paddingHor10 paddingVer20 bg-white flex gap15"
          >
            {mixture.name}
            <img src="/images/filter-cog.svg" alt="close" />
            <img
              src="/images/close.svg"
              alt="close"
              className="pointer"
              onClick={props.onClose ? () => props.onClose(mixture) : undefined}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

const concreteStrength = [
  { name: "ב20", item: 1 },
  { name: "ב30", item: 2 },
  { name: "ב40", item: 3 },
  { name: "ב50", item: 4 },
  { name: "ב60", item: 5 },
  { name: "ב70", item: 6 },
  { name: "ב80", item: 7 },
];
@inject("authStore")
@observer
class TopFilter extends React.PureComponent<Props, any> {
  @observable sensors: any[];
  @observable mixtures: Mixture[];
  @observable mixTree: FilterTree;
  @observable selectedMixtures: any[] = [];
  @observable factoryId: any;
  @observable factories: any;
  @observable projects: any;

  componentDidMount = async () => {
    this.factoryId = this.props!.authStore!.user.factory_id;
    const group_id = this.props!.authStore!.getUserGroipID();
    this.onRangeDateChange([
      moment().subtract(30, "days").startOf("day"),
      moment().add(1, "days").endOf("day"),
    ]);

    try {
      if (this.props.filters.includes("loading_concreate_tree")) {
     
        getMixturesTree(group_id).then((mixtureTree: any) => {
          console.log({mixtureTree})
          this.mixTree = mixtureTree;
        });
      }
      if (
        this.props.filters.includes("loading_concreate_type") ||
        this.props.filters.includes("loading_concreate_multi_type")
      ) {
        getMixtures(group_id).then((mixtures: any) => {
          this.mixtures = mixtures;
        });

        getMixtures(group_id).then((mixtures: any) => {
          console.log({mixtures})
          this.projects = mixtures.map((mixture: any) => mixture.project_name);
        });

        console.log({prog:this.projects})
      }
      if (this.props.filters.includes("factory")) {
        getFactories(group_id).then((factories: any) => {
          this.factories = factories;
        });
      }
      getSensors({ group_id }).then((sensors: any) => {
        this.sensors = sensors;
      });
    } catch (error) {
      console.error(error);
    }
  };

  onFilterChange = (filterName: string, filterValue: any) => {
    this.props.onFilterChange(filterName, filterValue);
  };

  onFactoryChange = async (factory_id: number) => {
    getSensors({ factory_id }).then((sensors: any) => {
      this.sensors = sensors;
    });
  };

  onRangeDateChange = (dateArr: [moment.Moment, moment.Moment]) => {
    const dateRange: [number, number] = [946729684714, 4765177817183];
    if (dateArr) {
      const startDate = dateArr[0];
      const endDate = dateArr[1];

      if (startDate && endDate) {
        dateRange[0] = startDate.toDate().getTime();
        dateRange[1] = endDate.toDate().getTime();
      }
    }
    console.log("dateRange:", dateRange);

    this.onFilterChange("date", dateRange);
  };

  onSearchDebounce = debounce(async (value: string) => {
    this.onFilterChange("query", value);
  });

  render() {
    return (
      <TopFilterStyled>
        <Form initialValues={{ remember: true }}>
          <div className={this.props.class ? this.props.class : ""}>
            <div
              className={
                "filter-row full page-wrapper-container transparent " +
                (this.props.background ? "b-gray-2" : "")
              }
            >
              <div className="flex flexCol">
                <div className="flex gap20 doctorfilter wrap">
                  {this.props.filters.includes("factory") && (
                    <Form.Item name="factory" label={t("factory")}>
                      <Select
                        className="sensor_factory"
                        placeholder={t("factory")}
                        onChange={(e: any) => {
                          const value = e === "0" ? null : e;
                          this.onFilterChange("factory_id", value);
                          this.onFactoryChange(value);
                        }}
                      >
                        <Option value="all">{t("all")}</Option>

                        {this.factories?.map((cType: any) => (
                          <Option key={cType.id}>{cType.factory_name}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}

                  {this.props.filters.includes("sensor") && (
                    <Form.Item name="sensor" label={t("sensor")}>
                      <Select
                        className="sensor_number"
                        placeholder={t("mixers")}
                        onChange={(e: any) => {
                          const value = e === "0" ? null : e;
                          this.onFilterChange("sensor_id", value);
                        }}
                      >
                        <Option value="all">{t("all")}</Option>

                        {this.sensors?.map((cType: any) => (
                          <Option key={cType.sensor_id}>
                            {cType.sensor_id}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}

                  {this.props.filters.includes("project") && (
                    <Form.Item name="project" label={t("project")}>
                      <Select
                        style={{ width: 200 }}
                        className="sensor_number"
                        placeholder={t("project")}
                        onChange={(e: any) => {
                          const value = e === "0" ? null : e;
                          this.onFilterChange("project", value);
                        }}
                      >
                        <Option value="all">{t("all")}</Option>

                        {this.projects?.map((project: any) => (
                          <Option key={project}>
                            {project}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}

                  {this.props.filters.includes("order_id") && (
                    <Form.Item name="order_id" label={t("recipeId")}>
                      <Select
                        defaultValue="all"
                        onChange={(e: any) => {
                          const value = e === "0" ? null : e;
                          this.onFilterChange("order_id", value);
                        }}
                      >
                        <Option value="all">{t("recipeId")}</Option>
                        <Option value="357578969">357578969</Option>
                        <Option value="3757967">3757967</Option>
                      </Select>
                    </Form.Item>
                  )}

                  {this.props.filters.includes("type") && (
                    <Form.Item name="clinicType" label={t("type")}>
                      <Select
                        defaultValue={t("type")}
                        onChange={(e: any) => {
                          const value = e === 1 ? null : e;
                          this.onFilterChange("type", value);
                        }}
                      >
                        <Option value={"all"}>{t("all")} </Option>
                        <Option value={"water"}>{t("water")}</Option>
                        <Option value={"supplement"}>{t("mosaf")}</Option>
                      </Select>
                    </Form.Item>
                  )}

                  {this.props.filters.includes("loading_concreate_type") && (
                    <Form.Item
                      className="concreateType"
                      name="concreateType"
                      label={t("recipe")}
                    >
                      <Select
                        defaultValue="all"
                        onChange={(e: any) => {
                          console.log("e", e);
                          const mixture = this.mixtures.find(
                            (m: any) => m.code == e
                          );
                          console.log("mixture", mixture);

                          this.onFilterChange(
                            "loading_concreate_type",
                            mixture
                              ? { key: e, name: mixture?.mixture }
                              : { key: e, name: "all" }
                          );
                        }}
                      >
                        <Option value={"all"}>{t("allMixtures")}</Option>
                        {this.mixtures
                          ?.filter((v) => {
                            if (this.props.filterConcreteTypesList) {
                              return this.props.filterConcreteTypesList(v);
                            }
                            return true;
                          })
                          .map((cType) => {
                            if (this.props.filterConcreteTypesCount) {
                              const count =
                                this.props.filterConcreteTypesCount(cType);
                              return (
                                <Option
                                  key={cType.code}
                                >{`(${count}) ${cType.mixture}`}</Option>
                              );
                            }

                            return (
                              <Option key={cType.code}>{cType.mixture}</Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  )}
                  {this.props.filters.includes(
                    "loading_concreate_multi_type"
                  ) && (
                    <Form.Item
                      className="concreateType"
                      name="concreateType"
                      label={t("recipe")}
                    >
                      <Select
                        optionLabelProp="value"
                        onChange={(e: string) => {
                          console.log("e", e);
                          const mixture = this.mixtures.find(
                            (m) => m.code.toString() === e
                          );
                          if (e === "all") {
                            this.selectedMixtures = [];
                            this.onFilterChange(
                              "loading_concreate_multi_type",
                              this.selectedMixtures
                            );
                            return;
                          }
                          console.log("e", e);

                          if (this.selectedMixtures.some((m) => m.key === +e)) {
                            this.selectedMixtures =
                              this.selectedMixtures.filter((m) => m.key !== +e);
                          } else {
                            this.selectedMixtures = [
                              ...this.selectedMixtures,
                              { key: +e, name: mixture?.mixture },
                            ];
                          }
                          this.onFilterChange(
                            "loading_concreate_multi_type",
                            this.selectedMixtures
                          );
                        }}
                      >
                        <Option value={"all"}>
                          <label
                            dir="ltr"
                            className="flex align-center pointer"
                          >
                            <input
                              type="checkbox"
                              checked={this.selectedMixtures.length === 0}
                            ></input>
                            {t("allMixtures")}
                          </label>
                        </Option>
                        {this.mixtures
                          ?.filter((v) => {
                            if (
                              this.props.filterConcreteTypesList !== undefined
                            ) {
                              return this.props.filterConcreteTypesList(v);
                            }
                            return true;
                          })
                          .map((cType: any) => (
                            <Option key={cType.code}>
                              <label
                                dir="ltr"
                                className="flex align-center pointer"
                              >
                                <input
                                  type="checkbox"
                                  checked={this.selectedMixtures.some(
                                    (m) => m.key === cType.code
                                  )}
                                ></input>
                                <span>{cType.mixture}</span>
                              </label>
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  )}
                  
          

                  {this.props.filters.includes("loading_concreate_tree") && (
                       <AutoComplete
                       style={{ width: 400 }}
                       placeholder={t("recipe")}
                       onChange={(value: string) => {
                         const [type, val] = value.split("-");
                         this.onFilterChange("loading_concreate_tree", {
                           type,
                           value: val,
                         });
                       }}
                     >
                       {this.mixTree?.map((family: any) => (
                         <AutoComplete.Option key={`mixture-${family.code}`} value={`${family.code}`}>
                           <div className="opt1 paddingRight5 marginRight10">
                             {family.code} - ({family.mixture} )
                           </div>
                         </AutoComplete.Option>
                       ))}
                     </AutoComplete>
                  )}

                  {this.props.filters.includes("addons") && (
                    <Form.Item name="addons" label={"addons"}>
                      <Select
                        defaultValue={t("driverIntervention")}
                        onChange={(e: any) => {
                          const value = e === "0" ? null : e;
                          this.onFilterChange("addons", value);
                        }}
                      >
                        <Option value="all">{t("all")}</Option>
                        <Option value="1">{t("yes")}</Option>
                        <Option value="0">{t("no")}</Option>
                      </Select>
                    </Form.Item>
                  )}

                  {this.props.filters.includes(
                    "loading_concreate_strength"
                  ) && (
                    <Form.Item
                      name="concreateType"
                      label={t("concreateStrength")}
                    >
                      <Select
                        defaultValue="all"
                        onChange={(e: any) => {
                          const value = e === 1 ? null : e;
                          this.onFilterChange(
                            "loading_concreate_strength",
                            value
                          );
                        }}
                      >
                        <Option value={"all"}>{t("all")}</Option>
                        {concreteStrength.map((cType) => (
                          <Option key={cType.name}>{cType.name}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}

                  {this.props.filters.includes("who") && (
                    <Form.Item name="who" label={t("who")}>
                      <Select
                        defaultValue={t("all")}
                        onChange={(e: any) => {
                          const value = e === "0" ? null : e;
                          this.onFilterChange("who", value);
                        }}
                      >
                        <Option value="all">{t("all")}</Option>
                        <Option value="1">{t("driver")}</Option>
                        <Option value="technologist">{t("factory")}</Option>
                      </Select>
                    </Form.Item>
                  )}

                  {this.props.filters.includes("strength") && (
                    <Form.Item name="strength" label={t("match")}>
                      <Select
                        defaultValue={t("match")}
                        onChange={(e: any) => {
                          this.onFilterChange("strength", e);
                        }}
                      >
                        <Option value="0">{t("all")}</Option>
                        <Option value="1">{t("strength")}</Option>
                      </Select>
                    </Form.Item>
                  )}

                  {this.props.filters.includes("amount") && (
                    <Form.Item name="amount" label={t("who")}>
                      <InputNumber
                        min={0}
                        placeholder={t("amountAdded")}
                        max={1000}
                        // defaultValue={15}
                        onChange={(e: any) => {
                          const value = e === 1 ? null : e;
                          this.onFilterChange("flow", value);
                        }}
                      />
                    </Form.Item>
                  )}

                  {this.props.filters.includes("date") && (
                    <Form.Item name=" " label={t("dates")}>
                      <RangePicker
                        placeholder={[t("fromDate"), t("toDate")]}
                        onChange={this.onRangeDateChange}
                        defaultValue={[
                          moment().subtract(30, "days").startOf("day"),
                          moment().add(1, "days").endOf("day"),
                        ]}
                        showTime={{ format: "HH:mm" }}
                      />
                    </Form.Item>
                  )}

                  {this.props.filters.includes("search") && (
                    <Form.Item name="Search" label={t("search")}>
                      <AutoComplete
                        placeholder={t("searchPlaceholder")}
                        allowClear
                        onChange={(e) => this.onSearchDebounce(e)}
                      ></AutoComplete>
                    </Form.Item>
                  )}

                  <Divider className="divider" type={"vertical"} />

                  {this.props.csv && (
                    <CSVLink
                      className="download_csv"
                      filename={`conext_report_${moment().format()}.csv`}
                      headers={this.props.csv.tableHeader}
                      data={this.props.csv.info}
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_465_3378)">
                          <path
                            d="M12.8888 6.38893L7.83325 1.33337M12.8888 6.38893H8.49992C8.13173 6.38893 7.83325 6.09045 7.83325 5.72226V1.33337M12.8888 6.38893V12.1667M7.83325 1.33337H1.99992C1.63173 1.33337 1.33325 1.63185 1.33325 2.00004V18C1.33325 18.3682 1.63173 18.6667 1.99992 18.6667H12.2221C12.5903 18.6667 12.8888 18.3682 12.8888 18V12.1667M12.8888 12.1667H8.19436M12.8888 12.1667H18.6666M18.6666 12.1667L15.7777 9.27782M18.6666 12.1667L15.7777 15.0556"
                            stroke="#FF6700"
                            strokeWidth="2"
                            strokeLinecap="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_465_3378">
                            <rect width="20" height="20" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </CSVLink>
                  )}
                </div>
                <div>
                  <Mixtures
                    mixtures={this.selectedMixtures}
                    onClose={(mixture) => {
                      this.selectedMixtures = this.selectedMixtures.filter(
                        (m) => m.key !== mixture.key
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Form>
      </TopFilterStyled>
    );
  }
}
export default withRouter(TopFilter);
