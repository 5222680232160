import BaseHttpService, { OptionsRequest } from "../services/base.http.service";
import BaseStore from "./base.store";
import { action, observable, runInAction } from "mobx";

abstract class BaseModel {
  abstract get url(): string;

  /**
   * Hold status if update, delete, or save in server ( all action to server)
   */
  @observable loading: boolean = false;
  @observable updateLoading: boolean = false;
  @observable deleteLoading: boolean = false;
  @observable identityLoading: { [key: string]: boolean } = {};

  id: number | null = null;
  httpService: BaseHttpService;
  store: BaseStore;

  constructor(httpService: BaseHttpService, store: BaseStore, data: any[]) {
    this.store = store;
    this.httpService = httpService;
  }
  get rootUrl(): string {
    return `${this.url}/${this.id || ""}`;
  }

  @action
  public _updateData(data: any) {
    const _this: any = this;
    Object.keys(data).forEach(
      (key: any) =>
        typeof _this[key] !== "undefined" && (_this[key] = data[key])
    );
  }

  save(identityLoading?: string): OptionsRequest {
    !identityLoading && (this.updateLoading = true);
    identityLoading && (this.identityLoading[identityLoading] = true);

    const isCreate = this.id === null;
    const request = !isCreate
      ? this.httpService.put(this.rootUrl, this.toJson)
      : this.httpService.post(this.rootUrl, this.toJson);

    request.promise.then(async (response: any) => {
      if (isCreate) {
        this.store.onCreateModel(this);
      }

      const modelData = await response.clone().json();
      this._updateData(modelData);
      console.log("after update model");
      return response;
    });

    request.promise.finally(() => {
      runInAction(() => {
        identityLoading && (this.identityLoading[identityLoading] = false);
        !identityLoading && (this.updateLoading = false);
      });
    });

    return request;
  }

  saveFormData(): OptionsRequest {
    return this.httpService.formData(this.rootUrl, this.toFormData);
  }

  @action async destroy() {
    try {
      this.deleteLoading = true;
      const { promise } = this.httpService.del(this.rootUrl, this.toJson);
      await promise;
      this.deleteLoading = false;
      this.store.del(this);
      return promise;
    } catch (error) {
      this.deleteLoading = false;
      console.error(error);
      return error;
    }
  }

  abstract get toJson(): { [key: string]: any };

  get toFormData(): { [key: string]: any } {
    return {};
  }

  protected _toJson = (json: any) => JSON.parse(JSON.stringify(json));

  get dataToExport() {
    return this.toJson;
  }
}

export default BaseModel;
