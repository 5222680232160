import React, { useEffect, useState } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { DashboardMapStyled } from "./styled";
import { observer } from "mobx-react";
import CustomMarker from "./Marker";
import {
  GoogleMap,
  useJsApiLoader,
  GoogleMapProps,
  Libraries,
  Marker,
  useLoadScript,
} from "@react-google-maps/api";
import i18n from "../../i18n";
import { toJS } from "mobx";
import { t } from "i18next";
const API_KEY = "AIzaSyDDvphMh_sfgrpqvOOw2HOgel-NagtwUUo";

type Map = GoogleMap["state"]["map"];

const containerStyle = {
  width: "100%",
  height: "90vh",
};

class Size {
  constructor(width: number, height: number) {
    this.width = width;
    this.height = height;
  }
  width: number;
  height: number;
  equals() {
    return false;
  }
  toString() {
    return "";
  }
}

const libraries: Libraries = ["geometry", "drawing", "places"];

const language = i18n.language;
console.log("language", language);

function MyMap(props: GoogleMapProps) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: API_KEY,
    libraries: libraries,
    language: language,
  });

  const [map, setMap] = React.useState<Map | null>(null);

  const onLoad = React.useCallback(function callback(map: Map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    // const bounds = new window.LatLngBounds(props.center);
    // map.fitBounds(bounds);
    const language = i18n.language;
  

    map.set("language", language);
    setMap(map);
    if (props.onLoad) {
      props.onLoad(map);
    }
  }, []);

  const onUnmount = React.useCallback(function callback(map: Map) {
    setMap(null);
    if (props.onUnmount) {
      props.onUnmount(map);
    }
  }, []);

  const centerMap = () => {
    if (map) {
      map.setCenter({
        lat: +props.center?.lat!,
        lng: +props.center?.lng!,
      });
    }
  };
  useEffect(
    ()=>{
      centerMap()
    },[]
  )


  if (!isLoaded) {
    return <></>;
  }


  
  return (
    <div>
    <button onClick={centerMap} style={{ position: "absolute", top: "10px", left: "10px", zIndex: 1000 }}>
      {t("centerMap")}
    </button>

    <GoogleMap
      {...props}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={{
        mapTypeId: "roadmap", // Default map type
        mapTypeControl: true,
        gestureHandling: "greedy",
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
          position: google.maps.ControlPosition.TOP_RIGHT,
          mapTypeIds: ["roadmap", "satellite", "hybrid"] // Available map types
        },
      }}
    ></GoogleMap>
    </div>
  );
}

const MemoizedMap = React.memo(MyMap);

interface Props extends RouteComponentProps<any> {
  openPop: (i: number) => void;
  sensors: any;
  factory: any;
  factoryImg?: string;
  onLoad?: (map: Map) => void;
  toggleMixerPop: number | null;
}

const MapGoogle = observer((props: Props) => {
  console.log("factory", toJS(props.factory));

  if (!props.factory) return null;
  if (!props.sensors) return null;

  const [isFirstRender, setIsFirstRender] = useState(true);
  const [mapCenter, setMapCenter] = useState({
    lat: +props.factory.lat,
    lng: +props.factory.long,
  });

  useEffect(() => {
    if (isFirstRender) {
      setMapCenter({
        lat: +props.factory.lat,
        lng: +props.factory.long,
      });
      setIsFirstRender(false);
    }
  }, [isFirstRender, props.factory.lat, props.factory.long]);


  return (
    <DashboardMapStyled className="map-view">

  

      <MemoizedMap
        mapContainerStyle={containerStyle}
      
        mapTypeId={"hybrid"}
        center={mapCenter}
        zoom={13}
        onLoad={props.onLoad}
      >
        {/* factory marker */}
        <Marker
          icon={{
            url: props.factoryImg,
            scaledSize: new Size(50, 50),
            size: new Size(50, 50),
          }}
          position={{
            lat: Number(props?.factory?.lat),
            lng: Number(props?.factory?.long),
          }}
        ></Marker>
        {props.sensors.map((sensor: any, i: number) => (
          <CustomMarker
            togglePop={props.toggleMixerPop === sensor.sensor_id}
            key={sensor.sensor_id}
            {...sensor}
            sensor={sensor}
            openPop={() => props.openPop(sensor)}
            sensors={sensor}
          />
        ))}
      </MemoizedMap>
    </DashboardMapStyled>
  );
});
export default withRouter(MapGoogle);
