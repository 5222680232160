import React from "react";
import { Table } from "antd";
import { ChartData, slumpLegend } from "../ConcreteSlump";

type RatioType = "orderToLoading" | "loadingToUnloading" | "orderToUnloading";

type Props = {
  barsRatioType: RatioType;
  positiveOrNegative: "positive" | "negative" | "both";
  dailyOrderToLoadingSlumpChanges: {
    day: string;
    data: ChartData;
  }[];

  dailyLoadingToUnloadingSlumpChanges: {
    day: string;
    data: ChartData;
  }[];
  dailyOrderToUnloadingSlumpChanges: {
    day: string;
    data: ChartData;
  }[];
};

function changesToColumns(
  changes: {
    day: string;
    data: ChartData;
  }[],
  positiveOrNegative: "positive" | "negative" | "both" = "both"
) {
  return changes.map((item) => ({
    key: item.day,
    title: item.day,
    render: (value: any, record: (typeof slumpLegend)[number]) => {
      return (
        <div className="flex justify-center align-center fontweight600">
          {/* {item.data[record.key]} */}
          {positiveOrNegative === "both" &&
            item.data[record.key].negative + item.data[record.key].positive}
          {positiveOrNegative === "positive" && item.data[record.key].positive}
          {positiveOrNegative === "negative" && item.data[record.key].negative}
        </div>
      );
    },
  }));
}

export function OverViewTable(props: Props) {
  const [width, setWidth] = React.useState(window.innerWidth - 780);
  const [pagination, setPagination] = React.useState({ current: 1 });
  React.useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth - 780);
    });
  }, []);
  return (
    <div className="">
      <Table
        pagination={{
          current: 1,
          defaultPageSize: 7,
        }}
        scroll={{ x: "max-content" }}
        columns={[
          {
            key: "title",
            render: (
              value: (typeof slumpLegend)[number]["title"],
              record: (typeof slumpLegend)[number]
            ) => (
              <div className="fontweight600 flex gap5 align-center paddingHor10">
                <span
                  style={{
                    backgroundColor: record.color,
                    width: "20px",
                    height: "20px",
                  }}
                ></span>
                <span>{record.title}</span>
              </div>
            ),
          },
          ...(props.barsRatioType === "orderToLoading"
            ? changesToColumns(
                props.dailyOrderToLoadingSlumpChanges,
                props.positiveOrNegative
              )
            : []),
          ...(props.barsRatioType === "loadingToUnloading"
            ? changesToColumns(
                props.dailyLoadingToUnloadingSlumpChanges,
                props.positiveOrNegative
              )
            : []),

          ...(props.barsRatioType === "orderToUnloading"
            ? changesToColumns(
                props.dailyOrderToUnloadingSlumpChanges,
                props.positiveOrNegative
              )
            : []),
        ]}
        dataSource={slumpLegend}
        style={{ width: width }}
      />
    </div>
  );
}
