import { t } from "i18next";
import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
} from "recharts";

interface Props {
  data?: any;
  dataKeyX: string;
  dataLine: string;
  color: string;
  tick?: boolean;
  domain?: any;
  circle?: boolean;
}

const ChartEvents: React.FC<Props> = (props) => {
  const [xDomain, setXDomain] = useState(props.domain);

    
  const calculateWidth = () => {
    const isMobile = window.innerWidth <= 768; // Example threshold for mobile
    return isMobile ? window.innerWidth + 200  : window.innerWidth - 580;
  };

  const [width, setWidth] = useState(calculateWidth());


  useEffect(() => {
    setXDomain(props.domain);
  }, []);
  const handleBrushChange = (domain: any) => {
    setXDomain(domain);
  };

  return (
    <div style={{ width: "100%" }}>
      {" "}
      <LineChart  width={width} height={450} data={props.data} barSize={1200}>
        <CartesianGrid stroke="#ccc" />
        <XAxis  dataKey={props.dataKeyX} padding={{ left: 50, right: 50 }} />
        <YAxis
          domain={xDomain}
          tick={props.tick}
          // padding={{ top: 50, bottom: 50 }}
        />

        <Tooltip   offset={2400}/>
        <Legend />

        <Line
          type="monotone"
          dataKey={props.dataLine}
          stroke={props.color}
          strokeWidth={2}
          dot={props.circle ? { strokeWidth: 2, r: 8 } : false}
        >
          <LabelList dataKey="name" position="top" offset={20} angle={-30} />
        </Line>

        {/* <Brush dataKey="name" height={30} onChange={handleBrushChange} /> */}
        {/* <Line dataKey="uv" label={{ fill: 'red', fontSize: 20 }} /> */}
      </LineChart>
    </div>
  );
};

type MultProps = {
  data?: any;
  dataKeyX: string;
  lines: { key: string; color: string }[];
  tick?: boolean;
  domain?: [number, number];
};

export const ChartEventsMulti: React.FC<MultProps> = (props) => {
  const [xDomain, setXDomain] = useState(props.domain);
  const calculateWidth = () => {
    const isMobile = window.innerWidth <= 768; // Example threshold for mobile
    return isMobile ? window.innerWidth + 200 : window.innerWidth - 580;
  };

  const [width, setWidth] = useState(calculateWidth());

  useEffect(() => {
    setXDomain(props.domain);
  }, []);
  const handleBrushChange = (domain: any) => {
    setXDomain(domain);
  };

  return (
    <div style={{ width: "100%" }}>
      {" "}
      <LineChart width={width} height={450} data={props.data} barSize={1200}>
        <CartesianGrid stroke="#ccc" />
        <XAxis dataKey={props.dataKeyX} padding={{ left: 50, right: 50 }} />
        <YAxis
          domain={xDomain}
          tick={props.tick}
          // padding={{ top: 50, bottom: 50 }}
        />
        <YAxis
          domain={xDomain}
          tick={props.tick}
          // padding={{ top: 50, bottom: 50 }}
        />

        <Tooltip />
        <Legend />
        {props.lines.map((v, i) => (
          <Line
            type="monotone"
            dataKey={v.key}
            stroke={v.color}
            strokeWidth={2}
            dot={{ strokeWidth: 2, r: 8 }}
          >
            {i === 0 && (
              <LabelList
                dataKey="name"
                position="top"
                offset={20}
                angle={-30}
                className="flex line-clamp-2"
              ></LabelList>
            )}
          </Line>
        ))}

        {/* <Brush dataKey="name" height={30} onChange={handleBrushChange} /> */}
        {/* <Line dataKey="uv" label={{ fill: 'red', fontSize: 20 }} /> */}
      </LineChart>
    </div>
  );
};

export default ChartEvents;
