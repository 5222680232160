import { AreaChartOutlined } from "@ant-design/icons";
import { Checkbox, Drawer, Table, TableColumnsType } from "antd";
import axios from "axios";
import { t } from "i18next";
import { observable } from "mobx";
import { observer } from "mobx-react";
import moment from "moment";
import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { numberToFactorySingle } from "../../../../helpers/numberToFactory";
import { SensorsDataStyled } from "./styled";
import SensorsDataConnection from "./SensorsDataConnection";

interface Props extends RouteComponentProps<any> {
  sensors_data: any[];
  factory_preview?: boolean;
}

const columns: TableColumnsType<any> = [
  {
    title: t("sensor"),
    dataIndex: "sensor_id",
    fixed: "left",
    render: (value: number, record: any) => {
      if (record.factory_id !== 3) {
        return <div className="red">{value}</div>;
      } else {
        return value;
      }
    },
  },
  {
    title: t("factory"),
    dataIndex: "factory_id",
    render: (value: number, record: any) => {
      return numberToFactorySingle(String(value));
    },
  },
  {
    title: t("battery"),
    dataIndex: "battery",
    render: (value: number, record: any) => {
      if (!value) {
        return "-";
      }

      if (value < 50) {
        return <div className="b-red white textCenter">{value}</div>;
      } else {
        return <div className="textCenter">{value}</div>;
      }
    },
  },
  // {
  //   title: t("sensorConnections"),
  //   dataIndex: "last_android_connection",
  //   render: (value: number, record: any) => {
  //     return value ? moment(value).format("HH:mm DD/MM/YY") : "-";
  //   },
  // },

  {
    title: t("sensorTransmission"),
    dataIndex: "last_scr_message",
    render: (value: number, record: any) => {
      return value ? moment(value).format("HH:mm DD/MM/YY") : "-";
    },
  },
];

@observer
class SensorsData extends React.PureComponent<Props, any> {
  @observable openDrawer: boolean = false;
  @observable sensor_id: number = 0;

  showDrawer = () => {
    this.openDrawer = true;
  };

  onClose = () => {
    this.openDrawer = false;
  };

  convereFactoryToName = (id: number) => {
    switch (id) {
      case 1:
        return "גן יבנה";
        break;

      case 2:
        return "תפן";
        break;

      case 3:
        return "conext";
        break;
    }
  };

  updateSensorSleepMode = async (sleep_mode: boolean, sensorId: number) => {
    console.log({ sleep_mode });
    const rsp = await axios.post(
      `${process.env.REACT_APP_API_URL}/sensors/update/updateSensorSleepMode/${sensorId}`,
      { sleep_mode: sleep_mode ? 0 : 1 }
    );
  };

  // this.columns.push(this.conext_admin_data);

  componentDidMount = async () => {
    try {
      if (!this.props.factory_preview) {
        columns.push(...this.conext_admin_data);
      }
      // console.log({ da: this.props.factory_preview });
      // if (this.props.factory_preview !== undefined) {
      //   this.columns.push(this.conext_admin_data);
      // }
    } catch (error) {
      console.error(error);
    }
  };

  conext_admin_data = [
    {
      title: t("lastGPS"),
      dataIndex: "gps_timestamp",
      render: (value: number, record: any) => {
        return value ? moment(value).format("HH:mm DD/MM/YY") : "-";
      },
    },
    {
      title: t("SleepMode"),
      dataIndex: "sleep_mode",
      render: (value: boolean, record: any) => {
        return (
          <Checkbox
            checked={value}
            onChange={() => this.updateSensorSleepMode(value, record.sensor_id)}
          ></Checkbox>
        );
      },
    },

    {
      title: t("ConnectionStatus"),
      dataIndex: "android_id",
      render: (value: string, record: any) => {
        return (
          <AreaChartOutlined onClick={() => this.openDrawerDATA(record)} />
        );
      },
    },
  ];

  isTimestampWithin30Minutes(timestamp: number) {
    const currentTimeMilliseconds = new Date().getTime();
    const differenceInMinutes =
      (currentTimeMilliseconds - timestamp) / (1000 * 60);
    return differenceInMinutes <= 30 && differenceInMinutes > 0;
  }

  getRowClassName = (record: any) => {
    // You can customize this logic based on your requirements
    if (
      !this.isTimestampWithin30Minutes(record.last_scr_message) &&
      record.factory_id !== 3
    ) {
      return "active-row"; // CSS class for active rows
    }
  };

  openDrawerDATA = (record: any) => {
    // You can customize this logic based on your requirements
    this.sensor_id = record.sensor_id;
    this.showDrawer();
  };

  render() {
    return (
      <SensorsDataStyled>
        <Table
          dataSource={this.props.sensors_data}
          columns={columns}
          rowClassName={this.getRowClassName}
        />

        <Drawer
          title={t("ConnectionStatus") + " - " + this.sensor_id}
          placement="right"
          onClose={this.onClose}
          open={this.openDrawer}
          destroyOnClose={true}
        >
          <SensorsDataConnection sensor_id={this.sensor_id} />
        </Drawer>
      </SensorsDataStyled>
    );
  }
}
export default withRouter(SensorsData);
