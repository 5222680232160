import { ApexOptions } from "apexcharts";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import i18n from "../../../../../i18n";

interface ChartOverviewBarsProps {
  series: ApexOptions["series"];
  xaxis?: ApexOptions["xaxis"];
  yaxis?: ApexOptions["yaxis"];
  events?: ApexOptions["chart"]["events"];
  legend?: ApexOptions["legend"];
}

const ChartOverviewBars = (props: ChartOverviewBarsProps) => {

  const language = i18n.language;

  
  const calculateWidth = () => {
    const isMobile = window.innerWidth <= 768; // Example threshold for mobile
    return isMobile ? window.innerWidth : window.innerWidth - 780;
  };


  const calculateRange = () => {
    const isMobile = window.innerWidth <= 768; // Example threshold for mobile
    return isMobile ? 2 : 5; // Adjust range values based on your requirements
  };

  const [width, setWidth] = useState(calculateWidth());
  const [range, setRange] = useState(calculateRange());



  useEffect(() => {
  const handleResize = () => {
      setWidth(calculateWidth());
      setRange(calculateRange());
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const options: ApexOptions = {
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 4,
        columnWidth: "20px",
      },
    },
    grid: {
      padding: {
        left: 30,
        right: 30,
      },
    },
    dataLabels: {
      enabled: false,
    },

    chart: {
      type: "bar",
      height: 350,
      width: "50%",
      zoom: {
        enabled: true,
        type: "x",
        autoScaleYaxis: true,
      },
      selection: {
        enabled: true,
        xaxis: {
          min: new Date("20 Jan 2017").getTime(),
          max: new Date("10 Dec 2017").getTime(),
        },
      },

      events: props.events ?? {},
    },
    yaxis: props.yaxis ?? {},
    xaxis: {
      ...props.xaxis, // Ensure existing properties from props.xaxis are included
      range: range,
    },

    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: language ==="he" ?width-60 : width-100,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
    colors: ["#057A55", "#FACA15", "#FF6700", "#E02424"],
    fill: { opacity: 1 },
  };

  return (
    <ReactApexChart
      options={options}
      series={props.series}
      type="bar"
      width={width}
      height={350}
    />
  );
};

export default ChartOverviewBars;
