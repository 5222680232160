import BaseService from "../base.service";

class AuthService extends BaseService {
  constructor(baseUrl: string = "") {
    super(baseUrl);
  }

  getPassword = (phone: string) => {
    return this.post(`/login/get-password`, { phone });
  };

  checkPassword = (phone: string, one_time_password: string) => {
    return this.post(`/login/check-password`, { phone, one_time_password });
  };
  getCurrentUser = () => {
    return this.get("/auth/me");
  };

  logout = () => {
    return this.get("/auth/logout");
  };

  // a;
}

export default AuthService;
