import styled from "styled-components";

const PageDisplayStyled = styled.div`
  .wrapper .box {
    width: 40%;
    background: #f0791c1f;
  }

  .wrapper {
    justify-content: center;
    margin-bottom: 21px;
  }

  .wrapper2 .greenBox {
    background: #9ada6130;
  }

  .wrapper2 .redBox {
    background: #9ada6130;
  }

  .slump {
    width: 25%;
  }

  .recharts-wrapper {
    width: 100% !important;
  }

  .ant-tabs.ant-tabs-left {
    background: #fff;
    padding: 60px 25px;
    gap:25px;
  }

  .ant-tabs-nav {
    width: 193px;
    position: relative !important;
  }

  .ant-tabs-nav .ant-tabs-tab {
    border: 1px solid;
    width: 100%;
    border-radius: 6px;
    font-size: 13px;
    font-weight: 600;
  }

  .ant-tabs-tab:hover {
    color: #ff6700;
  }

  .ant-tabs-content-holder {
    border-left: none !important;
  }

  .ant-tabs-nav-wrap {
    width: 100% !important;
  }

  .ant-tabs-tab-active {
    background: #ff6700;
  }

  .ant-tabs-tab-active div {
    color: #fff !important;
  }

  .chart-event {
    width: 100%;
  }
  .ant-tabs.ant-tabs-top {
    background: #fff;
    width: 95%;
  }

  .ant-tabs-nav-wrap {
    flex: inherit !important;
    margin: 0 auto;
    display: block !important;
    width: 511px;
    border-bottom: 1px solid #9ca3af;
  }

  .chart-con {
    margin-top: 20px;
    width: 100% !important;
  }

  .ant-tabs.ant-tabs-left {
    width: 100%;
  }

  .ant-tabs-content.ant-tabs-content-left {
    width: 100% !important;
    height: 500px;
  }

  .flex.item {
    .title {
      font-size: 13px;
      font-weight: 500;
    }
    .data {
      font-weight: bold;
    }
  }

  .flex.item {
    width: 12.2vw;
    background: #fff;
    border-radius: 6px;
    flex-direction: column;
    align-items: baseline;
    padding: 13px;
  }

  .in-col {
    gap: 9px;

    .flex {
      align-items: center;
      border: 1px solid #7070700f;
      background: #ebebeb;
      text-align: center;
      flex-direction: column;
      padding: 4px;
      border-radius: 8px;
    }
  }

  .flex.item.fix {
    background: #0e9f6e;
    color: #fff;

    .title {
      color: #fff;
    }
  }

  .flex.item.error {
    background: #e02424;
    color: #fff;

    .title {
      color: #fff;
    }
  }


  .display_values {
    width: 400px;

    .ant-card {
      border: 1px solid #ddd8d8;
      border-radius: 8px;
    }

    .ant-card-body {
      padding: 12px;
    }

    
  }

  @media screen and (max-width: 600px) {
    .flex.item {
      width: auto !important;
    }


// .ant-tabs-nav-list {
//     flex-direction: row!important;
//     gap: 12px;
// }

// .ant-tabs-tab {
//     width: 50%!important;
//     height: 37px;
// }

.ant-tabs.ant-tabs-left.ant-tabs-mobile {
    flex-direction: column;
    width: 100%;
}
  }
  
  
`;

export { PageDisplayStyled };
