import React from "react";
import { withRouter } from "react-router-dom";
import TopFilter from "../common/TopFilter/TopFilter";
import { MaterialsChangeStyled } from "./styled";
import { Table } from "antd";
import { useQuery } from "react-query";

const mockMaterials = [
  {
    material: "Cement",
    change: 15,
    changeUnit: "kg",
    percentageOfDeliveriesAffected: 20,
  },
  {
    material: "Water",
    change: 25,
    changeUnit: "Liters",
    percentageOfDeliveriesAffected: 35,
  },
  {
    material: "Sand",
    change: -5,
    changeUnit: "kg",
    percentageOfDeliveriesAffected: 15,
  },
];

const mockMixtures = [
  {
    mixture: "General",
    materials: mockMaterials,
  },
  {
    mixture: "Reinforced Concrete",
    materials: mockMaterials,
  },
  {
    mixture: "Reinforced Concrete",
    materials: mockMaterials,
  },
];

function RawMaterialsChanges(props: {}) {
  const [selectedMixtures, setSelectedMixtures] = React.useState([]);
  const mixtures = useQuery(["mixtures", selectedMixtures], async () => {
    return mockMixtures;
  });
  return (
    <MaterialsChangeStyled className="padding20">
      <div className="bold f36">Changes in raw materials</div>
      <TopFilter
        changeTrackNumber={(truckNumber) => {
          console.log(truckNumber);
        }}
        onFilterChange={(filter, value) => {
          if (filter === "loading_concreate_multi_type") {
            setSelectedMixtures(value);
          }
        }}
        filters={["loading_concreate_multi_type", "date"]}
      />

      <div className="flex flexRow gap20">
        {mixtures.data?.map((mixture) => (
          <div key={mixture.mixture} className=" flex flexCol gap10">
            <div className="bold f24">{mixture.mixture}</div>
            <div className="flex flexRow gap20 bg-white f14">
              <Table
                rowClassName="border"
                bordered
                pagination={false}
                columns={[
                  {
                    title: () => {
                      return <div className="f18">Material</div>;
                    },
                    dataIndex: "material",
                    key: "material",
                    render: (value: string) => {
                      return <div className="f14">{value}</div>;
                    },
                  },
                  {
                    title: "Change",
                    dataIndex: "change",
                    key: "change",
                    render: (value: number, record) => {
                      return (
                        <div className="f14">
                          {value} {record.changeUnit}
                        </div>
                      );
                    },
                  },

                  {
                    title: "Deliveries affected",
                    dataIndex: "percentageOfDeliveriesAffected",
                    key: "percentageOfDeliveriesAffected",
                    render: (value: number) => {
                      return <div className="f14">{value}%</div>;
                    },
                  },
                ]}
                dataSource={mixture.materials?.map((material, index) => ({
                  ...material,
                  key: index,
                }))}
              />
            </div>
          </div>
        ))}
      </div>
    </MaterialsChangeStyled>
  );
}

export const RawMaterialsChangesPage = withRouter(RawMaterialsChanges);
