import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { observer } from "mobx-react";
import { List, Statistic, Table, Tooltip } from "antd";
import ConeLineStyled from "./ConeLineStyled";
import { CaretDownOutlined } from "@ant-design/icons";

interface ICone {
  cone1: number;
  cone2: number;
  cone3: number;
  prediction: any;
  cone_prediction: number;
  groupId: string;
}

interface Props extends RouteComponentProps<any> {
  cone: ICone;
}

const groupToDate = (groupId: string) =>
  new Date(parseInt(groupId)).toLocaleString();
const NumToStr = (num: any) => (isNaN(num) || !num ? "-" : num?.toFixed(2));
const columns = [
  { title: "קונוס 1", dataIndex: "cone1", key: "cone1" },
  { title: "קונוס 2", dataIndex: "cone2", key: "cone2" },
];

const getConeToPred = (cone: ICone) => {
  const cones = [cone.cone1, cone.cone2, cone.cone3].filter((v) => v);
  const cone_avg = cones.reduce((acc, curr) => acc + curr, 0) / cones.length;
  return Math.abs(cone.prediction - cone_avg);
};

@observer
class ConeLine extends React.PureComponent<Props, any> {
  render() {
    return (
      <ConeLineStyled
        className="row-data full"
        coneToPrediction={getConeToPred(this.props.cone)}
      >
        <div className="cone-row-time">
          <div>זמן:</div>
          <div>{groupToDate(this.props.cone.groupId)}</div>
        </div>
        <div className="flex row-prediction ">
          <Table
            columns={columns}
            dataSource={[this.props.cone]}
            pagination={{ hideOnSinglePage: true }}
          />
        </div>

        <div className="flex gap20 flexRow marginTop20">
          <Statistic
            title="חיזוי אמת"
            value={NumToStr(this.props.cone.prediction.prediction2)}
            formatter="number"
          ></Statistic>

          <Statistic
            title="חיזוי מחקרי"
            value={NumToStr(this.props.cone.prediction.prediction1)}
            formatter="number"
          ></Statistic>
        </div>
      </ConeLineStyled>
    );
  }
}

export default withRouter(ConeLine);
