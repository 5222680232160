import styled from "styled-components";

const ConcreteUnusualStyled = styled.div`
  .title {
    display: flex;
    justify-content: space-between;
    position: relative;
  }
  .page-title {
    position: relative;
  }

  .setting-page {
    position: absolute;
    left: 4px;
    top: 59px;
    cursor: pointer;

    svg {
      font-size: 26px;
      color: #2d2d2d;
    }
  }

  .match {
    width: 10px;
    height: 10px;
    background: #a5e38c;
    border-radius: 20px;
    margin: 7px 0px;
    margin-left: 10px;
  }

  .color-prec {
    color: #f38d25;
    font-weight: bold;
  }

  .description {
    font-weight: 500;
  }
  .flex-wrapper {
    display: flex !important;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    margin-top: 46px;

    .flexCol.item {
      width: 31%;
      position: relative;
      justify-content: center;
      align-items: start;
      border: 1px solid #2d2d2d3b;
      border-radius: 8px;
      height: 141px;
      cursor: zoom-in;
      background: #fff;
      padding: 20px;

      svg {
        font-size: 27px;
        color: #48444087;
        margin-top: 13px;
      }
    }
  }

  button.ant-btn.ant-btn-dashed.ant-btn-sm.ant-btn-dangerous {
    border: navajowhite;
    font-size: 15px;
    height: 25px;
    color: #403e3e;
  }
  .settingsIcon {
    font-size: 2rem;
  }

  .info-wrap .flex {
    margin: 0 auto;
    align-items: center;
  }

  .flex.page-title.gap20 {
    height: 110px;
  }
  .title.flex1 {
    justify-content: end;
  }

  .settingsContainer {
    padding: 5px;
    position: absolute;
    top: 50px;
    left: 0;
    border: 1px solid #000000;
    border-radius: 5px;
  }

  .input-settings {
    width: 189px;
  }

  .settingsContainer > div {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  td.ant-table-cell {
    font-size: 13px;
  }

  td.ant-table-cell {
    padding: 4px 10px !important;
  }

  .ant-table-thead > tr > th {
    color: #080808 !important;
    font-size: 12px !important;
  }
  .chart {
    width: 400px;
    height: 100px;
  }

  @media screen and (max-width: 600px) {
    .unusuel-slump {
      flex-direction: column;
    }

    .unusuel-slump .flex.flexCol {
      width: 100%;
    }

    .flex-wrapper {
      flex-direction: column;
    }

    .flex.flexCol.item {
      width: 100% !important;
    }

    .grid.grid-cols-2.gap-6 {
      flex-direction: column;
      display: flex !important;
    }

    .flex.flexCol.item {
      width: 100% !important;
    }
  }
`;

export { ConcreteUnusualStyled };
