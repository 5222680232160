/* eslint-disable @typescript-eslint/no-useless-constructor */

import {
  Button,
  Checkbox,
  Form,
  Input,
  message,
  Modal,
  Select,
  Switch,
  TimePicker,
} from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";
import axios from "axios";
import { useMutation } from "react-query";

const { Option } = Select;

const factories = [
  { name: "תפן", item: 2 },
  { name: "גן יבנה", item: 1 },
];

const sesnsors = [0, 693, 616, 453, 55];

const concreteTypes = [
  { name: "Regular", item: "regular" },
  { name: "Flow table", item: "flow table" },
];

const concreteStrength = [
  { name: "25", item: 25 },
  { name: "30", item: 30 },
  { name: "35", item: 35 },
  { name: "40", item: 40 },
  { name: "45", item: 45 },
  { name: "50", item: 50 },
  { name: "other", item: "other" },
];

const locations = [
  { name: "Factory", item: 1 },
  { name: "Job Site", item: 2 },
];

const cone_codes = [
  { name: "הכל בסדר", item: 0 },
  { name: "הוספת מים", item: 1 },
  { name: "לא ערבלו כמו שצריך באתר", item: 2 },
  { name: "סוג בטון חריג", item: 3 },
];

const recognitions = [
  { name: "משלוח שלא קרה", item: 0 },
  { name: "משלוח בפועל", item: 1 },
];

const wc = [0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9];

const slumpLevel = Array(9)
  .reverse()
  .map((_, i) => i - 1);

const concreteAmounts = Array(10)
  .fill(null)
  .map((_, i) => i + 1);
type StartMeasuremenProps = {
  onFinish: (values: any) => void;
  deviceId?: any;
  showModal: boolean;
  hide: () => void;
};
export const StartMeasurementModal = ({
  onFinish,
  deviceId,
  showModal,
  hide,
}: StartMeasuremenProps) => {
  const [timeMessurement, settimeMessurement] = useState(false);
  const [groupId, setgroupId] = useState(false);

  const onChange = (e: any) => {
    if (e.target.checked) {
      settimeMessurement(true);
    } else {
      settimeMessurement(false);
    }
  };

  function onChangeTime(time: any, timeString: any) {
    console.log(time, timeString);
    var timestamp = time.valueOf();
    setgroupId(timestamp);
  }

  const onFinishLocal = async (values: any) => {
    values.groupId = groupId;
    values.deviceId = deviceId;
    const rsp = await axios.get(
      `${process.env.REACT_APP_API_URL}/iot_data/cone_check/set`,
      {
        params: values,
      }
    );
    if (rsp.data !== "DATA_OK") {
      message.error("Your measurement not added!!!");
      settimeMessurement(false);
    } else {
      message.success("Your measurement added successfully");
      settimeMessurement(false);
    }
  };

  return (
    <Modal
      visible={showModal}
      onCancel={() => hide()}
      footer={null}
      destroyOnClose
    >
      <Form
        name="control-ref"
        className="padding20 form-settings"
        onFinish={timeMessurement ? onFinishLocal : onFinish}
      >
        <Form.Item label="Measurement Type" name="concreteType">
          <Checkbox onChange={onChange}>Add Measurement By Time</Checkbox>
        </Form.Item>

        {timeMessurement && (
          <Form.Item label="Test Time" name="checktime">
            <TimePicker
              format={"HH:mm"}
              onChange={onChangeTime}
              defaultOpenValue={moment("00:00", "HH:mm")}
            />
          </Form.Item>
        )}

        <Form.Item
          label="Cone Test Type"
          name="concreteType"
          rules={[{ required: true, message: "please add concrete type" }]}
        >
          <Select>
            {concreteTypes.map((cType) => (
              <Option key={cType.name}>{cType.name}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Concrete Strength"
          name="concreteStrength"
          rules={[{ required: true, message: "please add concrete strength" }]}
        >
          <Select>
            {concreteStrength.map((cType) => (
              <Option key={cType.name}>{cType.name}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Measurement Location"
          name="location"
          rules={[
            { required: true, message: "please add measurement location" },
          ]}
        >
          <Select>
            {locations.map((loc) => (
              <Option key={loc.name}>{loc.name}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Cubic Amount"
          name="amountInCubic"
          rules={[{ required: true, message: "please add cubic amount" }]}
        >
          <Select>
            {concreteAmounts.map((amount) => (
              <Option key={amount}>{amount}</Option>
            ))}
          </Select>
        </Form.Item>

        {timeMessurement && (
          <>
            <Form.Item
              label="cone 1"
              name="cone1"
              rules={[{ required: true, message: "require" }]}
            >
              <Input type="text" />
            </Form.Item>
            <Form.Item
              label="cone 2"
              name="cone2"
              rules={[{ required: true, message: "require" }]}
            >
              <Input type="text" />
            </Form.Item>
            <Form.Item
              label="cone 3"
              name="cone3"
              // rules={[{ required: true, message: "שדה חובה" }]}
            >
              <Input type="text" />
            </Form.Item>
          </>
        )}

        <Button className="button-center full" htmlType="submit">
          {timeMessurement ? "save" : "start"}
        </Button>
      </Form>
    </Modal>
  );
};

export const StartUploadingModal = ({
  onFinish,
  showModal,
  hide,
}: StartMeasuremenProps) => {
  return (
    <Modal
      visible={showModal}
      onCancel={() => hide()}
      footer={null}
      destroyOnClose
    >
      <Form
        name="control-ref"
        className="padding20 form-settings"
        onFinish={onFinish}
      >
        <Form.Item
          label=" מס' תעודת משלוח"
          name="order_id"
          rules={[{ message: "נא למלא מספר תעודת משלוח" }]}
        >
          <Input type="text"></Input>
        </Form.Item>

        <Form.Item
          label="מספר מפעל"
          name="factory_id"
          rules={[{ message: "נא למלא מספר תעודת משלוח" }]}
        >
          <Input type="text"></Input>
        </Form.Item>

        <Form.Item
          label="מספר ערבל"
          name="sensor_id"
          rules={[{ message: "נא למלא מספר תעודת משלוח" }]}
        >
          <Input type="text"></Input>
        </Form.Item>

        <Form.Item
          label="סוג הבטון"
          name="concrete_type"
          rules={[{ message: "נא למלא את סוג הבטון" }]}
        >
          <Select>
            <Option value={"all"}>הצג הכל</Option>
            {concreteTypes.map((cType) => (
              <Option key={cType.name}>{cType.name}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="חוזק הבטון"
          name="concrete_strength"
          rules={[{ message: "נא למלא את חוזק הבטון" }]}
        >
          <Select>
            <Option value={"all"}>הצג הכל</Option>
            {concreteStrength.map((loc) => (
              <Option key={loc.name}>{loc.name}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="רמת שקיעת הבטון"
          name="concrete_slump"
          rules={[{ message: "נא למלא את רמת השקיעה" }]}
        >
          <Select>
            <Option value={"all"}>הצג הכל</Option>
            {slumpLevel.reverse().map((amount) => (
              <Option key={amount}>{amount}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="תכולת מים לקוב"
          name="water_act"
          rules={[{ message: "נא למלא תכולת מים לקוב" }]}
        >
          <Input type="text"></Input>
        </Form.Item>

        <Form.Item
          label="תכולת צמנט"
          name="cement_act"
          rules={[{ message: "נא למלא תכולת מים לקוב" }]}
        >
          <Input type="text"></Input>
        </Form.Item>

        <Form.Item
          label="חריגים"
          name="cone_code"
          rules={[{ message: "נא למלא יחס מים צמנט" }]}
        >
          <Select>
            <Option value={"all"}>הצג הכל</Option>
            {cone_codes.map((code) => (
              <Option key={code.item}>{code.name}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="זיהוי העמסה"
          name="recognition"
          rules={[{ message: "נא למלא יחס מים צמנט" }]}
        >
          <Select>
            <Option value={"all"}>הצג הכל</Option>
            {recognitions.map((recognition) => (
              <Option key={recognition.item}>{recognition.name}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="בדיקת חוזק(7 ימים)"
          name="strength_7"
          rules={[{ message: "נא למלא יחס מים צמנט" }]}
        >
          <Input type="text"></Input>
        </Form.Item>

        <Form.Item
          label="בדיקת חוזק(28 ימים)"
          name="strength_28"
          rules={[{ message: "נא למלא יחס מים צמנט" }]}
        >
          <Input type="text"></Input>
        </Form.Item>

        {/* slumpLevel */}

        <Button className="button-center full" htmlType="submit">
          עדכן נתונים
        </Button>
      </Form>
    </Modal>
  );
};

type uploadConesProps = {
  showModal: boolean;
  deviceId: any;
  item: any;
  hide: () => void;
};
type ReadEntry = {
  amountInCubic: string;
  concreteType: string;
  groupId: number;
  id: string;
  location: string;
};
export const MeasurementsListModal = ({
  showModal,
  deviceId,
  item,
  hide,
}: uploadConesProps) => {
  const [measurments, setmeasurments] = useState([]);
  const [isSaved, setIsSaved] = useState(null);

  const onFinish = async (values: any) => {
    const data = { ...item, ...values };

    const item_saved = { ...item, ...values };
    data.deviceId = deviceId;
    console.log({ data });

    const rsp = await axios.get(
      `${process.env.REACT_APP_API_URL}/iot_data/cone_check/set`,
      {
        params: data,
      }
    );
    if (rsp.data !== "DATA_OK") {
      setIsSaved(false);
    } else {
      setIsSaved(true);
    }
  };

  return (
    <Modal open={showModal} onCancel={hide} footer={null} destroyOnClose>
      {isSaved !== null ? (
        <div>
          {isSaved ? (
            <div
              className=""
              style={{
                textAlign: "center",
                fontSize: "32px",
                color: "#47b947",
              }}
            >
              Your data is saved.
              <br />
              Thank You
            </div>
          ) : (
            <div
              className=""
              style={{
                textAlign: "center",
                fontSize: "32px",
                color: "#a1380f",
              }}
            >
              Your data not saved.
              <br />
              Please try again.
              <br />
              If not working, contact Conext Global team
            </div>
          )}
        </div>
      ) : (
        <div>
          <div className="messur flex flexCol gap20" key={item.groupId}>
            <div className="loc flex">
              <div>Start Time: </div>
              <div>{moment(item.groupId).format("h:mm:ss")}</div>
            </div>

            <div className="loc flex">
              <div>End Time: </div>
              <div>{moment(item.endMeasurementTime).format("h:mm:ss")}</div>
            </div>

            <div className="loc flex">
              <div>location: </div>
              <div>{item.location === "1" ? "factory" : "delivery"}</div>
            </div>
          </div>

          <Form
            onFinish={onFinish}
            className="padding20 form-settings"
            initialValues={{ cone1: 0, cone2: 0, cone3: 0 }}
          >
            <Form.Item
              label="cone 1"
              name="cone1"
              rules={[{ required: true, message: "require" }]}
            >
              <Input type="text" />
            </Form.Item>
            <Form.Item
              label="cone 2"
              name="cone2"
              rules={[{ required: true, message: "require" }]}
            >
              <Input type="text" />
            </Form.Item>
            <Form.Item
              label="cone 3"
              name="cone3"
              // rules={[{ required: true, message: "שדה חובה" }]}
            >
              <Input type="text" />
            </Form.Item>
            <Button className="button-center full" htmlType="submit">
              SAVE
            </Button>
          </Form>
        </div>
      )}
    </Modal>
  );
};
