import { Button, message, Select } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { MessurmentStyled } from "./styled";
import {
  BatteryStatus,
  NetworkStatus,
  SensorStatus,
} from "./components/StatusIndicators";
import {
  StartMeasurementModal,
  MeasurementsListModal,
  StartUploadingModal,
} from "./components/modals";
import { useLatestReading, useStopWatch } from "./hooks";
import moment from "moment";
import Axios from "axios";

const { Option } = Select;

const devicesList = ["275", "155", "83", "680"];

function MessurmentAll() {
  const [timer, setTimer] = useState(0);
  const [messuremntValues, setMessuremntValues] = useState<any>();
  const [sensors, setSensors] = useState<any>();

  const [isMeasureInProgress, setIsMeasureInProgress] = useState(false);
  const [showStartMeasure, setShowStartMeasure] = useState(false);
  const [showUploadingModal, setShowUploadingModal] = useState(false);
  const [showUploadCones, setShowUploadCones] = useState(false);
  const [deviceId, setdeviceId] = useState<any>(null);
  const [data, setData] = useState<any>(null);
  // const { rotation, battery, createdAt, groupId } = useLatestReading(deviceId);

  useEffect(() => {
    const open = localStorage.getItem("open");
    setIsMeasureInProgress(open === "true" || false);
    const device = localStorage.getItem("messurment-device");

    setdeviceId(device);
    getSensors();
    getLatestReading();
    setInterval(() => {
      getLatestReading();
    }, 5000);
  }, []);

  const getSensors = async () => {
    let sensors = await axios.get(
      `${process.env.REACT_APP_API_URL}/sensors?group_id=${1}`
    );
    setSensors(sensors.data.sensors_data);
  };

  const getLatestReading = async () => {
    const device = localStorage.getItem("messurment-device");
    const rsp = await Axios.get(
      `${process.env.REACT_APP_API_URL}/iot/latestReading/${device}`
    );
    setData(rsp.data);
  };

  useEffect(() => {
    let timerId: any;

    if (timer > 0) {
      timerId = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
      }, 1000);
    }

    return () => {
      clearInterval(timerId);
    };
  }, [timer]);

  const startMeasurement = (values: any) => {
    setIsMeasureInProgress(true);
    const groupId = new Date().getTime();
    values.groupId = groupId;
    setMessuremntValues(values);

    startStopTimer();
    setShowStartMeasure(false);
  };

  const finishMeasurment = () => {
    const endMeasurementTime = new Date().getTime();
    messuremntValues.endMeasurementTime = endMeasurementTime;
    console.log({ messuremntValues });
    setIsMeasureInProgress(false);

    startStopTimer();

    setShowUploadCones(true);
  };

  const addLoading = async (values: any) => {
    const rsp = await axios.post(
      `${process.env.REACT_APP_API_URL}/concrete/deleviry/${values.id}`,
      values
    );

    message.success("record successfully added");
  };

  const updateConeData = () => {
    setShowUploadCones(true);
  };

  const updateDeviceId = (value: string) => {
    localStorage.setItem("messurment-device", value);
    setdeviceId(value);
  };

  const startStopTimer = () => {
    setTimer((prevTimer) => (prevTimer === 0 ? 1 : 0));
  };

  const resetTimer = () => {
    setTimer(0);
  };

  const formattedTime = (time: any) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };

  return (
    <MessurmentStyled>
      <div className="flex top measurement">
        <NetworkStatus createdAt={data?.createdAt} />
        <BatteryStatus battery={data?.battery} />
        <SensorStatus groupId={data?.createdAt} />
      </div>

      <div className="center" style={{ display: "flex" }}>
        <div className="flex logo-container">
          <img src={require("../../../assets/logo.svg")} alt="react logo" />
        </div>
        <div>
          <div className="rpm-title">
            RPM
            <div className="rpm">{data?.rotation}</div>
          </div>

          <div className="flex white justify-centered">
            <div>Last Update:</div>
            <div> {` ${moment(data?.createdAt).format("HH:mm")} `}</div>
          </div>

          <div>
            <div className="title-rpm">Choose Mixer</div>
            <Select
              className="measurement"
              value={deviceId}
              size={"large"}
              onChange={(deviceVal) => updateDeviceId(deviceVal)}
            >
              {sensors?.map((device: any) => (
                <Option key={device.sensor_id}>{device.sensor_id}</Option>
              ))}
            </Select>
          </div>

          <div className="messure-spped">{formattedTime(timer)}</div>
        </div>
      </div>

      <div className="buttons">
        {/* <Button onClick={() => setShowUploadingModal(true)}>רישום העמסה</Button> */}

        {isMeasureInProgress ? (
          <Button onClick={finishMeasurment}>STOP</Button>
        ) : (
          <Button
            onClick={() => {
              setShowStartMeasure(true);
            }}
          >
            START MEASUREMENT
          </Button>
        )}

        {/* <Button onClick={updateConeData}>UPDATE</Button> */}
      </div>
      <StartMeasurementModal
        deviceId={deviceId}
        showModal={showStartMeasure}
        hide={() => setShowStartMeasure(false)}
        onFinish={(values) => startMeasurement(values)}
      />

      <StartUploadingModal
        showModal={showUploadingModal}
        hide={() => setShowUploadingModal(false)}
        onFinish={(values) => addLoading(values)}
      />

      {showUploadCones && (
        <MeasurementsListModal
          showModal={true}
          deviceId={deviceId}
          item={messuremntValues}
          hide={() => setShowUploadCones(false)}
        />
      )}
    </MessurmentStyled>
  );
}

export default MessurmentAll;
