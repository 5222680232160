import { Checkbox, Form, Select } from "antd";
import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { TrackNumberStyled } from "./styled";
import { observer } from "mobx-react";
import { observable } from "mobx";
import { t } from "i18next";

const { Option } = Select;

interface Props extends RouteComponentProps<any> {
  sensors: any;
  showLabel?: boolean;
  defaultSensor?: string;
  showSelectAllAsTitles?: boolean;

  changeTackNumber: (track_number: any) => void;
}
@observer
class TrackNumber extends React.PureComponent<Props, any> {
  // componentDidUpdate = (prevProps: any, prevState: any) => {
  //   console.log(prevProps?.sensors[0]);
  // }

  @observable active_track: any = "0";

  getSensorNumber = () => {
    const track_number = localStorage.getItem("track_number");
    return track_number || null;
  };

  render() {
    return (
      <TrackNumberStyled>
        {/* {this.props.showLabel ? (
          <div className="f20">{t("sensorNumber")}</div>
        ) : null} */}

        <Form.Item name="deliveryId" label={t("sensor")}>
          <Select
            defaultValue={
              this.getSensorNumber && !this.props.showSelectAllAsTitles
                ? this.getSensorNumber
                : t("chooseSensor")
            }
            placeholder={"מס' ערבל"}
            onChange={(e: any) => {
              this.active_track = e;
              this.props.changeTackNumber(e);
            }}
          >
            {this.props.sensors?.map((sensor: any, i: number) => {
              return (
                <Option key={i} value={sensor.sensor_id}>
                  {sensor.sensor_id}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        {this.active_track === 999 && <Checkbox>מזרים מידע</Checkbox>}
      </TrackNumberStyled>
    );
  }
}
export default withRouter(TrackNumber);
