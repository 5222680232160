import { Tabs } from "antd";
import axios from "axios";
import { observable, toJS } from "mobx";
import { observer } from "mobx-react";
import moment from "moment";
import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import ChartEvents, {
  ChartEventsMulti,
} from "../../../../../components/ChartBars/ChartEvents";
import getUrlParams from "../../../../../helpers/getUrlParams";
import { PageDisplayStyled } from "./styled";
import { t } from "i18next";
import parseObjectToUrlParams from "../../../../../utils/parseObjectToUrlParams";
import { numberToFactorySingle } from "../../../../../helpers/numberToFactory";
import RoadMapComponent from "./roadMapComponent";

interface Props extends RouteComponentProps<any> {
  record: any;
}

function mergeGranularSlumpWithEvents(
  granularSlump: {
    createdAt: string;
    event?: string;
    slump: number;
    slump_avg: number;
  }[],
  events: { createdAt: string; name: string }[]
) {
  const eventsCopy = [...events];
  for (let i in granularSlump) {
    const closestEventIndex = eventsCopy.findIndex((event) => {
      if (!event) return false;
      return moment(event.createdAt).isSameOrBefore(
        moment(granularSlump[i].createdAt)
      );
    });
    const closestEvent = eventsCopy[closestEventIndex];
    if (closestEvent) {
      console.log("closestEvent", closestEvent.name);
      delete eventsCopy[closestEventIndex];
      granularSlump[i].event = ` ${closestEvent.name} (${moment(
        closestEvent.createdAt
      ).format("h:mm")})`;
    }
  }
  const lastEvent = eventsCopy[eventsCopy.length - 1];
  if (lastEvent) {
    granularSlump[granularSlump.length - 1].event = lastEvent.name;
  }
  return granularSlump;
}

function calculateMovingAverage(records: any, windowSize: any) {
  let result = [];
  for (let i = 0; i < records.length; i += windowSize) {
    let sum = 0;
    for (let j = 0; j < windowSize && i + j < records.length; j++) {
      sum += records[i + j].slump;
    }
    let average = sum / windowSize;
    let newRecord = {
      slump_avg: average,
      slump: average,
      createdAt:
        records[i + windowSize - 1]?.createdAt ||
        records[records.length - 1].createdAt,
    };
    result.push(newRecord);
  }
  return result;
}

@observer
class PageDisplay extends React.PureComponent<Props, any> {
  // history = useHistory();

  @observable data: any = {};
  @observable settingData: any = {};

  @observable location_events: any = [];

  @observable current: any = {};
  @observable chart1: any = [];

  @observable chart2: any[] = [];
  @observable chart4: any = [];
  @observable chart5: any = [];

  @observable slump_start: number = 0;
  @observable slump_end: number = 0;

  @observable wc_start: number = 0;
  @observable wc_end: number = 0;

  @observable max_slump: number = 0;
  @observable max_wc: number = 0;
  @observable max_rpm: number = 0;

  @observable min_slump: number = 0;
  @observable min_wc: number = 0;
  @observable min_rpm: number = 0;

  @observable max_temp: number = 0;
  @observable min_temp: number = 0;

  codeKeys: Record<number, string> = {
    1: t("loadingStart"),
    2: t("loadingFinish"),
    3: t("uniformConcrete"),
    4: t("leavingFactory"),
    5: t("arriveAtSite"),
    6: t("startMixing"),
    7: t("addingWater"),
    8: t("addingSupplement"),
    9: t("finishMixing"),
    10: t("unloadingstart"),
    11: t("unloadingstop"),
    12: t("unloadingFinish"),
    13: t("GPSdata"),
    14: t("SuspectedTheft"),
    15: t("SuspectedAddition"),
    16: t("arriveFactory"),
    17: t("Problem"),
    18: t("deliveryTooLong"),
    19: t("oldConcrete"),
    23: t("leavingSite"),
  };

  componentDidMount = async () => {
    try {
      this.getDeliveryData();
      this.getEventsData();

      this.settingData = JSON.parse(localStorage.getItem("deliveryData")) || {};
    } catch (error) {
      console.error(error);
    }
  };

  getDeliveryData = async (filter?: any) => {
    const rsp = await axios.get(
      `${process.env.REACT_APP_API_URL}/concrete/deleviry/${this.props.record.id}`
    );

    this.data = rsp.data;

    this.slump_start = rsp.data.slump_at_loading;
    this.slump_end = rsp.data.slump_at_unloading1;

    this.wc_start = rsp.data.wc;
    this.wc_end = rsp.data.wc;
  };

  translateName = (value: number) => {
    if (value in this.codeKeys) {
      return this.codeKeys[value];
    }
    return "Unknown";
  };

  getEventsData = async (filter?: any) => {
    let rsp: any = await axios.get(
      `${process.env.REACT_APP_API_URL}/concrete/events/${this.props.record.id}`
    );

    const locations = rsp.data.map((event: any) => ({
      lat: Number(event.lat),
      lng: Number(event.lang)
    }));

    this.location_events = locations



    const events = rsp.data.map((item: any) => {
      this.max_slump =
        this.max_slump > item.slump ? this.max_slump : item.slump;
      this.min_slump =
        this.min_slump < item.slump ? this.min_slump : item.slump;

      const data = {
        time: moment(item.events_time_start).format("h:mm"),
        name: this.translateName(item.events_code),
        uv: 2000,
        slump: item.slump,
      };
      return data;
    });

    const slump = rsp.data.map((item: any) => {
      this.max_slump =
        this.max_slump > item.slump ? this.max_slump : item.slump;
      this.min_slump =
        this.min_slump < item.slump ? this.min_slump : item.slump;

      const data = {
        time: moment(item.events_time_start).format("h:mm"),
        name: this.translateName(item.events_code),
        slump: item.slump,
        createdAt: item.events_time_start,
      };
      return data;
    });

    // const wc = rsp.data.map((item: any) => {
    //   this.max_wc = this.max_wc > item.wc ? this.max_wc : item.wc;
    //   this.min_wc = this.min_wc < item.wc ? this.min_wc : item.wc;
    //   const data = {
    //     time: moment(item.events_time_start).format("h:mm"),
    //     name: this.translateName(item.events_code),
    //     water: item.wc,
    //   };
    //   return data;
    // });

    const temp = rsp.data.map((item: any) => {
      this.max_temp = this.max_temp > item.temp ? this.max_temp : item.temp;
      this.min_temp = this.min_temp < item.temp ? this.min_temp : item.temp;

      const data = {
        time: moment(item.events_time_start).format("h:mm"),
        name: this.translateName(item.events_code),
        rpm: item.temp,
      };
      return data;
    });

    const rpm = rsp.data.map((item: any) => {
      this.max_rpm = this.max_rpm > item.rpm ? this.max_rpm : item.rpm;
      this.min_rpm = this.min_rpm < item.rpm ? this.min_rpm : item.rpm;
      const data = {
        time: moment(item.events_time_start).format("h:mm"),
        name: this.translateName(item.events_code),
        rpm: item.rpm,
      };
      return data;
    });

    console.log({ slump });

    this.chart1 = events;
    this.chart2 = slump;

    console.log({ slump: this.chart2 });
    // this.chart3 = wc;
    this.chart4 = temp;
    this.chart5 = rpm;
  };

  goBack = () => {
    window.history.back();
  };

  getChartData = async () => {
    let urlParams: Record<string, any> = {};

    urlParams.date = {
      start: this.data.date_upload,
      end: this.data.date_download,
    };
    console.log({ urlParams });

    let rsp = await axios.get(
      `${process.env.REACT_APP_API_URL}/calculate/data/${this.data.sensor_id}/?` +
        parseObjectToUrlParams(urlParams)
    );

    const records = rsp.data.data;
    console.log({ records });

    const windowSize = 3;
    const movingAverages: any = calculateMovingAverage(records, windowSize);

    // console.log({ movingAverages });
    // const arr = mergeGranularSlumpWithEvents(movingAverages, this.chart2);
    // console.log("arr", arr);

    // this.chart2 = arr.map((item) => {
    //   const time = moment(item.createdAt).format("h:mm");
    //   return {
    //     time: time,
    //     slump: item.slump_avg,
    //     name: item.event,
    //   };
    // });
  };

  render() {
    const page_id = getUrlParams("id", window.location.href);
    const slmumpAvg = this.slump_end - this.slump_start;
    const wcAvg = ((this.wc_end - this.wc_start) / this.wc_start) * 100;
    return (
      <PageDisplayStyled>
        <div className="">
          {/* <div className="title flex gap10">
            <div onClick={this.goBack}>
              {" "}
              <RightOutlined />
            </div>
            <div>{t("deliveryStatus")}</div>
          </div> */}

          <div className="">
            <div className="flex flexCol gap20">
              <div className="flex gap10 full">
                <div className="flex item">
                  <div className="title">{t("factory")}</div>
                  <div className="data">
                    {numberToFactorySingle(String(this.data.factory_id))}
                  </div>
                </div>

                <div className="flex item">
                  <div className="title">{t("orderNom")}</div>
                  <div className="data">
                    {this.data.order_id !== "0"
                      ? this.data.order_id
                      : t("willUpdate")}
                  </div>
                </div>

                <div className="flex item">
                  <div className="title"> {t("sensor")}</div>
                  <div className="data">{this.data.sensor_id}</div>
                </div>

                <div className="flex item">
                  <div className="title">{t("dateLoading")}</div>
                  <div className="data">
                    {!this.data.date_upload
                      ? t("notUpdates")
                      : moment(this.data.date_upload).format(
                          "DD/MM/YY , HH:mm"
                        )}
                  </div>
                </div>
                <div className="flex item">
                  <div className="title">{t("dateUnload")}</div>
                  <div className="data">
                    {!this.data.date_download
                      ? t("notUpdates")
                      : moment(this.data.date_download).format(
                          "DD/MM/YY , HH:mm"
                        )}
                  </div>
                </div>

                <div
                  className={
                    "flex item " +
                    (this.settingData &&
                    this.settingData.deliveries_count <
                      this.data.delivery_duration
                      ? "error"
                      : "")
                  }
                >
                  <div className="title">{t("lengthNum")}</div>
                  <div className="data">
                    {this.data.delivery_duration} {t("minuts")}
                  </div>
                </div>
              </div>

              <div className="flex gap10 ">
                <div className="flex item">
                  <div className="title">{t("recipe")}</div>
                  <div className="data">
                    {" "}
                    {this.data.product_name
                      ? `${this.data.product_name} (${this.data.mixture_code})`
                      : t("willUpdate")}
                  </div>
                </div>

                <div className="flex item">
                  <div className="title">{t("cubikInMixer")}</div>
                  <div className="data">{this.data.cubic_est}</div>
                </div>

                <div className="flex item">
                  <div className="title">{t("planning")}</div>

                  <div className="data">
                    {this.data.slump_orderd
                      ? this.data.slump_orderd
                      : t("willUpdate")}
                  </div>
                </div>

                <div className="flex item">
                  <div className="title">{t("production")}</div>
                  <div className="data">
                    {this.data.slump_at_loading}
                    {t("mm")}
                  </div>
                </div>

                <div className="flex item">
                  <div className="title">{t("supply")}</div>
                  <div className="data">
                    {this.data.slump_at_unloading1}
                    {t("mm")}
                  </div>
                </div>

                <div
                  className={
                    "flex item " +
                    (this.settingData.slump_count >
                    this.data.slump_at_unloading1
                      ? "error"
                      : "fix")
                  }
                >
                  <div className="title">{t("level")}</div>
                  <div className="data">
                    {this.data.slump_at_unloading1 - this.data.slump_at_loading}
                    {t("mm")}
                  </div>
                </div>
              </div>

              <div className="flex gap10 ">
                <div
                  className={
                    "flex item " +
                    (this.data.suspected_water_addition ? "error" : "")
                  }
                >
                  <div className="title">{t("DriverAddition")}</div>
                  <div className="data">
                    {this.data.suspected_water_addition ? t("yes") : t("no")}
                  </div>
                </div>

                {/* <div className="flex item">
                  <div className="title">{t("FactoryAddition")}</div>
                  <div className="data">{this.data.total_water_factory}</div>
                </div> */}

                <div
                  className={
                    "flex item " +
                    (this.settingData.temp_count < this.data.high_temp
                      ? "error"
                      : "")
                  }
                >
                  <div className="title">{t("temperature")}</div>
                  <div className="flex ">
                    <div className="">{this.data.high_temp}℃</div>
                  </div>
                </div>

                <div className="flex item">
                  <div className="title">{t("unloadingTime")}</div>
                  <div className="data">
                    {this.data.unloading_duration}
                    {t("minuts")}
                  </div>
                </div>

                {this.data.stay_in_site_after_loading_duration &&
                <div className="flex item">
                  <div className="title">{t("timeInSite")}</div>
                  <div className="data">
                    {this.data.stay_in_site_after_loading_duration}
                    {t("minuts")}
                  </div>
                </div>
                }
              </div>
            </div>

            <div className="flex chart-con">
              <Tabs
                tabPosition={"left"}
                defaultActiveKey="1"
                onTabClick={(key) => this.getChartData()}
              >
                <Tabs.TabPane tab={t("summary")} key="1">
                  <ChartEventsMulti
                    data={this.chart1}
                    dataKeyX={"time"}
                    lines={[
                      { key: "uv", color: "#82ca9d" },
                      // { key: "slump", color: "#e0730d" },
                    ]}
                    tick={false}
                    domain={[0, 3000]}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab={t("slump")} key="2">
                  <ChartEvents
                    data={this.chart2}
                    dataKeyX={"time"}
                    dataLine={"slump"}
                    color={"#e0730d"}
                    tick={true}
                    domain={[0, 450]}
                  />
                </Tabs.TabPane>
                {/* <Tabs.TabPane tab={t("wc")} key="3">
                  <ChartEvents
                    data={this.chart3}
                    dataKeyX={"time"}
                    dataLine={"water"}
                    color={"#3476f1"}
                    tick={true}
                    domain={[0, 8]}
                  />
                </Tabs.TabPane> */}

                <Tabs.TabPane tab={t("temperature")} key="4">
                  <ChartEvents
                    data={this.chart4}
                    dataKeyX={"time"}
                    dataLine={"rpm"}
                    color={"#570de0"}
                    tick={true}
                    domain={[-10, 40]}
                    circle
                  />
                </Tabs.TabPane>

                <Tabs.TabPane tab={t("rpm")} key="5">
                  <ChartEvents
                    data={this.chart5}
                    dataKeyX={"time"}
                    dataLine={"rpm"}
                    color={"#570de0"}
                    tick={true}
                    domain={[-10, 20]}
                    circle
                  />
                </Tabs.TabPane>

                <Tabs.TabPane tab={t("road")} key="6">
                  <RoadMapComponent locations={this.location_events}/>
                </Tabs.TabPane>
              </Tabs>
            </div>
          </div>
        </div>
      </PageDisplayStyled>
    );
  }
}
export default withRouter(PageDisplay);
