/** @format */

import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { SignInStyled } from "./styled";
import { Input, Button, Form, Carousel } from "antd";
import { observer } from "mobx-react";
import { observable } from "mobx";
import { EllipsisOutlined, UserOutlined } from "@ant-design/icons";
import { t } from "i18next";
const axios = require("axios");

interface Props extends RouteComponentProps<any> {}

interface Props {
  viewNumber: any;
}

@observer
class SignIn extends React.PureComponent<Props, any> {
  state = { viewNumber: this.props.viewNumber };
  @observable error: null | string = null;

  componentDidMount = async () => {
    console.log("viewNumber: ", this.state.viewNumber);
  };

  onFinish = async (values: any) => {
    try {
      console.log(process.env.REACT_APP_API_URL);
      let config = {
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };

      let userLogin = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/login`,
        values,
        config
      );

      console.log(userLogin);

      if (userLogin) {
        this.error = "";
        localStorage.setItem("b_user", JSON.stringify(userLogin.data));
        // this.props.history.push(`/dashboard`);
        window.location.href = `/addons`;
      }
    } catch (error) {
      this.error = "הפרטים אינם נכונים";
      console.error(error);
    }
  };

  onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  render() {
    return (
      <SignInStyled>
        <div className="flex">
          <img className="login-image" src="/images/login_back.png" />
          <div className="form-login">
            <svg
              className="center"
              width="161"
              height="31"
              viewBox="0 0 161 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M53.8362 22.2598C53.8516 23.309 53.652 24.3504 53.2498 25.3197C52.8476 26.289 52.2512 27.1658 51.4973 27.8961C50.7654 28.6467 49.8877 29.2398 48.9181 29.6391C47.9485 30.0383 46.9075 30.2352 45.8591 30.2177L44.4775 30.2177C43.4286 30.2332 42.3876 30.0341 41.4186 29.6326C40.4495 29.2311 39.5729 28.6357 38.8425 27.8829C38.0875 27.1561 37.49 26.2819 37.0871 25.3146C36.6842 24.3473 36.4845 23.3075 36.5004 22.2598L36.5004 12.605C36.4845 11.5573 36.6842 10.5176 37.0871 9.55024C37.49 8.58292 38.0875 7.70871 38.8425 6.98189C39.5729 6.22914 40.4495 5.6337 41.4186 5.23219C42.3876 4.83067 43.4286 4.63157 44.4775 4.64713L45.8591 4.64713C46.9087 4.63174 47.9504 4.83123 48.9201 5.23332C49.8897 5.63541 50.7668 6.23159 51.4973 6.98519C52.2512 7.71548 52.8476 8.59225 53.2498 9.56156C53.652 10.5309 53.8516 11.5722 53.8362 12.6215L53.8362 15.4396L48.5203 15.4396C48.5203 13.5411 48.3778 12.2258 48.0927 11.4936C47.6321 10.1234 46.6222 9.43832 45.063 9.43832C42.8985 9.43832 41.8174 10.917 41.8196 13.8744L41.8196 20.8458C41.8196 23.8031 42.972 25.2807 45.2768 25.2785C46.8361 25.2785 47.8284 24.6208 48.2539 23.3055C48.4315 22.6675 48.5203 21.3522 48.5203 19.3594L53.8362 19.3594L53.8362 22.2598Z"
                fill="white"
              />
              <path
                d="M73.3005 22.2761C73.3164 23.3238 73.1167 24.3636 72.7138 25.3309C72.3109 26.2982 71.7134 27.1724 70.9584 27.8992C70.228 28.652 69.3514 29.2474 68.3823 29.6489C67.4133 30.0504 66.3723 30.2495 65.3234 30.234L63.9418 30.234C62.8924 30.25 61.8508 30.0511 60.8811 29.6496C59.9115 29.248 59.0343 28.6524 58.3036 27.8992C57.5496 27.1718 56.953 26.2974 56.5507 25.3301C56.1484 24.3629 55.949 23.3234 55.9647 22.2761L55.9647 12.6213C55.949 11.574 56.1484 10.5345 56.5507 9.56727C56.953 8.60005 57.5496 7.72566 58.3036 6.9982C59.0343 6.24504 59.9115 5.64935 60.8811 5.24782C61.8508 4.8463 62.8924 4.64742 63.9418 4.66343L65.3234 4.66343C66.3723 4.64787 67.4133 4.84697 68.3823 5.24849C69.3514 5.65 70.228 6.24544 70.9584 6.99819C71.7134 7.72501 72.3109 8.59922 72.7138 9.56654C73.1167 10.5339 73.3164 11.5736 73.3005 12.6213L73.3005 22.2761ZM67.9814 20.8555L67.9814 13.8841C67.9814 10.9267 66.8289 9.44804 64.5241 9.44804C62.3639 9.44804 61.2828 10.9267 61.2806 13.8841L61.2806 20.8555C61.2806 23.8128 62.433 25.2904 64.7379 25.2882C66.9002 25.2882 67.9814 23.8106 67.9814 20.8555Z"
                fill="white"
              />
              <path
                d="M93.4025 12.1807L93.4025 29.7539L88.1887 29.7539L88.1886 13.4468C88.1886 10.4938 87.0088 9.0173 84.6491 9.0173C82.4693 9.0173 81.398 10.0192 81.4353 12.0229L81.5932 29.7473L76.001 29.7473L76.001 4.23927L81.4254 4.29189L81.4254 5.60724C82.2346 4.68649 83.7522 4.22611 85.9781 4.22611C86.9859 4.19431 87.9883 4.38668 88.9127 4.78931C89.8372 5.19194 90.6606 5.79478 91.3236 6.5543C92.7052 8.09838 93.4484 10.1096 93.4025 12.1807Z"
                fill="white"
              />
              <path
                d="M113.607 22.2597C113.623 23.3093 113.423 24.351 113.02 25.3204C112.617 26.2898 112.02 27.1664 111.265 27.896C110.534 28.6469 109.657 29.2402 108.688 29.6395C107.719 30.0387 106.678 30.2355 105.63 30.2176L104.258 30.2176C103.209 30.2336 102.167 30.0348 101.198 29.6332C100.228 29.2317 99.3507 28.636 98.62 27.8829C97.866 27.1554 97.2695 26.281 96.8672 25.3138C96.4649 24.3466 96.2654 23.3071 96.2811 22.2597L96.2811 12.605C96.2654 11.5576 96.4649 10.5182 96.8672 9.55094C97.2695 8.58372 97.866 7.70931 98.62 6.98185C99.3507 6.22869 100.228 5.63302 101.198 5.23149C102.167 4.82996 103.209 4.63108 104.258 4.64709L105.64 4.64709C106.689 4.63121 107.731 4.83081 108.7 5.23354C109.669 5.63626 110.546 6.23354 111.275 6.98842C112.03 7.71722 112.627 8.59319 113.03 9.56211C113.433 10.531 113.633 11.5723 113.617 12.6214L113.617 19.9578L101.587 19.9578L101.587 20.8523C101.587 22.5359 101.957 23.7033 102.696 24.3544C103.435 25.0055 104.649 25.3344 106.337 25.341C107.285 25.341 107.935 24.3128 108.288 22.2564L113.607 22.2597ZM108.288 15.5613L108.288 13.8743C108.288 10.9235 107.135 9.44815 104.831 9.44815C102.668 9.44815 101.587 10.9367 101.587 13.9138L101.587 15.558L108.288 15.5613Z"
                fill="white"
              />
              <path
                d="M148.337 30.5C142.239 30.5 139.189 28.6969 139.189 25.0906L139.189 9.96399L136.584 9.96399L136.584 5.17938L139.189 5.17938L139.189 0.5L144.508 0.499999L144.508 5.17938L148.337 5.17938L148.337 9.96399L144.502 9.96399L144.502 23.8443C144.502 24.8703 144.933 25.4885 145.795 25.699C146.121 25.8042 146.967 25.8393 148.331 25.8042L148.337 30.5Z"
                fill="white"
              />
              <path
                d="M134.078 4.29201L128.548 4.29201L125.143 13.8612L121.581 17.5311L116.16 29.7573L121.689 29.7573L125.091 20.7175L128.337 29.7573L133.867 29.7573L128.601 17.5837L134.078 4.29201Z"
                fill="white"
              />
              <path
                d="M124.318 11.5847L121.9 4.29109L116.37 4.29109L120.745 15.2349L124.318 11.5847Z"
                fill="#FF6700"
              />
              <path
                d="M17.9124 14.3614L11.8569 20.6396L14.3204 9.15818L16.9025 9.15818L17.9124 14.3614Z"
                fill="#FF6700"
              />
              <path
                d="M27.6049 25.8245L25.0289 15.1339L27.0345 15.1339L26.0798 11.0322L24.0681 11.0322L23.79 9.92048C23.4936 8.71674 23.1767 7.46805 22.9846 6.24183C22.406 2.72667 19.6747 0.629844 15.6779 0.627801C13.8175 0.627801 12.0961 1.07742 10.8327 1.89081C9.04177 3.04345 8.52045 4.80715 8.26899 6.21526C8.18108 6.7037 8.06456 7.20849 7.93576 7.69489C7.87443 7.93605 7.81514 8.17925 7.75994 8.42041L7.14662 11.0363L5.14515 11.0363L4.19043 15.138L6.19598 15.138L3.62004 25.8286L0.625 25.8286L0.625 30.3574L7.37151 30.3574L12.7748 7.1104L18.4358 7.1104L23.8473 30.3574L30.5938 30.3574L30.5938 25.8245L27.6049 25.8245Z"
                fill="white"
              />
              <path
                d="M18.5747 16.7642L10.8387 24.5814L9.67139 30.3916L21.5493 30.3916L18.5747 16.7642Z"
                fill="#FF6700"
              />
              <path
                d="M153.25 5.94149V5.50684H155.298V5.94149H154.534V8.00034H154.013V5.94149H153.25Z"
                fill="white"
              />
              <path
                d="M155.635 5.50684H156.285L156.972 7.18216H157.001L157.688 5.50684H158.338V8.00034H157.827V6.37737H157.806L157.161 7.98817H156.812L156.167 6.37128H156.147V8.00034H155.635V5.50684Z"
                fill="white"
              />
              <circle
                cx="155.875"
                cy="6.5"
                r="3.5625"
                stroke="white"
                stroke-width="0.375"
              />
            </svg>

            <div className="wraper-form">
              <div className="login-text">{t("loginTitle")}</div>
              <div className="login-text-desc">{t("loginDescription")}</div>
              <Form
                initialValues={{ remember: true }}
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
              >
                <Form.Item
                  name="email"
                  rules={[{ required: true, message: "נא להזין אימייל" }]}
                  className="full flexCol"
                  label={t("email")}
                >
                  <Input
                    size={"large"}
                    className="full radius br-black input"
                    type={"email"}
                    prefix={
                      <svg
                        width="20"
                        height="21"
                        viewBox="0 0 20 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_475_2569)">
                          <path
                            d="M15 6.5C15 9.26142 12.7614 11.5 10 11.5C7.23858 11.5 5 9.26142 5 6.5C5 3.73858 7.23858 1.5 10 1.5C12.7614 1.5 15 3.73858 15 6.5Z"
                            stroke="#FF6700"
                            strokeWidth="2"
                          />
                          <path
                            d="M19 21.5C19 16.5294 14.9706 12.5 10 12.5C5.02944 12.5 1 16.5294 1 21.5"
                            stroke="#FF6700"
                            strokeWidth="2"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_475_2569">
                            <rect
                              width="20"
                              height="20"
                              fill="white"
                              transform="translate(0 0.5)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    }
                    placeholder={t("email")}
                  />
                </Form.Item>

                <Form.Item
                  name="password"
                  rules={[{ required: true, message: "נא להזין סיסמא" }]}
                  className="full flexCol"
                  label={t("password")}
                >
                  <Input
                    size={"large"}
                    className="full radius br-black input"
                    type={"password"}
                    prefix={
                      <svg
                        width="20"
                        height="21"
                        viewBox="0 0 20 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_475_1676)">
                          <path
                            d="M14.3335 7.1665V5.1665C14.3335 2.95736 12.3934 1.1665 10.0002 1.1665C7.60693 1.1665 5.66683 2.95736 5.66683 5.1665V7.1665M14.3335 7.1665H5.66683M14.3335 7.1665H17.6668C18.2191 7.1665 18.6668 7.61422 18.6668 8.1665V18.8332C18.6668 19.3855 18.2191 19.8332 17.6668 19.8332H2.3335C1.78121 19.8332 1.3335 19.3855 1.3335 18.8332V8.1665C1.3335 7.61422 1.78121 7.1665 2.3335 7.1665H5.66683"
                            stroke="#FF6700"
                            strokeWidth="2"
                          />
                          <rect
                            x="9.3335"
                            y="13.1665"
                            width="1.33333"
                            height="1.33333"
                            rx="0.666667"
                            fill="#FF6700"
                          />
                          <rect
                            x="13.3335"
                            y="13.1665"
                            width="1.33333"
                            height="1.33333"
                            rx="0.666667"
                            fill="#FF6700"
                          />
                          <rect
                            x="5.3335"
                            y="13.1665"
                            width="1.33333"
                            height="1.33333"
                            rx="0.666667"
                            fill="#FF6700"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_475_1676">
                            <rect
                              width="20"
                              height="20"
                              fill="white"
                              transform="translate(0 0.5)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    }
                    placeholder={t("password")}
                  />
                </Form.Item>

                <div className="red">{this.error}</div>

                <div className="flex">
                  <Button
                    type="primary"
                    size={"large"}
                    htmlType="submit"
                    className="b-blue radius white br-white full marginTop20"
                  >
                    {t("loginSubmit")}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </SignInStyled>
    );
  }
}
export default withRouter(SignIn);
