const parseObjectToUrlParams = (obj: any, prefix?: any): any => {
  var str = [],
    p;
  // console.log('obj.hasOwnProperty(p):', obj.hasOwnProperty(p));

  for (p in obj) {
    // console.log("p: ", p);

    if (obj.hasOwnProperty(p)) {
      var k = prefix ? prefix + "[" + p + "]" : p,
        v = obj[p];
      str.push(
        v !== null && typeof v === "object"
          ? parseObjectToUrlParams(v, k)
          : // : k + "=" + v
            encodeURIComponent(k) + "=" + encodeURIComponent(v)
      );
    }
  }
  return str.join("&");
};

export default parseObjectToUrlParams;
