import styled from "styled-components";

const AddItem2Styled = styled.div`
  .how-much {
    direction: initial;
    text-align: right;
  }

  .ant-input-number-input {
    color: #ec5118;
  }

  .close {
    background: #3b3939;
    position: absolute;
    top: -8px;
    left: -10px;
    width: 22px;
    text-align: center;
    border-radius: 28px;
    color: #fff;
    z-index: 99;
  }

  .start-action {
    height: 25px;
    font-size: 13px;
  }

  .open_device {
    position: absolute;
    top: 11px;
    left: 5px;
  }

  .active {
    animation: pulse 2s infinite;
    background: #c1c0ff !important;
    color: #fff !important;
    border-color: #c1c0ff !important;
  }

  .flex {
    line-height: 2.2;
    color: #534646;
  }
  .open_device {
    width: 35px;
  }

  input.ant-input-number-input {
    font-weight: 100;
    font-size: 14px !important;
    padding: 8px !important;
  }

  .ant-input-number {
    border: none !important;
    margin-right: 13px;
    width: 94px !important;
  }

  .ant-checkbox-inner {
    width: 20px;
    height: 20px;
  }

  .approve {
    height: 10px;
  }

  label.ant-checkbox-wrapper {
    font-size: 10px;
  }
  .ant-checkbox-checked .ant-checkbox-inner::after {
    margin: 0px -4px !important;
    width: 6px !important;
    height: 12px !important;
  }

  button.ant-btn.ant-btn-primary.ant-btn-sm {
    width: 100%;
    height: 31px;
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.04);
      opacity: 1;
    }
  }
`;

export { AddItem2Styled };
