import styled from "styled-components";

const SlumpSliderStyled = styled.div<{ slumpRate: number }>`
  width: 100%;
  direction: ltr;
  .parent {
    display: flex;
    flex-direction: row;
  }
  .step {
    width: 100%;
    text-align: center;
    color: #2d2d2d;
    font-weight: 500;
    border: white solid;
    border-width: 0 2px 0 2px;
    height: 31px;
    line-height: 2.2;
  }
  .step1 {
    background-color: #0e9f6e;
  }
  .step2 {
    background-color: #48af59;
  }
  .step3 {
    background-color: #70bb4a;
  }
  .step4 {
    background-color: #99c63b;
  }
  .step5 {
    background-color: #c1d12c;
  }
  .step6 {
    background-color: #d4bf26;
  }

  .step7 {
    background-color: #d79d25;
  }

  .step8 {
    background-color: #da7925;
  }

  .step9 {
    background-color: #e02c24;
  }


  
  .anticon {
  position: relative;
    right: inherit;
    width: 100%;
  }

  .anticon svg{
    left: ${(p: any) => p.slumpRate -5  + "%"};
  position: absolute;
  }
`;
// p.slumpRate
export default SlumpSliderStyled;
