import CalibrationStyled from "./styled";

import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  Button,
  Col,
  Form,
  Row,
  Card,
  Select,
  Input,
  Checkbox,
  Typography,
} from "antd";
import { observable } from "mobx";
import axios from "axios";
import { observer } from "mobx-react";
import {
  ArrowLeftOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";

interface Props extends RouteComponentProps<any> {}

@observer
class Calibration extends React.PureComponent<Props, any> {
  @observable cement_types: any[] = [];
  @observable is_new_type: boolean = false;
  @observable sensor_min: number;
  @observable sensor_max: number;
  @observable real_min: number;
  @observable real_max: number;
  @observable cement_type: string = "עדש";
  @observable sensor_type: string = "wcratio";

  componentDidMount(): void {
    this.getCementTypes();
    if (this.cement_type && this.sensor_type) {
      this.getCalibrationValues();
    }
  }

  getCementTypes = async () => {
    const rsp = await axios.get(
      `${process.env.REACT_APP_API_URL}/calibration/cements`
    );

    this.cement_types = rsp.data;
  };

  getCalibrationValues = async () => {
    const query_str = `cement=${this.cement_type || ""}&sensor=${
      this.sensor_type || ""
    }`;
    console.log(query_str);

    const rsp = await axios.get(
      `${process.env.REACT_APP_API_URL}/calibration/data?${query_str}`
    );
    console.log(rsp.data[0]);

    const data = rsp?.data[0];
    this.sensor_min = Number(data?.sensor_min);
    this.sensor_max = Number(data?.sensor_max);
    this.real_min = Number(data?.real_min);
    this.real_max = Number(data?.real_max);
  };

  sendCalibration = async (values: any) => {
    console.log(values);
    values = {
      ...values,
      cement_type: this.cement_type,
      sensor_type: this.sensor_type,
    };
    const rsp = await axios.post(
      `${process.env.REACT_APP_API_URL}/calibration`,
      values
    );

    this.getCementTypes();
    this.is_new_type = false;
  };

  render() {
    return (
      <CalibrationStyled>
        <Form
          className="calibrate-sensor-form"
          size="large"
          onFinish={(values) => this.sendCalibration(values)}
        >
          <div className="flex page-title">
            <div className="page-title f20 blue flex1">כיול חיישן</div>
          </div>
          <Card>
            <Row>
              {this.is_new_type ? (
                <Form.Item name="cement_type" label="סוג בטון חדש">
                  <Input />
                </Form.Item>
              ) : (
                <Form.Item name="cement_type" label="סוג הבטון">
                  <Select
                    defaultValue={this.cement_type}
                    onChange={(val) => (this.cement_type = val)}
                  >
                    {this.cement_types.map((type) => (
                      <Select.Option key={type.cement_type}>
                        {type.cement_type}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
              {this.is_new_type ? (
                <MinusCircleOutlined
                  className="add-cement-icon"
                  onClick={() => (this.is_new_type = !this.is_new_type)}
                />
              ) : (
                <PlusCircleOutlined
                  className="add-cement-icon"
                  onClick={() => (this.is_new_type = !this.is_new_type)}
                />
              )}
            </Row>

            <Form.Item name="sensor_type" label="חיישן לכיול">
              <Select
                onChange={(val) => {
                  this.sensor_type = val;
                }}
                defaultValue={this.sensor_type}
              >
                <Select.Option value="wcratio" key="wcratio">
                  יחס מים/צמנט
                </Select.Option>
                <Select.Option value="slump" key="slump">
                  רמת שקיעה
                </Select.Option>
                <Select.Option value="fill_rate" key="fill_rate">
                  כמות הבטון
                </Select.Option>
              </Select>
            </Form.Item>
            {this.cement_type && this.sensor_type ? (
              <Card>
                <Row gutter={[16, 16]}>
                  <Col span={3}></Col>
                  <Col span={8}>
                    <div>ערכי חיישן</div>
                  </Col>
                  <Col span={2}></Col>
                  <Col span={8}>
                    <div>ערכי אמת</div>
                  </Col>
                </Row>
                <Row>
                  <Col span={3}>נמוך</Col>
                  <Col span={8}>
                    <Form.Item name={"sensor_min"} required>
                      <Input
                        type="number"
                        placeholder={
                          this.sensor_min ? this.sensor_min.toString() : "-"
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    <ArrowLeftOutlined className="calibration-arrow" />
                  </Col>

                  <Col span={8}>
                    <Form.Item name={"real_min"} required>
                      <Input
                        type="number"
                        placeholder={
                          this.real_min ? this.real_min.toString() : "-"
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={3}>גבוה</Col>
                  <Col span={8}>
                    <Form.Item name={"sensor_max"} required>
                      <Input
                        type="number"
                        placeholder={
                          this.sensor_max ? this.sensor_max.toString() : "-"
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    <ArrowLeftOutlined className="calibration-arrow" />
                  </Col>
                  <Col span={8}>
                    <Form.Item name={"real_max"} required>
                      <Input
                        type="number"
                        placeholder={
                          this.real_max ? this.real_max.toString() : "-"
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            ) : (
              <Typography.Text>בחר סוג בטון וחיישן</Typography.Text>
            )}
            <Form.Item>
              <Button
                className="br-blue2 start-action radius blue2 transparent full marginTop20 "
                htmlType="submit"
              >
                כייל חיישן
              </Button>
            </Form.Item>
          </Card>
        </Form>
      </CalibrationStyled>
    );
  }
}
export default withRouter(Calibration);
