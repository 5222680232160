import styled from "styled-components";

const TopFilterStyled = styled.div`
  .ant-form-item-control-input * {
    color: #6d6d70 !important;
  }

  .ant-form-item-control-input {
    border: 1px solid #9ca3af;
    border-radius: 5px;
  }

  input#amount {
    height: 43px;
    text-align: revert;
  }
  span.ant-select-selection-item {
    color: #846d70;
  }

  input,
  .ant-select-selection-item {
    font-size: 14px !important;
  }

  .filter-grid label {
    font-size: 14px;
    padding-top: 10px;
    font-weight: 400;
  }

  .ant-form-item-label {
    display: none;
  }

  .concreateType,
  .concreateTree {
    min-width: 280px;
  }
  .sensor_factory {
    min-width: 120px;
  }
  .sensor_number {
    min-width: 90px;
  }

  .divider {
    height: 43px !important;
    border-color: #b5b8bd;
    top: 1px;
  }

  .download_csv {
    margin-top: 12px;
  }

  @media screen and (max-width: 600px) {
    .doctorfilter {
      flex-direction: column;
      gap: 0;
    }

    .divider {
      width: 100%;
      height: 1px !important;
      background: rgb(181, 184, 189);
    }

    .flex.page-title.gap20 {
      height: 20px !important;
    }

    .flex.gap10 {
      width: 29%;
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }

    .apexcharts-canvas {
      width: 100% !important;
    }

    .ant-form-item {
      width: 100%;
    }

    .ant-select.ant-select-in-form-item.ant-select-single.ant-select-show-arrow {
      width: 100% !important;
    }

    .pglmR {
      margin-top: 0px;
    }
  }

  input[type="checkbox"] {
    width: 20px;
    height: 20px;
  }
`;

export { TopFilterStyled };
