import { CloseOutlined, SettingOutlined } from "@ant-design/icons";
import { Button, Input, message } from "antd";
import axios from "axios";
import { t } from "i18next";
import { observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import parseObjectToUrlParams from "../../../../utils/parseObjectToUrlParams";
import TopFilter from "../../common/TopFilter/TopFilter";
import ConcreteDelivery from "../ConcreteDelivery/ConcreteDelivery";
import { ConcreteUnusualStyled } from "./styled";
import { SettingsUnusualStyled } from "./styledSettings";
interface Props extends RouteComponentProps<any> {}

@observer
class SettingsUnusualMain extends React.PureComponent<Props, any> {
  @observable deliveries: any = {};
  @observable show_signle_repors: boolean = false;
  @observable urlParams: Record<string, any> = {};
  @observable data = [
    {
      name: t("GapInThreshold"),
      number: "54",
      prefix: t("deliveries"),
      icon: (
        <svg
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M28 28H3C2.44772 28 2 27.5523 2 27V2M7 23L26 4M26 4V10M26 4H20"
            stroke="#FF6700"
            stroke-width="2"
            stroke-linecap="round"
          />
        </svg>
      ),
      showSettings: true,
      setting_name: t("GapInThresholdForAbnormal"),
      setting_key: "max_slump_change",
      setting_prefix: t("mm"),
      nameData: "slump_count",
      input: {},
    },

    {
      name: t("Above120Minutes"),
      number: "67",
      prefix: t("deliveries"),
      icon: (
        <svg
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 6C16 5.44772 15.5523 5 15 5C14.4477 5 14 5.44772 14 6H16ZM15 15H14C14 15.2652 14.1054 15.5196 14.2929 15.7071L15 15ZM20.2929 21.7071C20.6834 22.0976 21.3166 22.0976 21.7071 21.7071C22.0976 21.3166 22.0976 20.6834 21.7071 20.2929L20.2929 21.7071ZM27 15C27 21.6274 21.6274 27 15 27V29C22.732 29 29 22.732 29 15H27ZM15 27C8.37258 27 3 21.6274 3 15H1C1 22.732 7.26801 29 15 29V27ZM3 15C3 8.37258 8.37258 3 15 3V1C7.26801 1 1 7.26801 1 15H3ZM15 3C21.6274 3 27 8.37258 27 15H29C29 7.26801 22.732 1 15 1V3ZM14 6V15H16V6H14ZM14.2929 15.7071L20.2929 21.7071L21.7071 20.2929L15.7071 14.2929L14.2929 15.7071Z"
            fill="#FF6700"
          />
        </svg>
      ),
      showSettings: true,
      setting_name: t("TimeToDischarge"),
      setting_key: "max_delivery_time",
      setting_prefix: t("min"),
      nameData: "deliveries_count",
      input: {},
    },
    {
      name: t("AbnormalTemperature"),
      number: "18",
      prefix: t("deliveries"),
      icon: (
        <svg
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21 9L15 15M8 27H22M23 15H24M6 15H7M15 6V7M8.5 8.5L9.5 9.5M28 15C28 7.8203 22.1797 2 15 2C7.8203 2 2 7.8203 2 15C2 18.1864 3.14639 21.105 5.04923 23.366C5.39998 23.7827 5.92612 24 6.47084 24C10.8833 24 19.1167 24 23.5292 24C24.0739 24 24.6 23.7827 24.9508 23.366C26.8536 21.105 28 18.1864 28 15Z"
            stroke="#FF6700"
            stroke-width="2"
            stroke-linecap="round"
          />
        </svg>
      ),
      showSettings: true,
      setting_name: t("AbnormalTemperature"),
      setting_key: "max_temperature",
      setting_prefix: t("degrees"),
      nameData: "temp_count",
      input: {},
    },
  ];

  @observable generalData: any = {
    total_count: 0,
    slump_count: 0,
    deliveries_count: 0,
    noAllowed_count: 0,
    temp_count: 0,
    water_count: 0,
    drivers_count: 0,
  };

  @observable settingData: any = {
    slump_count: 15,
    deliveries_count: 90,
    noAllowed_count: 1,
    temp_count: 28,
  };

  componentDidMount = async () => {
    this.createObjectInLocalStorageIfNotExists();
    this.getData();
  };

  getData = async () => {
    this.urlParams.settingData = this.settingData;

    let rsp = await axios.get(
      `${process.env.REACT_APP_API_URL}/calculate/general?` +
        parseObjectToUrlParams(this.urlParams)
    );

    this.generalData = rsp.data.data;
    console.log({ data: this.generalData });
  };

  getSingalDelivires = async (report: string) => {
    this.show_signle_repors = true;
    this.urlParams.settingData = this.settingData;
    this.urlParams.report = report;
    let rsp = await axios.get(
      `${process.env.REACT_APP_API_URL}/calculate/general/deliveris?` +
        parseObjectToUrlParams(this.urlParams)
    );

    this.deliveries = rsp.data.data;
  };

  createObjectInLocalStorageIfNotExists() {
    if (!localStorage.getItem("deliveryData")) {
      localStorage.setItem("deliveryData", JSON.stringify(this.settingData));
    } else {
      console.log({ data: JSON.parse(localStorage.getItem("deliveryData")) });
      this.settingData = JSON.parse(localStorage.getItem("deliveryData"));
    }
  }

  updateObjectValue(key: string, value: any) {
    const existingData = JSON.parse(localStorage.getItem("deliveryData")) || {};
    existingData[key] = value;
    localStorage.setItem("deliveryData", JSON.stringify(existingData));
    this.settingData = JSON.parse(localStorage.getItem("deliveryData"));
  }

  closeSettings = () => {
    console.log("this", this);

    this.getData();
    message.success(t("success_message"));
  };

  render() {
    return (
      <SettingsUnusualStyled>
        <div className="container flex flex-col gap-6">
          <div className="text-[22px] font-bold ">
            {t("exceptions")} {t("settings")}
          </div>
          <div className="grid grid-cols-2 gap-6">
            {this.data?.map((item: any) => {
              return (
                <div className="flex flexCol item">
                  <div className="flex gap10">
                    <div className="icon">{item.icon}</div>

                    <div className="flex flexCol">
                      <div className="title">{item.setting_name}</div>

                      <Input
                        className="input-settings"
                        type="text"
                        addonAfter={item.setting_prefix}
                        value={this.settingData[item.nameData]}
                        onChange={(e) =>
                          this.updateObjectValue(
                            item.nameData,
                            Number(e.target.value)
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="flex justify-end">
            <button
              className=" text-white rounded-lg bg-main-orange px-6 py-2 text-lg font-medium"
              onClick={this.closeSettings}
            >
              {t("save")}
            </button>
          </div>
        </div>
      </SettingsUnusualStyled>
    );
  }
}
export default withRouter(SettingsUnusualMain);
