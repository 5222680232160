import moment from "moment";
import { observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Messages from "./Messages/Messages";
import SensorsData from "./SensorsData/SensorsData";
import Status from "./Status/Status";
import { ReadDataStyled } from "./styled";
import { t } from "i18next";
import TopFilter from "../common/TopFilter/TopFilter";
import parseObjectToUrlParams from "../../../utils/parseObjectToUrlParams";
const axios = require("axios");
interface Props extends RouteComponentProps<any> {}

@observer
class ReadData extends React.PureComponent<Props, any> {
  @observable tab: number = 1;
  @observable current_time: any | null = null;
  @observable messages: any = [];
  @observable sensors_data: any = [];
  @observable active_sensors: number = 0;
  @observable test_sensors: number = 0;
  @observable sensor_number: string;
  @observable urlParams: Record<string, any> = { sensor_type: 1 };

  updaterIntervalId: any;

  componentDidMount = async () => {
    try {
      this.getMessagesById(this.sensor_number);

      var c = this;
      this.updaterIntervalId = setInterval(function async() {
        c.currentTime();
        c.getMessagesById(c.sensor_number);
        c.getSensorsData();
      }, 5000);
    } catch (error) {
      console.error(error);
    }
  };

  getMessagesById = async (track_number: string) => {
    try {
      let messages_data = await axios.get(
        `${process.env.REACT_APP_API_URL}/flow_messages/${track_number}/last-messages`
      );
      this.messages = messages_data.data;
    } catch (error) {
      console.error(error);
    }
  };

  getSensorsData = async () => {
    try {
      console.log({ urlParams: this.urlParams });

      let sensors_data = await axios.get(
        `${process.env.REACT_APP_API_URL}/sensors?` +
          parseObjectToUrlParams(this.urlParams)
      );
      this.sensors_data = sensors_data.data.sensors_data;
      this.active_sensors = sensors_data.data.active_sensors;
      this.test_sensors = sensors_data.data.test_sensores;
    } catch (error) {
      console.error(error);
    }
  };

  changeTrackNumber = async (track_number: string) => {
    try {
      this.sensor_number = track_number;
      this.getMessagesById(this.sensor_number);
    } catch (error) {
      console.error(error);
    }
  };

  currentTime() {
    this.current_time = moment().format("HH:mm");
  }

  changeTabs = (tab_number: number) => {
    this.tab = tab_number;
    if (tab_number === 1) {
      this.getMessagesById(this.sensor_number);
    } else {
      /** Get Sensors Status from Sensors-Table. */
      this.getSensorsData();
    }
  };

  changeSensorsType = (type: number) => {
    this.urlParams.sensor_type = type;
    this.getSensorsData();
  };

  componentWillUnmount(): void {
    clearInterval(this.updaterIntervalId);
  }

  onFilterChange = async (filterName: string, filterValue: any) => {
    if (filterName === "factory_id") {
      this.urlParams.factory_id = filterValue;
    }

    if (filterName === "sensor_id") {
      this.urlParams.sensor_id = filterValue;
    }

    try {
      let rsp = await axios.get(
        `${process.env.REACT_APP_API_URL}/sensors?` +
          parseObjectToUrlParams(this.urlParams)
      );

      this.sensors_data = rsp.data.sensors_data;
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    return (
      <ReadDataStyled>
        {" "}
        <div className="content-page">
          <div className="title-2">{t("sensors_statuses")}</div>
          <div className="flex gap40 mobile-col">
            <div className="box status">
              <Status
                active_sensors={this.active_sensors}
                test_sensors={this.test_sensors}
                data={this.sensors_data}
                changeSensorsType={this.changeSensorsType}
                changeTabs={this.changeTabs}
                tab_number={this.tab}
                current_time={this.current_time}
              />
            </div>

            <div className="box flex1">
              <div className="flex padding20">
                {this.tab === 1 ? (
                  <div className="flex flexCol full">
                    <TopFilter
                      class={"concreate-filter"}
                      background={false}
                      filters={["factory", "sensor"]}
                      changeTrackNumber={(track_number) =>
                        this.onFilterChange("sensor_id", track_number)
                      }
                      onFilterChange={this.onFilterChange}
                    />

                    <SensorsData sensors_data={this.sensors_data} />
                  </div>
                ) : (
                  <Messages
                    sensors_data={this.sensors_data}
                    messages={this.messages}
                    changeTrackNumber={this.changeTrackNumber}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </ReadDataStyled>
    );
  }
}
export default withRouter(ReadData);
