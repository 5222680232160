import React from "react";
import { withRouter } from "react-router-dom";
import TopFilter from "../common/TopFilter/TopFilter";
import { MixtureControlStyled } from "./styled";
import { Table } from "antd";
import { useQuery } from "react-query";

function Mixtures(props: {
  mixtures: {
    key: string;
    name: string;
  }[];
  onClose?: (mixture: { key: string; name: string }) => void;
}) {
  if (props.mixtures.length === 0) {
    return null;
  }
  return (
    <div className="flex flexRow align-center f18 bold gap20">
      <div>Mixtures</div>
      <div className="flex flexRow align-center gap20">
        {props.mixtures.map((mixture) => (
          <div
            key={mixture.key}
            className="paddingHor10 paddingVer20 bg-white flex gap15"
          >
            {mixture.name}
            <img src="/images/filter-cog.svg" alt="close" />
            <img
              src="/images/close.svg"
              alt="close"
              className="pointer"
              onClick={props.onClose ? () => props.onClose(mixture) : undefined}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

type MixtureSummary = {
  name: string;
  deliveries: number;
  exDensity: number;
  exTemperature: number;
  temperatureUnit: string;
  addingWater: number;
  addingWaterUnit: string;
  dangers?: ("temp" | "density" | "water")[];
};

const mockMixturesSummaries: MixtureSummary[] = [
  {
    name: "Reinforced Concrete",
    deliveries: 52,
    exDensity: 3,
    exTemperature: 75,
    temperatureUnit: "C",
    addingWater: 334,
    addingWaterUnit: "L",
    dangers: ["temp"],
  },
  {
    name: "Precast Concrete",
    deliveries: 52,
    exDensity: 3,
    exTemperature: 23,
    temperatureUnit: "C",
    addingWater: 1996,
    addingWaterUnit: "L",
    dangers: ["water"],
  },
  {
    name: "High-Strength Concrete",
    deliveries: 52,
    exDensity: 3,
    exTemperature: 23,
    temperatureUnit: "C",
    addingWater: 334,
    addingWaterUnit: "L",
  },
  {
    name: "Self-Consolidating Concrete (SCC)",
    deliveries: 52,
    exDensity: 3,
    exTemperature: 23,
    temperatureUnit: "C",
    addingWater: 334,
    addingWaterUnit: "L",
  },
  {
    name: "Lightweight Concrete",
    deliveries: 52,
    exDensity: 3,
    exTemperature: 23,
    temperatureUnit: "C",
    addingWater: 334,
    addingWaterUnit: "L",
  },
  {
    name: "6",
    deliveries: 52,
    exDensity: 3,
    exTemperature: 23,
    temperatureUnit: "C",
    addingWater: 334,
    addingWaterUnit: "L",
  },
];

function MixtureControl(props: {}) {
  const [selectedMixtures, setSelectedMixtures] = React.useState([]);
  const mixtures = useQuery(["mixtures", selectedMixtures], async () => {
    return mockMixturesSummaries;
  });
  return (
    <MixtureControlStyled className="flex padding20 flexCol gap10">
      <div className="bold f36">Mixture control</div>
      <TopFilter
        changeTrackNumber={(truckNumber) => {
          console.log(truckNumber);
        }}
        onFilterChange={(filter, value) => {
          if (filter === "loading_concreate_multi_type") {
            setSelectedMixtures(value);
          }
        }}
        filters={["loading_concreate_multi_type", "date"]}
      />

      <div className="grid grid-cols-3 gap-3">
        {mixtures.data?.map((mixture) => (
          <div
            key={mixture.name}
            className={`summary-card ${
              mixture.dangers?.length ? "summary-card-danger" : ""
            }`}
          >
            <div className="bold f18 flex justify-between">
              <span>{mixture.name}</span>
              {mixture.dangers?.length > 0 && (
                <img src="/images/warning.svg" alt="close" />
              )}
            </div>
            <div className="flex flexCol gap5">
              <div className="summary-row">
                <span className="fontweight600">Deliveries: </span>
                <span className="fontweight700">{mixture.deliveries}</span>
              </div>
              <div className="divider"></div>
              <div className="summary-row">
                <span className="fontweight600">Ex density: </span>
                <span
                  className={`fontweight700 ${
                    mixture.dangers?.includes("density") ? "danger-label" : ""
                  }`}
                >
                  {mixture.exDensity}
                </span>
              </div>
              <div className="divider"></div>
              <div className="summary-row">
                <span className="fontweight600">Ex temperature: </span>
                <span
                  className={`fontweight700 ${
                    mixture.dangers?.includes("temp") ? "danger-label" : ""
                  }`}
                >
                  {mixture.exTemperature}°{mixture.temperatureUnit}
                </span>
              </div>
              <div className="divider"></div>
              <div className="summary-row">
                <span className="fontweight600">Adding water: </span>
                <span
                  className={`fontweight700 ${
                    mixture.dangers?.includes("water") ? "danger-label" : ""
                  }`}
                >
                  {mixture.addingWater} {mixture.addingWaterUnit}
                </span>
              </div>
              <div className="divider"></div>
            </div>
          </div>
        ))}
      </div>
    </MixtureControlStyled>
  );
}

export const MixtureControlPage = withRouter(MixtureControl);
