import styled from "styled-components";

const CalibrationStyled = styled.div`
  .ant-form-item {
    margin: 0 1rem 0 0;
    min-width: 100px;
    /* width: 150px; */
  }
  .ant-form {
    display: flex;
    flex-direction: row;
  }
  .fluids-data-row {
    display: flex;
    flex-direction: row;
    margin: 1rem 0 0 0;
  }
  .fluid-prediction {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 60%;
  }
  .arrow-icon {
    margin: 3rem 1rem 0 0;
  }
  .fluid-drop {
    margin: 3rem 1rem 0 0;
  }
`;

export const FluidCardStyled = styled.div<{ ratio: number }>`
  .fluids-data-card {
    margin: 0 1rem 0 0;
    border-radius: 10px;
    background: linear-gradient(
      180deg,
      #ffffff ${({ ratio }) => 100 - ratio * 100}%,
      #206986 0%
    );
  }
`;

export default CalibrationStyled;
