import moment from "moment";
import React from "react";
import {
  CiBatteryFull,
  CiBatteryEmpty,
  CiCloudOn,
  CiCloudOff,
  CiWifiOff,
  CiWifiOn,
} from "react-icons/ci";
type statusProps = { isOk?: boolean };

const showIcon = (time: any) => {
  var now = moment();

  var timeMoment = moment(time);

  var diffSeconds = now.diff(timeMoment, "seconds");

  if (diffSeconds <= 20) {
    return <CiCloudOn />;
  } else {
    return <CiCloudOff />;
  }
};
export const NetworkStatus = ({ isOk = true, createdAt = null }: any) => (
  <div className="item">
    {showIcon(createdAt)}

    <div className="icon">connection</div>
  </div>
);
export const BatteryStatus = ({ isOk = true, battery = "100" }: any) => (
  <div className="item">
    {isOk ? <CiBatteryFull /> : <CiBatteryEmpty />}
    <div className="icon">{battery} %</div>
  </div>
);
export const SensorStatus = ({ isOk = true, groupId = null }: any) => (
  <div className="item">
    {/* {groupId && moment(groupId).isAfter(moment().subtract(1, "minute")) ? (
      <CiWifiOn />
    ) : (
      <CiWifiOff />
    )} */}

    <CiWifiOn />
    <div className="icon">sensor connection</div>
  </div>
);

const StatusesHeader = () => {};
