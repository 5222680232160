import styled from "styled-components";

export const MixtureControlStyled = styled.div`
  /* .grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
  } */
  .summary-card {
    padding: 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: #fff;
  }
  .summary-card-danger {
    border: 2px solid #e02424;
  }
  .summary-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .danger-label {
    color: #e02424;
  }
  .divider {
    width: 100%;
    height: 1px;
    background-color: #d1d5db;
  }
`;
