/** @format */

import BaseModel from "./base.model";
import BaseHttpService, { OptionsRequest } from "../services/base.http.service";
import { runInAction, action, observable, computed } from "mobx";
import parseObjectToUrlParams from "../utils/parseObjectToUrlParams";
import { message } from "antd";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
abstract class BaseStore {
  apiService: any;
  _status: "pending" | "reject" | "done" = "pending";

  @observable currentPageNumber: number = 1;
  @observable visible: boolean = false;
  @observable selectedRecord: any = false;
  @observable user: any = null;
  @observable filter: any = {};
  @observable primaryColor: String = "#F3E9DE";
  @observable secondaryColor: String = "#E3C7AE";

  constructor(apiService: BaseHttpService) {
    this.apiService = apiService;
  }

  get status() {
    return this._status;
  }

  @observable models: BaseModel[] = [];

  @computed get isAuthenticated(): boolean {
    return this.user !== null;
  }

  @action onFilterChanged = (filter: any) => {
    this.filter = filter;
  };

  @action
  del = (model: BaseModel) => {
    const index = this.models.indexOf(model);

    if (index > -1) {
      this.models.splice(index, 1);
    }
  };

  abstract url(): string;

  abstract get model(): BaseModel;

  create = (data: any = {}) => {
    const model: any = this.model;
    return new model(this.apiService, this, data);
  };

  @action
  fetchOne = async (id: number, params?: any): Promise<any> => {
    try {
      let url = `${this.url()}/${id}`;

      if (params) {
        url += `?${parseObjectToUrlParams(params)}`;
      }

      const response = await (await this.apiService.get(url).promise).json();
      const model = this.create(response || {});
      return model;
    } catch (error) {
      return error;
    }
  };

  @action
  fetch = async (where?: any) => {
    const { promise } = this.apiService.get(
      `${this.url()}?${parseObjectToUrlParams(where || {})}`
    );
    const response: any = await promise;
    const { status } = response;

    if (status === 200) {
      const dataKey = this.url().replace("/", "");
      const data = await response.clone().json();

      runInAction(() => {
        const _data = Array.isArray(data)
          ? data
          : data[dataKey] || data.results;
        this.models = _data.map((data: any) => {
          const model: any = this.model;

          return new model(this.apiService, this, data);
        });
      });
    }
    return promise;
  };

  onCreateModel = action((model: any) => {
    runInAction(() => {
      this.models.unshift(model);
    });
  });

  abstract fetchPage({}: {
    page?: number;
    pageSize?: number;
    startTime?: number | undefined;
    endTime?: number | undefined;
    params?: any;
    filter?: any;
    customPath?: string;
  }): Promise<OptionsRequest>;

  @action
  changeUsersStatus = async (
    status: string,
    usersIds: any,
    options: any = {}
  ): Promise<any> => {
    const { promise } = this.apiService.post(`${this.url()}/change-status`, {
      status: status,
      usersIds: usersIds,
    });

    console.log([status, usersIds]);

    const response: any = await (await promise).json();
    const { updatedRows } = response;

    if (status === "delete") {
      if (updatedRows > 0) {
        this.fetchPage({ page: this.currentPageNumber, ...options });
        message.info(`Success deleted ${updatedRows} records`);
      } else {
        message.error("Something went wrong when create new category.");
      }
    } else {
      if (updatedRows[0] > 0) {
        this.fetchPage({ page: this.currentPageNumber, ...options });
        message.info(`Success updated ${updatedRows[0]} records`);
      } else {
        message.error("Something went wrong when create new category.");
      }
    }
  };

  @action
  downloadCsv(rows: any, file_name: string) {
    try {
      if (!rows.length) {
        message.error("Please select rows to export");
      } else {
        const csv = [rows[0]]
          .map((i: any) => {
            return Object.keys(i);
          })
          .concat(
            rows.map((i: any) => {
              return Object.values(i).map((v) => `"${v}"`);
            })
          )
          .join("\n")
          .replace(/(^\[)|(\]$)/gm, "");
        console.log(csv);
        var hiddenElement = document.createElement("a");
        hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
        hiddenElement.target = "_blank";
        hiddenElement.download = `wiser-market-${file_name}.csv`;
        hiddenElement.click();
      }
    } catch (error) {}
  }

  @action
  onSearch = (pageOptions: any = {}): Promise<OptionsRequest> => {
    return this.fetchPage({ filter: this.filter, ...pageOptions });
  };

  @action
  deleteItem = async (params?: any, fetchPageOption = {}) => {
    this.visible = false;
    if (this.selectedRecord) {
      return this.selectedRecord.destroy();
    }
  };

  @action
  cancelDeleteItem = async () => {
    this.visible = false;
  };

  @action
  openDeleteItem = async () => {
    this.visible = true;
  };
  @action
  changePrimaryColor = (color: string) => {
    this.primaryColor = color;
  };
  @action
  changeSecondaryColor = (color: string) => {
    this.secondaryColor = color;
  };
}

export default BaseStore;
