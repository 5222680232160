import styled from "styled-components";

const ReadDataStyled = styled.div`
  .box.status {
    max-height: 298px;
    padding: 20px;
  }


  .slump-statuses {
    width: 100%;
    flex-wrap: wrap;
    padding: 20px;
    display: flex;
    gap: 20px;
}

  .box-items {
    background: rgb(255, 255, 255);
    border-radius: 5px;
    cursor: pointer;
    width: 22%;
    text-align: center;
    margin-right: 14px;
    padding: 10px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
}



h3.bold.text-center {
    font-size: 19px;
}
.px-4.py-2.black {
    font-size: 21px;
}
.box-items.red {
    background: #df1a1a42;
}

.box-items.orange {
    background: #ff832469;
}
.box-items.green {
    background: #42f44252;
}

  .active-row {
    background: #72fa9b;
  }

  td {
    font-size: 15px;
    .flex {
      font-size: 13px;
    }
  }
`;

export { ReadDataStyled };
