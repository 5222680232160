import React from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { inject, observer } from "mobx-react";
import AuthStore from "../../stores/auth/auth.store";
import { HeaderStyled } from "./styled";

interface Props extends RouteComponentProps<any> {
  authStore?: AuthStore;
  display: () => void;
}

@inject("authStore")
@observer
class HeaderMobile extends React.PureComponent<Props, any> {
  render() {
    return (
      <HeaderStyled className="">
        <div className="flex header-mobile">
          <svg
            className=""
            width="31"
            height="30"
            viewBox="0 0 31 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.0374 13.8614L11.9819 20.1396L14.4454 8.65818L17.0275 8.65818L18.0374 13.8614Z"
              fill="#FF6700"
            />
            <path
              d="M27.7299 25.3245L25.1539 14.6339L27.1595 14.6339L26.2048 10.5322L24.1931 10.5322L23.915 9.42048C23.6186 8.21674 23.3017 6.96805 23.1096 5.74183C22.531 2.22667 19.7997 0.129844 15.8029 0.127801C13.9425 0.127801 12.2211 0.577415 10.9577 1.39081C9.16677 2.54345 8.64545 4.30716 8.39399 5.71526C8.30608 6.2037 8.18956 6.70849 8.06076 7.19489C7.99943 7.43605 7.94014 7.67925 7.88494 7.92041L7.27162 10.5363L5.27016 10.5363L4.31543 14.638L6.32098 14.638L3.74504 25.3286L0.75 25.3286L0.75 29.8574L7.49651 29.8574L12.8998 6.6104L18.5608 6.6104L23.9723 29.8574L30.7188 29.8574L30.7188 25.3245L27.7299 25.3245Z"
              fill="#2D2D2D"
            />
            <path
              d="M18.6997 16.2642L10.9637 24.0814L9.79639 29.8916L21.6743 29.8916L18.6997 16.2642Z"
              fill="#FF6700"
            />
          </svg>

          <div className="noti flex gap20">
            <svg
              width="18"
              height="20"
              viewBox="0 0 18 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 20C9.6193 20.0008 10.2235 19.8134 10.7285 19.4639C11.2335 19.1144 11.6143 18.6202 11.818 18.05H6.182C6.38566 18.6202 6.76648 19.1144 7.27151 19.4639C7.77654 19.8134 8.3807 20.0008 9 20ZM16 12.7713V8.3C16 5.16342 13.815 2.52117 10.855 1.72655C10.562 1.007 9.846 0.5 9 0.5C8.154 0.5 7.438 1.007 7.145 1.72655C4.184 2.52117 2 5.16342 2 8.3V12.7713L0.293001 14.4357C0.199958 14.5261 0.126171 14.6335 0.0758854 14.7518C0.0256001 14.8701 -0.000189449 14.9969 1.04767e-06 15.125V16.1C1.04767e-06 16.3586 0.105358 16.6066 0.292894 16.7894C0.48043 16.9723 0.734784 17.075 1 17.075H17C17.2652 17.075 17.5196 16.9723 17.7071 16.7894C17.8946 16.6066 18 16.3586 18 16.1V15.125C18.0002 14.9969 17.9744 14.8701 17.9241 14.7518C17.8738 14.6335 17.8 14.5261 17.707 14.4357L16 12.7713Z"
                fill="#9CA3AF"
              />
            </svg>

            <svg
              onClick={() => this.props.display()}
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="30" height="30" rx="5" fill="#FF6700" />
              <rect x="5" y="8" width="20" height="2" rx="1" fill="#2D2D2D" />
              <rect x="5" y="14" width="20" height="2" rx="1" fill="#2D2D2D" />
              <rect x="5" y="20" width="20" height="2" rx="1" fill="#2D2D2D" />
            </svg>
          </div>
        </div>
      </HeaderStyled>
    );
  }
}
export default withRouter(HeaderMobile);
