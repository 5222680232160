import styled from "styled-components";

export const MaterialsChangeStyled = styled.div`
  .ant-table-row {
    border: 1px solid #000;

    .ant-table-cell {
      border: none;
    }
  }
  .ant-table-thead {
    .ant-table-cell {
      border: 1px solid #000 !important;
    }
  }
`;
