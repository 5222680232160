import { AreaChartOutlined } from "@ant-design/icons";
import { Badge, Drawer, Table } from "antd";
import axios from "axios";
import { t } from "i18next";
import { observable } from "mobx";
import { observer } from "mobx-react";
import moment from "moment";
import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import ChartPie from "./ChartPie";
import { SensorsDataStyled } from "./styled";

interface Props extends RouteComponentProps<any> {
  sensor_id: number;
}

@observer
class SensorsDataConnection extends React.PureComponent<Props, any> {
  @observable conection_data: any = [];

  componentDidMount = async () => {
    try {
      const rsp = await axios.get(
        `${process.env.REACT_APP_API_URL}/calculate/conections/${this.props.sensor_id}`
      );
      console.log({ data_new: rsp.data });
      this.conection_data = rsp.data.data;
    } catch (error) {
      console.error(error);
    }
  };

  columns: any = [
    {
      title: t("ConnectionDuration"),
      dataIndex: "duration",
      render: (value: string, record: any) => {
        const durationMinutes = moment(record.date_download).diff(
          moment(record.date_upload),
          "minutes"
        );
        const hours = Math.floor(durationMinutes / 60);
        const remainingMinutes = durationMinutes % 60;

        if (isNaN(durationMinutes)) {
          return "-";
        }
        if (durationMinutes > 60) {
          return `${hours} ${t("hours")} ${remainingMinutes} ${t("minutes")}`;
        } else {
          return `${durationMinutes} ${t("minutes")}`;
        }
      },
    },

    {
      title: t("date"),
      dataIndex: "createdAt",
      render: (value: string, record: any) => {
        return moment(value).format("DD/MM/YY , HH:mm");
      },
    },
  ];

  render() {
    console.log({ sensor: this.props.sensor_id });
    return (
      <SensorsDataStyled>
        <Table dataSource={this.conection_data} columns={this.columns} />
      </SensorsDataStyled>
    );
  }
}
export default withRouter(SensorsDataConnection);
