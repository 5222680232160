import styled from "styled-components";

const CementStateStyled = styled.div<{ rpmRate: string }>`
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }

  .close-pop {
    cursor: "pointer";
  }

  .topcard {
    display: flex;
    gap: 20px;
    align-items: center;
  }

  .bold.top-title {
    font-size: 30px;
  }

  .flex.flexCol {
    flex-direction: row !important;
    gap: 20px;
    justify-content: center;
  }

  .datetime {
    flex-direction: row !important;
    gap: 20px !important;
  }

  .value_date {
    font-size: 18px;
    margin: 0px 13px;
    font-family: monospace;
    color: #ffad61;
    font-weight: bold;
  }

  .rpm-icon {
    animation: spin linear infinite;
    animation-duration: ${(p: any) => p.rpmRate};
  }
  .pretty-cement-data {
    width: 60%;
    justify-content: space-between;
  }

  .data-card {
    justify-content: space-between;
  }
  .data-list {
    width: 40%;
  }
  .ant-slider {
    margin-top: 10%;
    width: 100%;
  }

  .card-mixer-info .flex {
    flex-direction: column-reverse;
  }

  .card-mixer-info .container {
    width: 100%;
    border: navajowhite;
    margin-top: 32px;
    padding: 0px !important;
  }

  .ant-card-body {
    padding: 0px;
    border: none !important;
  }

  .ant-card.ant-card-bordered.flex.flexRow.data-card.justify-betweenl.card-mixer-info {
  }

  .card-mixer-info {
    border: none !important;
  }

  img.rpm-icon {
    top: 154px;
    width: 24px;
    position: absolute;
    right: 37px;
  }
`;

export default CementStateStyled;
