import styled from "styled-components";

const ConcreteDeliveryStyled = styled.div`
  .concreate-filter .filter-grid.doctorfilter {
    display: flex;
  }

  td.ant-table-cell {
    font-size: 14px;
  }

  td.ant-descriptions-item {
    width: 0px;
  }
  .match {
    width: 10px;
    height: 10px;
    background: #a5e38c;
    border-radius: 20px;
    margin: 7px 0px;
    margin-left: 10px;
  }

  .icon-red {
    background: #e02424;
    width: 21px;
    padding: 3px;
    border-radius: 48px;
}
  .add-delevery {
    margin-right: 10px;
    background: #ff6700;
    border-radius: 28px;
    height: 19px;
    width: 19px;
    text-align: center;
    color: #fff;
    margin-top: 5px;
    line-height: 1.4;
  }

  @media screen and (max-width: 600px) {
    .open-filters {
      margin: 5px 0px;
    }
  }
`;

export { ConcreteDeliveryStyled };
