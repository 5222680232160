import moment from "moment";
import { observable } from "mobx";
import { observer } from "mobx-react";

import { withRouter, RouteComponentProps } from "react-router-dom";
import { ReadDataStyled } from "./styled";
import { ApexOptions } from "apexcharts";
import React from "react";
import ReactApexChart from "react-apexcharts";
import { t } from "i18next";

const axios = require("axios");
interface Props extends RouteComponentProps<any> {}

@observer
class SensorConnections extends React.PureComponent<Props, any> {
  @observable tab: number = 1;

  data = [
    {
      name: "Series 1",
      data: [
        {
          x: new Date("2023-01-01").getTime(),
          y: 10,
        },
        {
          x: new Date("2023-01-02").getTime(),
          y: 15,
        },
        {
          x: new Date("2023-01-03").getTime(),
          y: 8,
        },
        // Add more data points for Series 1
      ],
    },
  ];

  options = {
    series: this.data,
    chart: {
      id: "chart2",
      type: "line",
      height: 230,
      toolbar: {
        autoSelected: "pan",
        show: false,
      },
    },
    colors: ["#546E7A"],
    stroke: {
      width: 3,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 1,
    },
    markers: {
      size: 0,
    },
    xaxis: {
      type: "datetime",
    },
  };

  // Options for the area chart
  optionsLine = {
    series: this.data,
    chart: {
      id: "chart1",
      height: 130,
      type: "area",

      selection: {
        enabled: true,
        xaxis: {
          min: new Date("19 Jun 2017").getTime(),
          max: new Date("14 Aug 2017").getTime(),
        },
      },
    },
    colors: ["#008FFB"],
    fill: {
      type: "gradient",
      gradient: {
        opacityFrom: 0.91,
        opacityTo: 0.1,
      },
    },
    xaxis: {
      type: "datetime",
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      tickAmount: 2,
    },
  };

  componentDidMount = async () => {};

  getMessagesById = async (track_number: string) => {
    try {
      let messages_data = await axios.get(
        `${process.env.REACT_APP_API_URL}/flow_messages/${track_number}/last-messages`
      );
    } catch (error: any) {
      console.error(error);
    }
  };

  render() {
    return (
      <ReadDataStyled>
        {" "}
        <div className="content-page">
          <div className="title-2">{t("sensors_statuses")}</div>
          <div className="flex gap40 mobile-col">
            <div>
              {/* Line Chart */}
              {/* <ReactApexChart
                options={this.options as ApexOptions}
                series={this.options.series}
                type="line"
                height={230}
              /> */}

              {/* Area Chart */}
              <ReactApexChart
                options={this.optionsLine as ApexOptions}
                series={this.optionsLine.series}
                type="area"
                height={130}
              />
            </div>
          </div>
        </div>
      </ReadDataStyled>
    );
  }
}
export default withRouter(SensorConnections);
