import { Button, Collapse, List, Menu } from "antd";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import React from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { SidebarStyled } from "./styled";
import betonIcon from "../../../../assets/beton.svg";
import homeIcon from "../../../../assets/home.svg";
import mixer from "../../../../assets/concrete-mixer.svg";

import reportIcon from "../../../../assets/report.svg";
import menu_arrowIcon from "../../../../assets/menu_arrow.svg";
import waterJugs from "../../../../assets/water1.png";
import logo from "../../../../assets/logo.svg";
import { t } from "i18next";
import AuthStore from "../../../../stores/auth/auth.store";
import { inject, observer } from "mobx-react";
import { observable } from "mobx";
import Header from "../../../../components/Header/Header";
import HeaderMobile from "../../../../components/Header/HeaderMobile";

interface Props extends RouteComponentProps<any> {
  closeBar(): void;
}

@observer
class Sidebar extends React.PureComponent<Props, any> {
  @observable user: any = {};
  @observable showMenu: boolean = false;

  componentDidMount = async () => {
    try {
    } catch (error) {
      console.error(error);
    }
  };

  getPath = () => {
    return this.props.location.pathname;
  };

  getUserRoleInfo = () => {
    const storedUserJSON = localStorage.getItem("b_user");

    if (!storedUserJSON) {
      // If the user is not logged in, go to the login page
      this.props.history.push("/login");
      return;
    }

    // Parse the JSON string into an object
    const user = JSON.parse(storedUserJSON);
    return user.role_info;
  };

  changeView = () => {
    console.log(22);
    this.showMenu = !this.showMenu;
    console.log(this.showMenu);
  };

  render() {
    return (
      <SidebarStyled className="">
        <div className="desktop">
          <HeaderMobile display={() => this.changeView()} />
        </div>

        <div className={"sidebar " + (this.showMenu ? "show" : "")}>
          <ReactSVG className="logo" src={logo} />
          <div className="menu">
            <div className="menu-items">
              <div className="flex menu-title">
                <ReactSVG src={homeIcon} />
                <label htmlFor="">{t("realTimeManagement")}</label>
              </div>
              <div className="sub-menu">
                <Link
                  onClick={() => (this.showMenu = false)}
                  className={`flex ${
                    this.getPath() === "/addons" ? "current-path" : ""
                  }`}
                  to="/addons"
                >
                  {t("factoryControl")}
                </Link>

                <Link
                  onClick={() => (this.showMenu = false)}
                  className={`flex ${
                    this.getPath() === "/concrete/view" ? "current-path" : ""
                  }`}
                  to="/concrete/view"
                >
                  {t("mapDisplay")}
                </Link>

               
              </div>

              <div className="flex menu-title">
                <Link
                  onClick={() => (this.showMenu = false)}
                  className={`flex ${
                    this.getPath() === "/concrete/delivery"
                      ? "current-path"
                      : ""
                  }`}
                  to="/concrete/delivery"
                >
                  <ReactSVG src={betonIcon} />
                  <label htmlFor=""> {t("deliveryHistory")}</label>
                </Link>
              </div>

              <div className="flex menu-title">
                <ReactSVG
                  className="svg-big"
                  onClick={() => this.props.history.goBack()}
                  src={mixer}
                />
                <label htmlFor="">{t("managementOf")}</label>
              </div>

              <div className="sub-menu">
                <Link
                  onClick={() => (this.showMenu = false)}
                  className={`flex ${
                    this.getPath() === "/concrete/unusual" ? "current-path" : ""
                  }`}
                  to="/concrete/unusual"
                >
                  {t("exceptions")}
                </Link>
                <Link
                  onClick={() => (this.showMenu = false)}
                  className={`flex ${
                    this.getPath() === "/sensors/status/factories"
                      ? "current-path"
                      : ""
                  }`}
                  to="/sensors/status/factories"
                >
                  {t("trackMixer")}
                </Link>


                <Link
                  onClick={() => (this.showMenu = false)}
                  className={`flex ${
                    this.getPath() === "/concrete/slump" ? "current-path" : ""
                  }`}
                  to="/concrete/slump"
                >
                  {t("certifiedControl")}
                </Link>

                
                <Link
                  onClick={() => (this.showMenu = false)}
                  className={`flex ${
                    this.getPath() === "/sensors/slump/changes"
                      ? "current-path"
                      : ""
                  }`}
                  to="/sensors/slump/changes"
                >
                  {t("mixDesignBehavior")}
                </Link>
                {/* <Link
                 onClick={() => (this.showMenu = false)}
                className={`flex ${
                  this.getPath() === "/sensors/status/mixture_control"
                    ? "current-path"
                    : ""
                }`}
                to="/sensors/status/mixture_control"
              >
                {t("Mixture Control")}
              </Link>
              <Link
               onClick={() => (this.showMenu = false)}
                className={`flex ${
                  this.getPath() === "/sensors/status/raw_materials_changes"
                    ? "current-path"
                    : ""
                }`}
                to="/sensors/status/raw_materials_changes"
              >
                {t("Changes in raw materials")}
              </Link> */}
              </div>

              <div className="flex menu-title">
                <Link
                  onClick={() => (this.showMenu = false)}
                  className={`flex ${
                    this.getPath() === "/settings/unusual" ? "current-path" : ""
                  }`}
                  to="/settings/unusual"
                >
                  <ReactSVG
                    onClick={() => this.props.history.goBack()}
                    src={reportIcon}
                  />
                  <label htmlFor=""> {t("settings")}</label>
                </Link>
              </div>

              {/* <div className="flex menu-title">
              <ReactSVG
                onClick={() => this.props.history.goBack()}
                src={reportIcon}
              />
              <label htmlFor=""> {t("reports")}</label>
            </div>

            <div className="sub-menu">
              <Link
               onClick={() => (this.showMenu = false)}
                className={`flex ${
                  this.getPath() === "/reports" ? "current-path" : ""
                }`}
                to="/reports"
              >
                <div> {t("reports")}</div>
              </Link>
            </div> */}

              {this.getUserRoleInfo() === "admin" && (
                <>
                  <div className="flex menu-title">
                    <ReactSVG src={menu_arrowIcon} />
                    <label htmlFor=""> Conext</label>
                  </div>
                  <div className="sub-menu">
                    <Link
                      onClick={() => (this.showMenu = false)}
                      className={`flex ${
                        this.getPath() === "/sensors/status"
                          ? "current-path"
                          : ""
                      }`}
                      to="/sensors/status"
                    >
                      {t("sensorsStatus")}{" "}
                    </Link>

                    <Link
                      onClick={() => (this.showMenu = false)}
                      className={`flex ${
                        this.getPath() === "/sensors/sync" ? "current-path" : ""
                      }`}
                      to="/sensors/sync"
                    >
                      {t("sync")}{" "}
                    </Link>

                    {/* <Link
                className={`flex ${
                  this.getPath() === "/sensors/connections"
                    ? "current-path"
                    : ""
                }`}
                to="/sensors/connections"
              >
                תקשורת
              </Link> */}
                  </div>
                </>
              )}
            </div>
            {/* <div className="help-number flex">
            <div>לשאלות ובירורים:</div>
            <div className="bold">052-280-6589</div>
          </div> */}

            <div className="fotter-data">
              <Header />

              <svg
                onClick={this.props.closeBar}
                className="clos-bar"
                width="50"
                height="51"
                viewBox="0 0 50 51"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  y="0.584961"
                  width="50"
                  height="50"
                  rx="5"
                  fill="#FF6700"
                />
                <path
                  d="M28.3334 18.9185L21.6667 25.5851L28.3334 32.2518"
                  stroke="#2D2D2D"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
      </SidebarStyled>
    );
  }
}
export default withRouter(Sidebar);
