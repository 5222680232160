import moment from "moment";
import { observable } from "mobx";
import { inject, observer } from "mobx-react";
import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Messages from "./Messages/Messages";
import SensorsData from "./SensorsData/SensorsData";
import Status from "./Status/Status";
import { ReadDataStyled } from "./styled";
import { t } from "i18next";
import TopFilter from "../common/TopFilter/TopFilter";
import parseObjectToUrlParams from "../../../utils/parseObjectToUrlParams";
import AuthStore from "../../../stores/auth/auth.store";
const axios = require("axios");

interface Props extends RouteComponentProps<any> {
  authStore?: AuthStore;
}

@inject("authStore")
@observer
class ReadDataFactories extends React.PureComponent<Props, any> {
  @observable tab: number = 1;
  @observable current_time: any | null = null;
  @observable messages: any = [];
  @observable sensors_data: any = [];
  @observable active_sensors: number = 0;
  @observable test_sensors: number = 0;
  @observable sensor_number: string;
  @observable urlParams: Record<string, any> = { sensor_type: 1 };

  updaterIntervalId: any;

  componentDidMount = async () => {
    try {
      var c = this;
      this.updaterIntervalId = setInterval(function async() {
        c.getSensorsData();
      }, 5000);
    } catch (error) {
      console.error(error);
    }
  };

  getSensorsData = async () => {
    try {
      const group_id = this.props!.authStore!.getUserGroipID();
      this.urlParams.group_id = group_id;
      let sensors_data = await axios.get(
        `${process.env.REACT_APP_API_URL}/sensors?` +
          parseObjectToUrlParams(this.urlParams)
      );
      this.sensors_data = sensors_data.data.sensors_data;
      this.active_sensors = sensors_data.data.active_sensors;
      this.test_sensors = sensors_data.data.test_sensores;
    } catch (error) {
      console.error(error);
    }
  };

  componentWillUnmount(): void {
    clearInterval(this.updaterIntervalId);
  }

  render() {
    return (
      <ReadDataStyled>
        <div className="p-5 flex flex-col">
          <div className="text-lg font-bold py-5">{t("trackMixer")}</div>
          <SensorsData
            factory_preview={true}
            sensors_data={this.sensors_data}
          />
        </div>
      </ReadDataStyled>
    );
  }
}
export default withRouter(ReadDataFactories);
 