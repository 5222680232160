import {
  AreaChartOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  DashboardOutlined,
  DotChartOutlined,
  ExperimentOutlined,
  SettingOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { Input, message } from "antd";
import axios from "axios";
import { t } from "i18next";
import { observable } from "mobx";
import { inject, observer } from "mobx-react";
import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import parseObjectToUrlParams from "../../../../utils/parseObjectToUrlParams";
import TopFilter from "../../common/TopFilter/TopFilter";
import ConcreteDelivery from "../ConcreteDelivery/ConcreteDelivery";
import { ConcreteUnusualStyled } from "./styled";
import AuthStore from "../../../../stores/auth/auth.store";
interface Props extends RouteComponentProps<any> {
  authStore?: AuthStore;
}

@inject("authStore")
@observer
class ConcreteUnusualMain extends React.PureComponent<Props, any> {
  @observable active_mixture_code: number = 0;
  @observable active_mixture: any = {};
  @observable deliveries: any = {};
  @observable show_signle_repors: boolean = false;
  @observable urlParams: Record<string, any> = {};
  @observable data = [
    {
      name: t("GapInThreshold"),
      number: "54",
      prefix: t("deliveries"),
      icon: (
        <svg
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M28 28H3C2.44772 28 2 27.5523 2 27V2M7 23L26 4M26 4V10M26 4H20"
            stroke="#FF6700"
            stroke-width="2"
            stroke-linecap="round"
          />
        </svg>
      ),
      showSettings: true,
      setting_name: t("GapInThresholdForAbnormal"),
      setting_key: "max_slump_change",
      setting_prefix: t("mm"),
      nameData: "slump_count",
      input: {},
    },

    {
      name: t("Above120Minutes"),
      number: "67",
      prefix: t("deliveries"),
      icon: (
        <svg
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 6C16 5.44772 15.5523 5 15 5C14.4477 5 14 5.44772 14 6H16ZM15 15H14C14 15.2652 14.1054 15.5196 14.2929 15.7071L15 15ZM20.2929 21.7071C20.6834 22.0976 21.3166 22.0976 21.7071 21.7071C22.0976 21.3166 22.0976 20.6834 21.7071 20.2929L20.2929 21.7071ZM27 15C27 21.6274 21.6274 27 15 27V29C22.732 29 29 22.732 29 15H27ZM15 27C8.37258 27 3 21.6274 3 15H1C1 22.732 7.26801 29 15 29V27ZM3 15C3 8.37258 8.37258 3 15 3V1C7.26801 1 1 7.26801 1 15H3ZM15 3C21.6274 3 27 8.37258 27 15H29C29 7.26801 22.732 1 15 1V3ZM14 6V15H16V6H14ZM14.2929 15.7071L20.2929 21.7071L21.7071 20.2929L15.7071 14.2929L14.2929 15.7071Z"
            fill="#FF6700"
          />
        </svg>
      ),
      showSettings: true,
      setting_name: t("TimeToDischarge"),
      setting_key: "max_delivery_time",
      setting_prefix: t("min"),
      nameData: "deliveries_count",
      input: {},
    },

    {
      name: t("UnauthorizedPartialDischarge"),
      number: "23",
      prefix: t("deliveries"),
      icon: (
        <svg
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.70711 8.29289C9.31658 7.90237 8.68342 7.90237 8.29289 8.29289C7.90237 8.68342 7.90237 9.31658 8.29289 9.70711L9.70711 8.29289ZM20.2929 21.7071C20.6834 22.0976 21.3166 22.0976 21.7071 21.7071C22.0976 21.3166 22.0976 20.6834 21.7071 20.2929L20.2929 21.7071ZM21.7071 9.70711C22.0976 9.31658 22.0976 8.68342 21.7071 8.29289C21.3166 7.90237 20.6834 7.90237 20.2929 8.29289L21.7071 9.70711ZM8.29289 20.2929C7.90237 20.6834 7.90237 21.3166 8.29289 21.7071C8.68342 22.0976 9.31658 22.0976 9.70711 21.7071L8.29289 20.2929ZM27 15C27 21.6274 21.6274 27 15 27V29C22.732 29 29 22.732 29 15H27ZM15 27C8.37258 27 3 21.6274 3 15H1C1 22.732 7.26801 29 15 29V27ZM3 15C3 8.37258 8.37258 3 15 3V1C7.26801 1 1 7.26801 1 15H3ZM15 3C21.6274 3 27 8.37258 27 15H29C29 7.26801 22.732 1 15 1V3ZM8.29289 9.70711L14.2929 15.7071L15.7071 14.2929L9.70711 8.29289L8.29289 9.70711ZM14.2929 15.7071L20.2929 21.7071L21.7071 20.2929L15.7071 14.2929L14.2929 15.7071ZM15.7071 15.7071L21.7071 9.70711L20.2929 8.29289L14.2929 14.2929L15.7071 15.7071ZM14.2929 14.2929L8.29289 20.2929L9.70711 21.7071L15.7071 15.7071L14.2929 14.2929Z"
            fill="#FF6700"
          />
        </svg>
      ),
      showSettings: false,
      setting_name: t("AbnormalLoadRange"),
      setting_key: "max_unloading_distance",
      setting_prefix: t("meter"),
      nameData: "noAllowed_count",
      input: {},
    },
    {
      name: t("AbnormalTemperature"),
      number: "18",
      prefix: t("deliveries"),
      icon: (
        <svg
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21 9L15 15M8 27H22M23 15H24M6 15H7M15 6V7M8.5 8.5L9.5 9.5M28 15C28 7.8203 22.1797 2 15 2C7.8203 2 2 7.8203 2 15C2 18.1864 3.14639 21.105 5.04923 23.366C5.39998 23.7827 5.92612 24 6.47084 24C10.8833 24 19.1167 24 23.5292 24C24.0739 24 24.6 23.7827 24.9508 23.366C26.8536 21.105 28 18.1864 28 15Z"
            stroke="#FF6700"
            stroke-width="2"
            stroke-linecap="round"
          />
        </svg>
      ),
      showSettings: true,
      setting_name: t("AbnormalTemperature"),
      setting_key: "max_temperature",
      setting_prefix: t("degrees"),
      nameData: "temp_count",
      input: {},
    },
    {
      name: t("WaterAdditionsOnSite"),
      number: "23",
      prefix: t("deliveries"),
      icon: (
        <svg
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.99"
            d="M5.80821 23C5.3678 24.4615 4.77337 26.4341 4.3884 27.7117C4.19498 28.3535 4.67556 29 5.34593 29H24.6544C25.3248 29 25.8056 28.3543 25.6122 27.7124C25.0092 25.7114 23.829 21.7949 22.9868 19M5.80821 23C7.66642 16.8335 10.503 7.42034 10.8417 6.29617C10.8642 6.22155 10.8775 6.14588 10.8821 6.06808L10.9393 5.10185C10.9723 4.54433 10.5291 4.07407 9.9706 4.07407C9.43465 4.07407 9.00017 3.6396 9.00017 3.10365V2C9.00017 1.44772 9.44789 1 10.0002 1H20.0002C20.5525 1 21.0002 1.44772 21.0002 2V3.10365C21.0002 3.6396 20.5657 4.07407 20.0298 4.07407C19.4713 4.07407 19.028 4.54433 19.061 5.10185L19.1182 6.06808C19.1228 6.14588 19.1355 6.2194 19.158 6.29402C19.4471 7.25345 21.5832 14.3421 22.9868 19M5.80821 23C12.1737 23 16.7595 19 22.9868 19M16.0002 15C16.0002 16.1046 15.1047 17 14.0002 17C12.8956 17 12.0002 16.1046 12.0002 15C12.0002 13.8954 12.8956 13 14.0002 13C15.1047 13 16.0002 13.8954 16.0002 15Z"
            stroke="#FF6700"
            stroke-width="2"
          />
        </svg>
      ),
      showSettings: false,
      setting_name: t("DriverAddition"),
      setting_key: "max_unloading_distance",
      // Add additional translations as needed
      setting_prefix: t(""),
      nameData: "water_count",
      input: {},
    },
    {
      name: t("DriversAddingWater"),
      number: "13",
      prefix: t("Drivers"),
      icon: (
        <svg
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 10C15 12.7614 12.7614 15 10 15C7.23858 15 5 12.7614 5 10C5 7.23858 7.23858 5 10 5C12.7614 5 15 7.23858 15 10Z"
            stroke="#FF6700"
            stroke-width="2"
          />
          <path
            d="M19 25C19 20.0294 14.9706 16 10 16C5.02944 16 1 20.0294 1 25M20 15H24M28 15H24M24 15V11M24 15V19"
            stroke="#FF6700"
            stroke-width="2"
            stroke-linecap="round"
          />
        </svg>
      ),
      showSettings: false,
      setting_name: t("WaterAdditionsOnSurface"),
      setting_key: t("AbnormalTemperature"),
      setting_prefix: t("liters"),
      nameData: "drivers_count",
      input: {},
    },
  ];

  @observable generalData: any = {
    total_count: 0,
    slump_count: 0,
    deliveries_count: 0,
    noAllowed_count: 0,
    temp_count: 0,
    water_count: 0,
    drivers_count: 0,
  };

  @observable settingData: any = {
    slump_count: 15,
    deliveries_count: 90,
    noAllowed_count: 1,
    temp_count: 28,
  };

  @observable factory_id: any;
  @observable dateRange: any = [];

  componentDidMount = async () => {
    this.createObjectInLocalStorageIfNotExists();
    this.getData();
  };

  getData = async () => {
    this.urlParams.settingData = this.settingData;
    const group_id = this.props!.authStore!.getUserGroipID();

    let rsp = await axios.get(
      `${process.env.REACT_APP_API_URL}/calculate/general?group_id=${group_id}&` +
        parseObjectToUrlParams(this.urlParams)
    );

    this.generalData = rsp.data.data;
    console.log({ data: this.generalData });
  };

  getSingalDelivires = async (report: string) => {
    this.show_signle_repors = true;
    this.urlParams.settingData = this.settingData;
    this.urlParams.report = report;
    let rsp = await axios.get(
      `${process.env.REACT_APP_API_URL}/calculate/general/deliveris?` +
        parseObjectToUrlParams(this.urlParams)
    );

    this.deliveries = rsp.data.data;
  };

  createObjectInLocalStorageIfNotExists() {
    if (!localStorage.getItem("deliveryData")) {
      localStorage.setItem("deliveryData", JSON.stringify(this.settingData));
    } else {
      console.log({ data: JSON.parse(localStorage.getItem("deliveryData")) });
      this.settingData = JSON.parse(localStorage.getItem("deliveryData"));
    }
  }

  updateObjectValue(key: string, value: any) {
    const existingData = JSON.parse(localStorage.getItem("deliveryData")) || {};
    existingData[key] = value;
    localStorage.setItem("deliveryData", JSON.stringify(existingData));
    this.settingData = JSON.parse(localStorage.getItem("deliveryData"));
  }

  // this.settingsActive = !this.settingsActive

  onFilterChange = async (filterName: string, filterValue: any) => {
    switch (filterName) {
      case "date":
        this.dateRange = filterValue;
        break;

      case "factory_id":
        this.factory_id = filterValue;
        break;
    }

    if (this.dateRange.length > 1) {
      this.urlParams.date = {
        start: this.dateRange[0],
        end: this.dateRange[1],
      };
    }
    if (this.factory_id) {
      this.urlParams.factory_id = this.factory_id;
    }

    if (filterName === "sensor_id") {
      this.urlParams.sensor_id = filterValue;
    }

    if (filterName === "loading_concreate_type") {
      this.urlParams.mixture_code = filterValue.key;
      this.active_mixture_code = filterValue.key;
    }
    if (filterName === "loading_concreate_tree") {
      if (filterValue === "all") {
        this.urlParams.mixture_code = null;
        this.active_mixture_code = null;
      }
      const { type, value } = filterValue;
      if (type === "family") {
      }
      if (type === "mixture") {
        this.urlParams.mixture_code = value;
        this.active_mixture_code = value;
      }
      if (type === "product") {
      }
    }

    this.urlParams.settingData = this.settingData;

    console.log(this.urlParams);

    try {
      let rsp = await axios.get(
        `${process.env.REACT_APP_API_URL}/calculate/general?` +
          parseObjectToUrlParams(this.urlParams)
      );

      this.generalData = rsp.data.data;
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    console.log({ active_mixture: this.active_mixture["max_temperature"] });
    return (
      <ConcreteUnusualStyled>
        <div className="container">
          {!this.show_signle_repors ? (
            <>
              <div className="flex page-title gap20">
                <TopFilter
                  class={"concreate-filter"}
                  background={false}
                  filters={[
                    "sensor",
                    "date",
                    // "project",
                    "factory",
                    "loading_concreate_tree",
                  ]}
                  changeTrackNumber={(track_number) =>
                    this.onFilterChange("sensor_id", track_number)
                  }
                  onFilterChange={this.onFilterChange}
                />
              </div>

              <div className="title">
                {t("Total")} {this.generalData.total_count} {t("deliveries")}{" "}
              </div>
              <div className="flex-wrapper">
                {this.data?.map((item: any) => {
                  return (
                    <div
                      className="flex flexCol item"
                      onClick={() => {
                        if (this.generalData[item.nameData] > 0) {
                          this.getSingalDelivires(item.nameData);
                        }
                      }}
                    >
                      <div className="flex gap10">
                        <div className="icon">{item.icon}</div>

                        <div className="flex flexCol">
                          <div className="title">{item.name}</div>

                          <div className="description">
                            {this.generalData[item.nameData]} {item.prefix}
                            <div className="color-prec">
                              {!isNaN(
                                (this.generalData[item.nameData] /
                                  this.generalData.total_count) *
                                  100
                              )
                                ? (
                                    (this.generalData[item.nameData] /
                                      this.generalData.total_count) *
                                    100
                                  ).toFixed(2) + "%"
                                : ""}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            <ConcreteDelivery
              deliveries={this.deliveries}
              report={true}
              report_name={"מציג מידע ערבלים"}
              goBack={() => (this.show_signle_repors = false)}
            />
          )}
        </div>
      </ConcreteUnusualStyled>
    );
  }
}
export default withRouter(ConcreteUnusualMain);
