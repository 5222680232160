import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { observer } from "mobx-react";
import FluidsPredictionFilter, {
  FluidsFilterField,
} from "./FluidsPredictionFilter";
import CalibrationStyled, { FluidCardStyled } from "./styled";
import { dailyWCData, wcDataEntry } from "./mock";
import { Card, Statistic, Typography } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
const filters: FluidsFilterField[] = ["mixer", "recipe", "timeRange"];
const filterDataArray = (arr: wcDataEntry[], filters: any): wcDataEntry[] => {
  const filteredArr = arr.filter((v) => {
    let filterRow = false;
    if (filters.mixer && parseInt(filters.mixer))
      filterRow = v.mixer !== parseInt(filters.mixer);
    if (filters.recipe) filterRow = filterRow || v.recipe !== filters.recipe;
    if (filters.timeRange) {
      const todayTime = new Date().getTime();
      const dayTime = 1000 * 60 * 60 * 24;
      const weekTime = dayTime * 7;
      if (filters.timeRange === "weekly")
        filterRow = filterRow || v.date.getTime() <= todayTime - weekTime;
      if (filters.timeRange === "daily")
        filterRow = filterRow || v.date.getTime() <= todayTime - dayTime;
      if (filters.timeRange === "daily")
        filterRow = filterRow || v.date.getTime() <= todayTime - dayTime;
    }

    return !filterRow;
  });
  if (filters.timeRange !== "sum" || filteredArr.length === 0) {
    return filteredArr;
  }
  const loadAvg =
    filteredArr.reduce((acc, curr) => acc + curr.wcRratios.load, 0) /
    filteredArr.length;
  const driveAvg =
    filteredArr.reduce((acc, curr) => acc + curr.wcRratios.drive, 0) /
    filteredArr.length;
  const offloadAvg =
    filteredArr.reduce((acc, curr) => acc + curr.wcRratios.offload, 0) /
    filteredArr.length;
  const wcRratios = { load: loadAvg, drive: driveAvg, offload: offloadAvg };
  return [
    {
      date: filteredArr[0].date,
      groupId: 0,
      mixer: filters.mixer || "multi",
      recipe: filters.recipe || "multi",
      wcRratios,
    },
  ];
};
const FluidCard = observer(
  ({ value, title }: { value: number; title?: string }) => {
    return (
      <FluidCardStyled ratio={value}>
        <Card className="fluids-data-card">
          <Statistic
            title={title}
            value={value * 100}
            prefix={"%"}
            precision={0}
            valueRender={(v) => <span>{v}</span>}
          />
        </Card>
      </FluidCardStyled>
    );
  }
);
const ConcreteFluidsRow = observer(({ v }: { v: any }) => {
  return (
    <div className="fluids-data-row">
      <div>{v.groupId}</div>
      <FluidCard title="העמסה" value={v.wcRratios.load} />
      <ArrowLeftOutlined className="arrow-icon" />
      <FluidCard title="נסיעה" value={v.wcRratios.drive} />
      <ArrowLeftOutlined className="arrow-icon" />
      <FluidCard title="פריקה" value={v.wcRratios.offload} />
      <Statistic
        title="ירידה"
        value={v.wcRratios.load * 100 - v.wcRratios.offload * 100}
        prefix="%"
        precision={2}
      />
    </div>
  );
});

const ConcreteFluidsPrediction = observer(() => {
  const [WCData, setWCData] = useState<any[]>();
  const filtersChanged = (
    changedValues: any,
    values: { [str in FluidsFilterField]: string }
  ) => {
    setWCData(filterDataArray(dailyWCData, values));
  };
  useEffect(() => {
    setWCData(filterDataArray(dailyWCData, { timeRange: "daily" }));
  }, []);
  return (
    <CalibrationStyled>
      <FluidsPredictionFilter
        filters={filters}
        onChange={(changedValues: any, values: any) =>
          filtersChanged(changedValues, values)
        }
      />
      <Card>{WCData && WCData.map((v) => <ConcreteFluidsRow v={v} />)}</Card>
      {/* <Typography> חיזוי הבא</Typography> */}
      <div className="fluid-prediction">
        <Statistic title="ספיגות" value={2} prefix="%" />
        <Statistic title="רטיבות" value={4} prefix="%" />
        <Statistic title="איודי" value={6} prefix="%" />
      </div>
    </CalibrationStyled>
  );
});

export default withRouter(ConcreteFluidsPrediction);
