/* eslint-disable @typescript-eslint/no-useless-constructor */
import UsersService from "./user.service";
import User from "./user.model";
import PageStore from "../page.store";

class UsersStore extends PageStore {
  constructor(usersService: UsersService) {
    super(usersService);
  }

  url(): string {
    return "/users";
  }

  get model(): any {
    return User;
  }

  importFromCsv = (users: any[]) => this.apiService.importFromCsv({ users });
}

export default UsersStore;
