import CementStateStyled from "./styled";
import React, { FC, useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Card, List } from "antd";
import axios from "axios";
import moment from "moment";

import SlumpSlider from "../SlumpSlider/SlumpSlider";
import { ArrowsAltOutlined, LoadingOutlined } from "@ant-design/icons";
import { useQuery } from "react-query";
import { t } from "i18next";

type Props = RouteComponentProps<any> & {
  deviceId?: any;
  mode?: string;
  small?: boolean;
  data?: any,
  deleviery_status?: number;
  closeMapPop?: () => void;
};

const getPredictionData = async (deviceId: string | number) => {
  const rsp = await axios.get<number>(
    `${process.env.REACT_APP_API_URL}/iot/getCurrentPediction/${deviceId}`
  );

  return rsp.data;
};

const usePredictionData = (deviceId: any): any => {
  const { data: prediction_data, isLoading: isLoadingPredictionData } =
    useQuery(
      ["current-prediction", deviceId],
      () => getPredictionData(deviceId),
      { refetchInterval: 5000 }
    );

  return { prediction_data, isLoadingPredictionData };
};

const StatisticItem: FC<{
  value?: any;
  title: string;
  prefix?: string;
  isLoading?: boolean;
}> = ({ value, title, prefix = "", isLoading }) => {
  const parsedValue = parseFloat(value).toFixed(2);
  return (
    <List.Item className="">
      <div className="" style={{ fontWeight: 500 }}>
        {title}
      </div>
      {isLoading ? (
        <LoadingOutlined />
      ) : (
        <div className="bold">
          {typeof value === "number" ? prefix + parsedValue : value}
        </div>
      )}
    </List.Item>
  );
};


function MixerSvg(status:any) {
  if(status?.status ===1){
    return (
      <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1" y="1" width="48" height="48" rx="24" fill="#0E9F6E" />
      <rect
        x="1"
        y="1"
        width="48"
        height="48"
        rx="24"
        stroke="white"
        stroke-width="2"
      />
      <g clip-path="url(#clip0_719_2674)">
        <path
          d="M12.0004 19.5957L13.0004 20.7583L14.5004 22.5147L15.5003 23.6834L17.1129 25.5708C17.3941 25.9021 17.7254 26.177 18.1004 26.3896L20.669 27.8708C21.4378 28.3208 22.3128 28.552 23.2066 28.5583C23.3689 28.5583 23.5379 28.5459 23.7002 28.5332L26.5003 28.2583H26.5191C26.6814 28.2458 26.8252 28.1521 26.9065 28.0146L27.5003 26.9834L29.0002 24.3833L29.9065 22.8145C29.9189 22.7959 29.9253 22.7708 29.9314 22.752C29.9878 22.627 29.9878 22.4834 29.9314 22.3645L28.7566 19.7832C28.3191 18.8208 27.5877 18.0147 26.669 17.4896L24.1004 15.9959C23.7254 15.7833 23.3252 15.6334 22.9002 15.5521L14.5878 14.0084C14.3817 13.9708 14.1691 14.0645 14.0629 14.2459L11.5629 18.5772C11.4567 18.7583 11.4816 18.9895 11.619 19.1521L12.0004 19.5957Z"
          fill="white"
        />
        <path
          d="M39.4936 27.9833L35.6626 24.0708C35.2876 23.7207 34.8062 23.5207 34.3001 23.4958H31.5001C31.2252 23.4958 31.0001 23.7207 31.0001 23.9958V29.4958H30.0001V24.6457L27.7689 28.5146C27.525 28.9333 27.0938 29.2145 26.6126 29.2519L26.5501 29.2582L26.5001 29.2646V29.4958H24.1063C24.1063 29.4958 22.6564 29.5331 22.3815 29.4958H15.5002V25.2207L12.2378 21.4082L12.0002 21.127V29.4958H11.5002C11.2878 29.4958 11.094 29.6332 11.0251 29.8332L10.1377 32.4958L10.0251 32.8332C9.97532 32.9833 10.0002 33.152 10.094 33.2831C10.1877 33.4145 10.3376 33.4958 10.5002 33.4958H12.5002C12.5002 34.8768 13.6188 35.9957 15.0002 35.9957C16.3815 35.9957 17.5002 34.8768 17.5002 33.4958H18.5002C18.5002 34.8768 19.6188 35.9957 21.0001 35.9957C22.3815 35.9957 23.5001 34.8768 23.5001 33.4958H32.5C32.5 34.8768 33.6187 35.9957 35 35.9957C36.3814 35.9957 37.5 34.8768 37.5 33.4958H38.5C39.1626 33.5707 39.7812 33.1396 39.9438 32.4958C39.9812 32.3332 40 32.1645 40 31.9958V29.2333C40 28.7707 39.8188 28.3207 39.4936 27.9833ZM15.0002 34.9957C14.1689 34.9957 13.5002 34.327 13.5002 33.4958C13.5002 32.6645 14.1689 31.9958 15.0002 31.9958C15.8315 31.9958 16.5002 32.6645 16.5002 33.4958C16.5002 34.327 15.8315 34.9957 15.0002 34.9957ZM21.0001 34.9957C20.1688 34.9957 19.5001 34.327 19.5001 33.4958C19.5001 32.6645 20.1688 31.9958 21.0001 31.9958C21.8314 31.9958 22.5001 32.6645 22.5001 33.4958C22.5001 34.327 21.8314 34.9957 21.0001 34.9957ZM35 34.9957C34.1687 34.9957 33.5 34.327 33.5 33.4958C33.5 32.6645 34.1687 31.9958 35 31.9958C35.8313 31.9958 36.5 32.6645 36.5 33.4958C36.5 34.327 35.8313 34.9957 35 34.9957ZM34.7063 27.9958C34.3189 27.9958 34 27.677 34 27.2895V24.4958H34.3001C34.5376 24.5207 34.7688 24.6145 34.95 24.7707L38.1062 27.9958H34.7063Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_719_2674">
          <rect
            width="30"
            height="30"
            fill="white"
            transform="translate(10 10)"
          />
        </clipPath>
      </defs>
    </svg>
      );
  }else{
    return (

      <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="48" height="48" rx="24" fill="#FF6700"/>
      <rect x="1" y="1" width="48" height="48" rx="24" stroke="white" stroke-width="2"/>
      <g clip-path="url(#clip0_1685_3786)">
      <path d="M12.0004 19.5958L13.0004 20.7584L14.5004 22.5147L15.5003 23.6834L17.1129 25.5709C17.3941 25.9022 17.7254 26.1771 18.1004 26.3897L20.669 27.8709C21.4378 28.3208 22.3128 28.552 23.2066 28.5584C23.3689 28.5584 23.5379 28.5459 23.7002 28.5332L26.5003 28.2583H26.5191C26.6814 28.2459 26.8252 28.1521 26.9065 28.0147L27.5003 26.9834L29.0002 24.3834L29.9065 22.8145C29.9189 22.796 29.9253 22.7708 29.9314 22.752C29.9878 22.627 29.9878 22.4835 29.9314 22.3646L28.7566 19.7833C28.3191 18.8209 27.5877 18.0148 26.669 17.4896L24.1004 15.996C23.7254 15.7833 23.3252 15.6334 22.9002 15.5521L14.5878 14.0084C14.3817 13.9709 14.1691 14.0646 14.0629 14.246L11.5629 18.5773C11.4567 18.7584 11.4816 18.9896 11.619 19.1522L12.0004 19.5958Z" fill="white"/>
      <path d="M39.4936 27.9833L35.6626 24.0708C35.2876 23.7207 34.8062 23.5207 34.3001 23.4958H31.5001C31.2252 23.4958 31.0001 23.7207 31.0001 23.9958V29.4958H30.0001V24.6457L27.7689 28.5146C27.525 28.9333 27.0938 29.2145 26.6126 29.2519L26.5501 29.2582L26.5001 29.2646V29.4958H24.1063C24.1063 29.4958 22.6564 29.5331 22.3815 29.4958H15.5002V25.2207L12.2378 21.4082L12.0002 21.127V29.4958H11.5002C11.2878 29.4958 11.094 29.6332 11.0251 29.8332L10.1377 32.4958L10.0251 32.8332C9.97532 32.9833 10.0002 33.152 10.094 33.2831C10.1877 33.4145 10.3376 33.4958 10.5002 33.4958H12.5002C12.5002 34.8768 13.6188 35.9957 15.0002 35.9957C16.3815 35.9957 17.5002 34.8768 17.5002 33.4958H18.5002C18.5002 34.8768 19.6188 35.9957 21.0001 35.9957C22.3815 35.9957 23.5001 34.8768 23.5001 33.4958H32.5C32.5 34.8768 33.6187 35.9957 35 35.9957C36.3814 35.9957 37.5 34.8768 37.5 33.4958H38.5C39.1626 33.5707 39.7812 33.1396 39.9438 32.4958C39.9812 32.3332 40 32.1645 40 31.9958V29.2333C40 28.7707 39.8188 28.3207 39.4936 27.9833ZM15.0002 34.9957C14.1689 34.9957 13.5002 34.327 13.5002 33.4958C13.5002 32.6645 14.1689 31.9958 15.0002 31.9958C15.8315 31.9958 16.5002 32.6645 16.5002 33.4958C16.5002 34.327 15.8315 34.9957 15.0002 34.9957ZM21.0001 34.9957C20.1688 34.9957 19.5001 34.327 19.5001 33.4958C19.5001 32.6645 20.1688 31.9958 21.0001 31.9958C21.8314 31.9958 22.5001 32.6645 22.5001 33.4958C22.5001 34.327 21.8314 34.9957 21.0001 34.9957ZM35 34.9957C34.1687 34.9957 33.5 34.327 33.5 33.4958C33.5 32.6645 34.1687 31.9958 35 31.9958C35.8313 31.9958 36.5 32.6645 36.5 33.4958C36.5 34.327 35.8313 34.9957 35 34.9957ZM34.7063 27.9958C34.3189 27.9958 34 27.677 34 27.2895V24.4958H34.3001C34.5376 24.5207 34.7688 24.6145 34.95 24.7707L38.1062 27.9958H34.7063Z" fill="white"/>
      </g>
      <defs>
      <clipPath id="clip0_1685_3786">
      <rect width="30" height="30" fill="white" transform="translate(10 10)"/>
      </clipPath>
      </defs>
      </svg>
    
    )
  }
}

const CementStateFC = ({ deviceId, data, mode, small, deleviery_status, closeMapPop }: Props) => {
  const { prediction_data, isLoadingPredictionData } =
    usePredictionData(deviceId);
  const sensor_with_concrete =  deleviery_status  === 1? true :false;
console.log({data})
  return (
    <CementStateStyled
      rpmRate={60 / data?.rotation + "s"}
      className={small ? "" : "small"}
    >
      <div className="content-page pop-bar">
        <Card className="flex flexRow justify-between card-mixer-info">
          <div className="topcard">
            <MixerSvg status={deleviery_status} />
            <div className="bold top-title flex1">
              {t("sensor")}: {deviceId}
            </div>

            <div className="close-pop" onClick={closeMapPop}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.34302 4.34314L15.6567 15.6568"
                  stroke="#FF6700"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M4.34302 15.6569L15.6567 4.34321"
                  stroke="#FF6700"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
          <List>
            {sensor_with_concrete && (
              <>
                <StatisticItem
                  title={t("status")}
                  value={
                    data?.content
                      ? data?.content.toFixed(1) + " " + t("kob")
                      : t("empty")
                  }
                />

                <StatisticItem
                  title={t("uniformConcrete")}
                  value={t("yes")}
                />

                <StatisticItem
                  title={t("slump")}
                  value={prediction_data?.slump_avg + " " + t("mm")}
                  // value={153 + " " + t("mm")}
                />

                <List.Item>
                {/* <SlumpSlider slumpRate={150} /> */}
                  <SlumpSlider slumpRate={prediction_data?.slump_avg} />
                </List.Item>
              </>
            )}

            <StatisticItem
              title={t("temperature")}
              value={
                data?.lastRecord?.temp ? data?.lastRecord?.temp.toFixed(1) : "-"
              }
              prefix=" ℃"
            />

            <StatisticItem
              title={t("rpm")}
              value={
                data?.lastRecord?.rotation ?  data?.lastRecord?.rotation.toFixed(1) : "-"
              }
            />

            <List.Item className="flex datetime">
              <div style={{ fontWeight: 500 }}>{t("lastUpdated")}</div>
              <div className="bold">
                {` ${moment(data?.updatedAt).format(
                  "HH:mm DD/MM/YY"
                )} `}
              </div>
            </List.Item>
          </List>
        </Card>
      </div>
    </CementStateStyled>
  );
};



export default withRouter(CementStateFC);
