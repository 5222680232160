import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { Button, Card, Col, Row, Statistic, Table } from "antd";
import { observable } from "mobx";
import { observer } from "mobx-react";
import moment from "moment";
import React from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import ChartBars from "../../../../components/ChartBars/ChartBars";
import SlumpSlider from "../../../../components/SlumpSlider/SlumpSlider";
import TopFilter from "../../common/TopFilter/TopFilter";

import Charts1 from "./Charts/Charts1";
import { FactoryDisplayStyled } from "./styled";
import { t } from "i18next";

interface Props extends RouteComponentProps<any> {}

@observer
class FactoryDisplay extends React.PureComponent<Props, any> {
  @observable sensor_number: string;
  @observable concrete_data: any[] = [];

  columns = [
    { title: "מזהה", dataIndex: "id" },
    { title: "יחס מים צמנט", dataIndex: "wcRatio" },
    { title: "סומך", dataIndex: "slump" },
    { title: "יתרת מים", dataIndex: "remnantWater" },
  ];

  columns_events = [
    { title: "אחוז ספיגות אגריגטים", dataIndex: "absorptivity" },
    { title: "אחוז רטיבות אגריגטים", dataIndex: "wetness" },
    { title: "איבוד מים בערבול ראשון", dataIndex: "water_lost" },
    { title: "איבוד מים ממוצע ל10 דק נסיעה", dataIndex: "water_lost_per_10" },
  ];

  data_events = [
    {
      absorptivity: 50,
      wetness: 30,
      water_lost: 10,
      water_lost_per_10: 10,
    },
  ];

  onFilterChange = async (filterName: string, filterValue: any) => {
    console.log(filterName);
  };

  getConcereteDataFiltered = async (track_number: string) => {
    try {
      this.sensor_number = track_number;
      // console.log(this.dateRange);

      // let concrete_data = await axios.get(
      //   `${process.env.REACT_APP_API_URL}/concrete?` +
      //     parseObjectToUrlParams({
      //       sensor_id: track_number,
      //       date: this.dateRange,
      //     })
      // );

      // this.concrete_data = concrete_data.data.rows;
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    return (
      <FactoryDisplayStyled>
        <div className="title">{t("retivotAndSfigot")}</div>
        <TopFilter
          class={"concreate-filter"}
          background={false}
          filters={["factory", "date"]}
          changeTrackNumber={(track_number) =>
            this.getConcereteDataFiltered(track_number)
          }
          onFilterChange={this.onFilterChange}
        />

        <div className="box margin40">
          <div className="flex wrapper gap20">
            <div className="box">
              <div className="title"> אחוז ספיגות אגריגטים</div>
              <div className="d_value green">5%</div>
            </div>

            <div className="box">
              <div className="title">אחוז רטיבות אגריגטים</div>
              <div className="d_value red">0%</div>
            </div>

            <div className="box">
              <div className="title">איבוד מים בערבול ראשון</div>
              <div className="d_value red">8%</div>
            </div>

            <div className="box">
              <div className="title">איבוד מים ממוצע ל10 דק נסיעה</div>
              <div className="d_value green">3%</div>
            </div>
          </div>

          <div className=" wrapper2 gap20">
            <div className="flex justify-between">
              <div className=" gap20 display_values">
                <div className="flex">
                  <Col span={12}>
                    <Card bordered={false}>
                      <Statistic
                        title="טווח שינוי רמת שקיעה "
                        value={11.28}
                        precision={2}
                        valueStyle={{ color: "#3f8600" }}
                        prefix={<ArrowUpOutlined />}
                        suffix="%"
                      />
                    </Card>
                  </Col>
                  <Col span={12}>
                    <Card bordered={false}>
                      <Statistic
                        title="שינוי תכולת מים טווח"
                        value={9.3}
                        precision={2}
                        valueStyle={{ color: "#cf1322" }}
                        prefix={<ArrowDownOutlined />}
                        suffix="%"
                      />
                    </Card>
                  </Col>
                </div>
              </div>
            </div>
          </div>

          <div className=" marginTop40">
            <h2>{t("history")} </h2>
            <div className="chart-events">
              <ChartBars />
            </div>
          </div>
        </div>
      </FactoryDisplayStyled>
    );
  }
}
export default withRouter(FactoryDisplay);
