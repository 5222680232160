import { observable } from "mobx";
import { observer } from "mobx-react";

import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { StatusStyled } from "./styled";
import Tag from "./Tag/Tag";
import { t } from "i18next";
import { Tooltip } from "antd";

interface Props extends RouteComponentProps<any> {
  current_time: any;
  changeTabs: (tab_number: number) => void;
  changeSensorsType: (type: number) => void;
  data: any;
  active_sensors: number;
  test_sensors: number;
  tab_number: number;
}

@observer
class Status extends React.PureComponent<Props, any> {
  @observable
  number_of_sensors: number = 0;
  total_water: number = 0;
  total_mosaf: number = 0;
  total_water_hand: number = 0;
  total_mosaf_hand: number = 0;

  currect_sensors: number = 0;
  error_sensors: number = 0;

  isTimestampWithin30Minutes(timestamp: number) {
    const currentTimeMilliseconds = new Date().getTime();
    const differenceInMinutes =
      (currentTimeMilliseconds - timestamp) / (1000 * 60);
    return differenceInMinutes <= 30 && differenceInMinutes > 0;
  }

  render() {
    console.log({ data: this.props.data });

    this.currect_sensors = this.props.data.filter(
      (sensor: any) =>
        sensor.factory_id !== 3 &&
        this.isTimestampWithin30Minutes(sensor.last_android_connection)
    ).length;

    this.error_sensors = this.props.data.filter(
      (sensor: any) =>
        sensor.factory_id !== 3 &&
        !this.isTimestampWithin30Minutes(sensor.last_android_connection)
    ).length;

    return (
      <StatusStyled>
        <div className="flex flexCol">
          {/* <div className="btns flex gap10">
            <button onClick={() => this.props.changeTabs(1)} className="">
              {" "}
              {t("messagesHistory")}{" "}
            </button>
            <button onClick={() => this.props.changeTabs(2)} className="">
              {t("sensorsStatus")}
            </button>
          </div> */}

          {this.props.tab_number === 2 ? (
            <div className="flex gap10 marginTop20 loader">
              <div className="title">ערבל מחובר</div>
              <img
                src={require("../../../../assets/loader.gif")}
                alt="loader"
              />
            </div>
          ) : (
            <div className="flex flexCol gap10 marginTop20">
              <div
                className="flex pointer"
                onClick={() => this.props.changeSensorsType(1)}
              >
                <div className=""> {t("activeSensors")} :</div>
                <div className="value"> {this.props.active_sensors}</div>
              </div>

              <div
                className="flex pointer"
                onClick={() => this.props.changeSensorsType(2)}
              >
                <div className=""> {t("sensorsInTestMode")}:</div>
                <div className="value"> {this.props.test_sensors}</div>
              </div>
            </div>
          )}
        </div>
        <div className="status flex">
          <div className="flex1">{t("lastUpdated")}</div>
          <div className="bold">{this.props.current_time}</div>
        </div>
      </StatusStyled>
    );
  }
}
export default withRouter(Status);
