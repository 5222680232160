import styled from "styled-components";

const HeaderStyled = styled.div`
  top: 0;
  direction: ltr;
  margin: 0 auto;
  width: 100%;
  z-index: 999;
  display: block;

  padding: 0px 16px;
  .header-mobile {
    padding: 10px 5px;
    justify-content: space-between;
    svg {
      height: auto !important;
      width: auto !important;
    }
  }

  .ant-dropdown-trigger.flex {
    background: #f9f9fb;
    width: fit-content;
    padding: 8px;
    border-left: 1px solid #e9e9ef !important;
    border-right: 1px solid #e9e9ef !important;
    line-height: 2.5;
  }
  .ant-avatar-circle {
    margin-left: 11px;
  }

  .factory-name {
    border: #ffdead;
    color: #fff;
    background: transparent;
    color: #3a2626;
    font-size: 15px;
    border-radius: 7px;
    cursor: pointer;
    height: 34px;
    margin: 10px;
    border-top: 1px solid;
    border-radius: 0 !important;
    padding: 10px;
  }

  .show-example {
    font-size: 17px;
    line-height: 2.8;
    cursor: pointer;
    direction: rtl;
  }
  .logo {
    color: black;
    font-size: 22px;
    font-weight: bold;
    margin-left: 20px 40px;
  }

  .sub-menu {
    display: flex;
    gap: 20px;
  }

  .flex.menu-title {
    background: #ff67004d;
    justify-content: end;
  }

  svg {
    width: 20px;
  }
`;

export { HeaderStyled };
