import { action, observable } from "mobx";
import PageStore from "../page.store";
import User from "../users/user.model";
import UsersStore from "../users/user.store";
import AuthService from "./auth.service";

class AuthStore extends PageStore {
  private readonly LOCAL_STORAGE_TOKEN = "s_token";
  private readonly LOCAL_STORAGE_REFRESH_TOKEN = "lkm243o502934l2k3m-R";

  @observable primaryColor: string = "#FFFFFF";
  @observable secondaryColor: string = "#FFFFFF";

  usersStore: any;
  authService: any;

  @observable fetchMeLoading: boolean = false;

  constructor(authService: AuthService, usersStore: UsersStore) {
    super(authService);
    this.usersStore = usersStore;
    this.authService = authService;
  }

  storeUser(user: any): void {
    if (user && !this.user) this.user = user;
  }

  getUserFactoryName(): string {
    return this.user?.factory?.factory_name;
  }

  getUserLogo(): string {
    return this.user?.factory?.logo_url;
  }

  getRoleInfo(): string {
    return this.user?.role_info;
  }

  getUserFactoryID(): string {
    return this.user?.factory?.id;
  }

  getUserGroipID(): string {
    return this.user?.factory?.group_id;
  }

  getUserName(): string {
    return this.user?.first_name + " " + this.user?.last_name;
  }

  getAuthHeaders() {
    const token = this.getToken();

    return token ? { Authorization: "Bearer " + this.getToken() } : {};
  }

  storeToken(token: string): void {
    localStorage.setItem(this.LOCAL_STORAGE_TOKEN, token);
  }

  private getToken(): string | null {
    return localStorage.getItem(this.LOCAL_STORAGE_TOKEN);
  }

  @action clear = () => {
    console.log("clear user");
    this.user = null;
    localStorage.removeItem(this.LOCAL_STORAGE_TOKEN);
    localStorage.removeItem(this.LOCAL_STORAGE_REFRESH_TOKEN);
  };

  url(): string {
    return "/auth";
  }

  get model(): any {
    return User;
  }

  login = (user: any) => {
    return this.authService.login(user);
  };

  getPassword = (phone: string) => {
    return this.authService.getPassword(phone);
  };

  checkPassword = (phone: string, one_time_password: string) => {
    return this.authService.checkPassword(phone, one_time_password);
  };

  @action logout = async () => {
    try {
      await this.authService.logout();
      localStorage.clear();
      this.clear();
    } catch (error) {}
  };
}

export default AuthStore;
