import React, { useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

interface Props {
  data?: any;
}

const ChartBars: React.FC<Props> = (props) => {
  const data = [
    {
      name: "יום ראשון",
      uv: 4000,
      pv: 2400,
      amt: 2400,
      dsp: 2400,
    },
    {
      name: "יום שני",
      uv: 3000,
      pv: 1398,
      amt: 2210,
      dsp: 2400,
    },
    {
      name: "יום שלישי",
      uv: 2000,
      pv: 9800,
      amt: 2290,
      dsp: 2400,
    },
    {
      name: "יום רביעי",
      uv: 2780,
      pv: 3908,
      amt: 2000,
      dsp: 2400,
    },
    {
      name: "יום חמישי",
      uv: 1890,
      pv: 4800,
      amt: 2181,
      dsp: 2400,
    },
    {
      name: "יום שישי",
      uv: 2390,
      pv: 3800,
      amt: 2500,
      dsp: 2400,
    },
    {
      name: "יום שבת",
      uv: 3490,
      pv: 4300,
      amt: 2100,
      dsp: 2400,
    },
  ];
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          dataKey="pv"
          stroke="#962213"
          activeDot={{ r: 8 }}
          name="ספיגות"
        />
        <Line type="monotone" dataKey="uv" stroke="#82ca9d" name="רטיבות" />

        <Line
          type="monotone"
          dataKey="amt"
          stroke="#8caa1e"
          activeDot={{ r: 8 }}
          name="ערבול ראשון"
        />

        <Line
          type="monotone"
          dataKey="dsp"
          stroke="#8884d8"
          activeDot={{ r: 8 }}
          name="איבוד מים ממוצע "
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default ChartBars;
