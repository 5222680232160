import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Divider, List, Spin } from "antd";
import { observable } from "mobx";
import axios from "axios";
import { observer } from "mobx-react";
import ConeLine from "../ConeLine/ConeLine";

interface Props extends RouteComponentProps<any> {
  deviceId?: number | string;
}

const getLatestGroups = async ({
  device,
  skip,
  limit,
}: {
  device: number | string;
  skip: number;
  limit: number;
}) => {
  const rsp = await axios(
    `${process.env.REACT_APP_API_URL}/iot_data/groups/${device}`,
    { params: { limit, skip } }
  );
  return rsp.data.map((v: any) => v.groupId);
};

const getCone = async (groupId: number | string) => {
  const rsp = await axios(
    `${process.env.REACT_APP_API_URL}/iot_data/cone_check/${groupId}`
  );
  return rsp.data;
};

const getPrediction = async (groupId: number | string) => {
  const cone_rsp = await axios(
    `${process.env.REACT_APP_API_URL}/iot/getPedictionBySensor/${groupId}`
  );
  return cone_rsp.data;
};

@observer
class GroupsHistory extends React.PureComponent<Props, any> {
  @observable deviceId: string | number = this.props.deviceId;

  @observable updated: Date;
  @observable cones: any[];
  @observable groups: string[];
  @observable limit: number = 10;
  @observable skip: number = 0;
  updaterIntervalId: any;

  getCones = async () => {
    if (!this.deviceId) return;
    const groups = await getLatestGroups({
      device: this.deviceId,
      skip: this.skip,
      limit: this.limit,
    });
    const cones = await Promise.all(
      groups.slice(0, 5).map(async (groupId: string) => {
        const cone = await getCone(groupId);

        const prediction = await getPrediction(groupId);
        return { ...cone[0], prediction };
      })
    );
    console.log(cones);

    this.cones = cones.filter((v) => v);
  };

  componentDidMount(): void {
    this.getCones();
  }

  render() {
    return (
      <>
        {this.cones &&
        <div className="title">העמסות אחרונות</div>
        }
        {this.cones ?
          this.cones.map((cone, i) => {
            return (

              <>
      
        <List.Item className="flex flexCol">
          
          
                <>
                  <ConeLine cone={cone} />
                  {i !== this.cones.length - 1 && <Divider />}
                </>
            
          
        </List.Item>
        </>
  );
        }) : <Spin className="center_loading" tip="טוען העמסות אחרונות..."></Spin>}
      </>

    );
  }
}

export default withRouter(GroupsHistory);
