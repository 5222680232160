import { Button, Checkbox, InputNumber, Select, Slider, Switch } from "antd";
import { observable } from "mobx";
import { inject, observer } from "mobx-react";
import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { AddItem2Styled } from "./styled";
import { t } from "i18next";
import AuthStore from "../../../../stores/auth/auth.store";
import parseObjectToUrlParams from "../../../../utils/parseObjectToUrlParams";
import axios from "axios";

const { Option } = Select;

interface Props extends RouteComponentProps<any> {
  authStore?: AuthStore;
  step?: number;
  sensorId?: any | null;
  recordId?: number;
  recordStatus?: number;
  slump_live_prediction?: number;
}
@inject("authStore")
@observer
class AddItem2 extends React.PureComponent<Props, any> {
  @observable statusOnOFF: boolean = false;
  @observable litter: number = 0;
  @observable typeAddon: string = "water";
  @observable selectedOption: string = "water";
  @observable maxAddon: number = 200;
  @observable step: number = 1;

  @observable recordId: number = 1;
  @observable recordStatus: number = 0;
  @observable lastRecordId: number = 0;

  @observable refreshRecord: boolean = true;

  // componentDidUpdate(): void {
  //   this.updateLocalTrack(
  //     this.props.sensorId,
  //     this.props.recordId,
  //     this.props.recordStatus
  //   );
  // }

  componentDidMount(): void {
    this.updateLocalTrack(
      this.props.sensorId,
      this.props.recordId,
      this.props.recordStatus
    );

    this.checkSensorStatus(this.props.sensorId);
  }

  componentDidUpdate(): void {
    this.updateLocalTrack(
      this.props.sensorId,
      this.props.recordId,
      this.props.recordStatus
    );

    this.checkSensorStatus(this.props.sensorId);
  }

  onChange = async (value: any) => {
    this.litter = value;
  };

  updateLocalTrack = (sensor_id: number, record_id: number, status: number) => {
    const key = "addons_conext";

    // Set the current record_id and status
    this.recordId = record_id;
    this.recordStatus = status;

    // console.log({
    //   status2: this.recordStatus,
    //   recordId2: this.recordId,
    // });

    if (status === null) {
      return;
    }

    if (status === 10 || status === 12) {
      this.removeRecordFrobTracking(this.recordId, () => {
        this.checkSensorStatus(this.props.sensorId);
      });
      return;
    }
    if (localStorage.getItem(key) && status !== 0) {
      let existingArray = JSON.parse(localStorage.getItem(key));

      // Find the index of the record with the given sensorId
      const index = existingArray.findIndex(
        (record: any) => record.sensorId === sensor_id
      );

      const record_id_index = existingArray.findIndex(
        (record: any) => record.record_id === record_id
      );

      if (record_id_index !== -1) {
        existingArray[record_id_index].status = status;
      } else {
        // Add a new record to the array
        existingArray.push({
          sensorId: sensor_id,
          record_id,
          status,
        });
      }

      console.log({ existingArray });
      localStorage.setItem(key, JSON.stringify(existingArray));
    }
  };

  appendChildToLocalStorage(
    sensorId: number,
    record_id: number,
    status: number
  ) {
    const key = "addons_conext";

    // Retrieve the existing array or initialize it if it doesn't exist
    let existingArray = JSON.parse(localStorage.getItem(key)) || [];

    // Add a new record to the array
    existingArray.push({
      sensorId: sensorId,
      record_id: record_id,
      status: status,
    });

    // Update local storage with the modified array
    console.log({ existingArray2: existingArray });
    localStorage.setItem(key, JSON.stringify(existingArray));
  }

  startAction = async () => {
    let flow_command = `START_FLOW_${this.litter * 100}`;

    let queries = parseObjectToUrlParams({
      flow_command: flow_command,
      sensor_id: this.props.sensorId,
      type: this.typeAddon,
      name: this.props!.authStore!.getUserName(),
      role: this.props!.authStore!.user.role_info,
    });

    let url = `${process.env.REACT_APP_API_URL}/flow_commands/new_command?${queries}`;
    let rsp = await axios.get(url);

    this.appendChildToLocalStorage(this.props.sensorId, rsp.data.id, 1);
  };

  stopAction = async () => {
    // localStorage.removeItem(`${type_name}_status`);

    let flow_command = "STOP_FLOW";

    let type_name = "water";

    let queries = parseObjectToUrlParams({
      flow_command: flow_command,
      sensor_id: this.props.sensorId,
      type: type_name,
      name: this.props!.authStore!.getUserName(),
      role: this.props!.authStore!.user.role_info,
    });

    console.log({ queries });
    let url = `${process.env.REACT_APP_API_URL}/flow_commands/new_command?${queries}`;
    let rsp = await axios.get(url);
    this.removeRecordFrobTrackingBySensorId(this.props.sensorId);
  };

  removeRecordFrobTracking(recordId: number, callback: () => void) {
    // Step 1: Retrieve the data from local storage
    const localStorageData = localStorage.getItem("addons_conext");
    let parsedData = JSON.parse(localStorageData);
    if (!parsedData) {
      return;
    }
    const indexToRemove = parsedData.findIndex(
      (item: any) => item.record_id === recordId
    );

    // Step 4: Remove the object from the array if found
    if (indexToRemove !== -1) {
      parsedData.splice(indexToRemove, 1); // Remove 1 element at the found index
    }
    // Step 5: Update local storage with the modified array
    localStorage.setItem("addons_conext", JSON.stringify(parsedData)); //

    // Call the callback function
    if (typeof callback === "function") {
      callback();
    }
  }

  removeRecordFrobTrackingBySensorId(sensorId: number) {
    // Step 1: Retrieve the data from local storage
    const localStorageData = localStorage.getItem("addons_conext");
    let parsedData = JSON.parse(localStorageData);
    if (!parsedData) {
      return;
    }
    const indexToRemove = parsedData.findIndex(
      (item: any) => item.sensorId === sensorId
    );

    // Step 4: Remove the object from the array if found
    if (indexToRemove !== -1) {
      parsedData.splice(indexToRemove, 1); // Remove 1 element at the found index
    }
    // Step 5: Update local storage with the modified array
    localStorage.setItem("addons_conext", JSON.stringify(parsedData)); //
  }

  checkSensorStatus(sensorId: number) {
    const localStorageData = localStorage.getItem("addons_conext");
    const parsedData = JSON.parse(localStorageData);
    let foundObject = null;

    if (Array.isArray(parsedData)) {
      foundObject = parsedData.find((item) => item.sensorId === sensorId);
    }

    if (foundObject) {
      const { sensorId, record_id, status } = foundObject;
      if (status !== 10 || status !== 0) {
        this.statusOnOFF = false;
      } else {
        this.statusOnOFF = true;
      }
    } else {
      this.statusOnOFF = true;
    }
  }

  onChangeType(checked: boolean) {
    this.litter = 0;
    if (checked) {
      this.typeAddon = "water";
    } else {
      this.typeAddon = "supplement";
    }
  }

  render() {
    // console.log({
    //   status: this.props.recordStatus,
    //   recordId: this.props.recordId,
    // });

    return (
      <AddItem2Styled className="box adding-box">
        <div className="">
          <div className="bold white f18 underline b-orange marginBottom20">
            {t("sensorNumber")}: {this.props.sensorId}
          </div>

          {/* <div className="bold white f18 underline b-orange marginBottom20">
            {this.props.recordId + this.props.recordStatus}
            status: {this.props.recordStatus}
          </div> */}
          {this.statusOnOFF && (
            <div className="flex gap10 full">
              <Switch
                checkedChildren={t("water")}
                unCheckedChildren={t("addon")}
                defaultChecked
                onChange={(checked) => this.onChangeType(checked)}
              />

              <Slider
                min={0}
                className="slider-right"
                max={this.typeAddon === "water" ? 200 : 5}
                step={this.step}
                onChange={this.onChange}
                value={this.litter}
              />

              <InputNumber
                min={0}
                max={this.typeAddon === "water" ? 200 : 5}
                onChange={this.onChange}
                onClick={() => {
                  this.litter = 0;
                }}
                formatter={(value) => `${value}`}
                style={{ marginLeft: 4 }}
                value={this.litter}
              />
              {t("liter")}
            </div>
          )}

          {/* <div className="flex">
            <div className="value flex1">
              <span className="bold">סומך זמן אמת:</span>{" "}
              {this.props.slump_live_prediction}
            </div>
          </div> */}
          <div className="flex full">
            <Button
              type="primary"
              size={"small"}
              htmlType="submit"
              className={
                "br-blue2 start-action radius blue2 transparent full marginTop20 " +
                (this.props.recordStatus === 1 ? "active" : null)
              }
              onClick={() =>
                this.statusOnOFF ? this.startAction() : this.stopAction()
              }
            >
              {this.statusOnOFF ? t("start") : t("stop")}
            </Button>
          </div>
        </div>
      </AddItem2Styled>
    );
  }
}
export default withRouter(AddItem2);
