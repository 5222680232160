import styled from "styled-components";

const MessagesStyled = styled.div`
  width: 100%;
  .sensor_number {
    padding: 0px 14px;
    border-radius: 34px;
    font-size: 13px;
    color: #fff;
  }

  .b1 {
    background: #1d6356;
  }

  td.ant-table-cell {
    text-align: right;
  }
`;

export { MessagesStyled };
