export const placeholder = "";

export interface wcDataEntry {
  groupId: number;
  date: Date;
  mixer: number;
  recipe: "recipe-2" | "recipe-1";
  wcRratios: { load: number; drive: number; offload: number };
}
const createDate = (i: number) => {
  const date = new Date();
  date.setDate(date.getDate() - i * 0.1 + 0.2);
  date.setHours(date.getHours() - i * 0.2);
  return date;
};
const getRandInt = (a: number, b: number) =>
  Math.round(Math.random() * (b - a) + a);

const getRandwcRratios = () => {
  const load = 1 - Math.random() * 0.05;
  const drive = load - Math.random() * 0.05;
  const offload = drive - Math.random() * 0.05;
  return { load, drive, offload };
};
export const dailyWCData: wcDataEntry[] = [...new Array(20)].map((_, i) => {
  return {
    groupId: 123456789 + i * 12,
    date: createDate(i),
    mixer: getRandInt(1, 3),
    recipe: Math.random() > 0.5 ? "recipe-2" : "recipe-1",
    wcRratios: getRandwcRratios(),
  };
});
