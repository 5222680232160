import styled from "styled-components";

const ConcreteTestingStyled = styled.div`
  width: 100%;
  max-width: 350px;
  border-left: 1px solid #c0b6b6;
  border-right: 1px solid #c0b6b6;
  height: 100vh;
  margin: 0 auto;
  position: relative;

  body {
    background-image: url(https://f.cl.ly/items/2K0P3z2y3X3c052f2B3Y/Image%202013.04.30%2021%3A28%3A02.png);
  }
  #traffic-light {
    display: block;
    background-color: #333;
    width: 77px;
    height: 179px;
    border-radius: 30px;
    float: left;
    position: absolute;
    left: 23px;
    top: 20px;
  }

  .wrapp-rpm {
    margin: 20px;
    width: 100%;
  }

  .flex.light-wrapper {
    height: 250px;
  }

  .rpm.flex {
    direction: ltr;
  }

  .name {
    font-size: 20px;
  }

  .number {
    font-size: 20px;
    font-weight: bold;
  }

  button.ant-btn.br-blue2.start-action.radius.blue2.transparent.full.marginTop20 {
    margin-right: 9px;
    margin-top: 93px !important;
  }
  #traffic-light input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: relative;
    width: 31px;
    left: -23px;
    height: 30px;
    margin-top: 20px;
    background-color: grey;
    vertical-align: middle;
    border-radius: 100%;
    display: block;
  }
  input#color1 {
    background-color: #b30000;
  }
  input#color1:hover {
    animation: blink1 1.1s step-end infinite;
  }

  .active1 {
    animation: blink1 1.1s step-end infinite;
  }
  input#color1:checked {
    background-color: #ff0000;
    box-shadow: 0 0 6em #ff3333;
  }
  input#color2 {
    background-color: #b2b300;
  }
  input#color2:hover {
    animation: blink2 1s step-end infinite;
  }

  .active2 {
    animation: blink2 1.1s step-end infinite;
  }
  input#color2:checked {
    background-color: #ffff00;
    box-shadow: 0 0 6em #ffff33;
  }
  input#color3 {
    background-color: #00b300;
  }
  input#color3:hover {
    animation: blink3 1s step-end infinite;
  }

  .active3 {
    animation: blink3 1.1s step-end infinite;
  }
  input#color3:checked {
    background-color: #00ff00;
    box-shadow: 0 0 6em #33ff33;
  }
  @keyframes blink1 {
    0% {
      background-color: #ff0000;
      box-shadow: 0 0 6em #ff3333;
    }
    50% {
      background-color: #b30000;
      box-shadow: 0 0 0em transparent;
    }
  }
  @keyframes blink2 {
    0% {
      background-color: #ffff00;
      box-shadow: 0 0 6em #ffff33;
    }
    50% {
      background-color: #b2b300;
      box-shadow: 0 0 0em transparent;
    }
  }
  @keyframes blink3 {
    0% {
      background-color: #00ff00;
      box-shadow: 0 0 6em #33ff33;
    }
    50% {
      background-color: #00b300;
      box-shadow: 0 0 0em transparent;
    }
  }
  * {
    user-select: none;
    outline: none;
  }

  .form-settings label {
    width: 102px;
  }

  .ant-tabs-tab {
    margin-left: 24px;
  }

  .ant-tabs-nav-list {
    margin: 0 auto;
  }

  .ant-collapse-header div {
    position: absolute;
    left: 1pc;
  }
`;

export { ConcreteTestingStyled };
