import { Button, Checkbox, InputNumber, Slider } from "antd";
import { observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { AddItemStyled } from "./styled";
import { t } from "i18next";

interface Props extends RouteComponentProps<any> {
  name: string;
  min: number;
  type?: string;
  sensor_number?: string;
  max: number;
  maxAlert?: number;
  submitText: string;
  step?: number;
  submitActiveText?: string | null;
  explainText: string;
  active_btn: boolean;
  sensorId?: any | null;
  btnDisabled?: boolean;
  startAction: (type: string, on: boolean, liter: number) => void;
}
@observer
class AddItem extends React.PureComponent<Props, any> {
  @observable litter: number = this.props.min;

  componentDidMount = async () => {
    try {
      console.log(this.props.sensor_number);
    } catch (error) {
      console.error(error);
    }
  };

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<any>,
    snapshot?: any
  ): void {}

  onChange = async (value: any) => {
    this.litter = value;
  };

  render() {
    console.log(this.props.active_btn);
    return (
      <AddItemStyled className="box adding-box">
        <div className="name f24 blue">{this.props.name}</div>

        {this.props.active_btn ? (
          <div className="open_device">
            {this.props.active_btn ? (
              ""
            ) : (
              <img
                src={require("../../../../assets/faucet-leak.gif")}
                alt="leaing faucet"
              />
            )}
          </div>
        ) : null}

        <div className="">
          <div className="16 how-much">{this.props.explainText}</div>
          <div className="flex">
            <Slider
              min={this.props.min}
              max={this.props.max}
              step={this.props.step ? this.props.step : 1}
              onChange={this.onChange}
              value={this.litter}
            />

            <InputNumber
              min={this.props.min}
              max={this.props.max}
              onChange={this.onChange}
              onClick={() => {
                this.litter = 0;
              }}
              formatter={(value) => `${value}`}
              style={{ marginLeft: 4 }}
              value={this.litter}
            />
            {t("liter")}
          </div>

          <div className="flex">
            {this.props.type === "factor" ? (
              <Button
                type="primary"
                size={"small"}
                htmlType="submit"
                className={
                  "br-blue2 start-action radius blue2 transparent full marginTop20 " +
                  (this.props.active_btn &&
                  this.props.submitActiveText !== "בוצע"
                    ? "active"
                    : null)
                }
                onClick={() =>
                  this.props.startAction(
                    this.props.name,
                    !this.props.active_btn,
                    this.litter
                  )
                }
              >
                {this.props.submitText}
              </Button>
            ) : (
              <Button
                type="primary"
                size={"small"}
                disabled={this.props.btnDisabled}
                htmlType="submit"
                className={
                  "br-blue2 start-action radius blue2 transparent full marginTop20 " +
                  (this.props.active_btn &&
                  this.props.submitActiveText !== "בוצע"
                    ? "active"
                    : null)
                }
                onClick={() =>
                  this.props.startAction(
                    this.props.name,
                    !this.props.active_btn,
                    this.litter
                  )
                }
              >
                {!this.props.active_btn
                  ? this.props.submitText
                  : this.props.submitActiveText}
              </Button>
            )}
          </div>
        </div>
      </AddItemStyled>
    );
  }
}
export default withRouter(AddItem);
