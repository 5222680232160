import { ApexOptions } from "apexcharts";
import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import i18n from "../../../../../i18n";

interface ChartOverviewProps {
  series: ApexOptions["series"];
  labels?: ApexOptions["labels"];
  children?: React.ReactNode;
}

const ChartOverview = (props: ChartOverviewProps) => {
  const language = i18n.language;

  
  const calculateWidth = () => {
    const isMobile = window.innerWidth <= 768; // Example threshold for mobile
    return isMobile ? window.innerWidth : window.innerWidth - 780;
  };




  const [width, setWidth] = useState(calculateWidth());


  const options: ApexOptions = {
    series: props.series,
    chart: {
      width: 480,
      stacked: true,
      type: "donut",
    },
    labels: props.labels,
    colors: ["#057A55", "#FACA15", "#FF6700", "#E02424"],
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        customScale: 0.8,
        donut: {
          size: "78%",
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: language ==="he" ?width-100 : width-100,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
    legend: {
      position: "right",
      offsetY: 0,
      height: 430,
    },
  };

  return (
    <div style={{ width: "100%", height: 240 }}>
      <ReactApexChart
        options={options}
        series={props.series}
        type="donut" // Corrected type to "donut"
        height={350}
        
      >
        {props.children}
      </ReactApexChart>
    </div>
  );
};

export default ChartOverview;
