import {
  AutoComplete,
  Button,
  DatePicker,
  Form,
  InputNumber,
  Select,
  Table,
} from "antd";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import TrackNumber from "../Dashboard/TrackNumber/TrackNumber";
import { observable, runInAction } from "mobx";
import { inject, observer } from "mobx-react";
import { WaterAddonsStyled } from "./styled";
import AuthStore from "../../../stores/auth/auth.store";
import { t } from "i18next";
import AddItem from "../Dashboard/AddItem/AddItem";
import AddItem2 from "../Dashboard/AddItem2/AddItem2";
import TrackHistory from "../Dashboard/TrackHistory/TrackHistory";
import parseObjectToUrlParams from "../../../utils/parseObjectToUrlParams";
import moment from "moment";
import "moment/locale/he";
import Icon from "@ant-design/icons";
import i18n from "../../../i18n";
// import { kypros_data } from "../../../helpers/kypros_data";
const axios = require("axios");

const { Option } = Select;
const { RangePicker } = DatePicker;

interface Props extends RouteComponentProps<any> {
  authStore?: AuthStore;
}

@inject("sensorStore", "authStore")
@observer
class WaterAddons extends React.PureComponent<Props, any> {
  @observable sensors: any;
  @observable factoryId: any;
  @observable sensor_number: string = "";
  @observable active_btn_water: boolean = false;
  @observable active_btn_mosaf: boolean = false;
  @observable active_btn_mosaf_water: boolean = false;
  @observable show_bar: boolean = false;
  @observable track_history_data: any = [];
  @observable litter_to_add_display: boolean = false;
  @observable litter_to_add_message: string = "";
  @observable litter_to_add_disable: boolean = true;
  @observable litter_to_add_counter: number = 3;
  @observable litter_to_add_object: any = {};
  @observable disable_supplement: boolean = false;
  @observable disable_water: boolean = false;
  @observable settingData: any = {};

  @observable mixture_data: any = [];

  @observable data: any = [];
  @observable boxData: any = [];
  columns = [
    {
      title: t("sensor"),
      dataIndex: "sensor_id",
    },

    {
      title: t("dateLoading"),
      dataIndex: "date_upload",
      render: (value: string, record: any) => {
        return !value
          ? t("notUpdates")
          : moment(value).format(" HH:mm | DD/MM ");
      },
    },

    {
      title: t("product_name"),
      dataIndex: "product_name",
      render: (value: string, record: any) => {
        return record.product_name ? record.product_name : "-";
      },
    },

    {
      title: t("planning"),
      dataIndex: "orderd",
      key: "slump_at_loading",
      render: (value: string, record: any) => {
        if (record.slump_orderd) {
          return record.slump_orderd ? record.slump_orderd : "-";
        } else if (record.product_name) {
          const product_name = record.product_name?.replace(/\s/g, "");
          const mixture = this.mixture_data?.find(
            (element: any) => element.code === product_name
          );
          return mixture ? mixture.slump : "-";
        }
      },
    },

    {
      title: t("production"),
      dataIndex: "slump_at_loading",
      key: "slump_at_loading",
      render: (value: string, record: any) => {
        return value ? value : "-";
      },
    },

    {
      title: t("supply"),
      dataIndex: "slump_at_unloading1",
      key: "slump_at_unloading1",
      render: (value: string, record: any) => {
        const difference =
          parseInt(record.slump_at_loading, 10) -
          parseInt(record.slump_at_unloading1, 10);
        let className = "";

        if (this.settingData && this.settingData.slump_count !== undefined) {
          className =
            difference > this.settingData.slump_count ? "large-difference" : "";
        } else if (
          this.settingData &&
          this.settingData.slump_count === undefined
        ) {
          // If slump_count is not set
          className = difference > 25 ? "large-difference" : "";
        }
        return <span className={className}>{value ? value : "-"}</span>;
      },
    },

    {
      title: t("DriverAddition"),
      dataIndex: "suspected_water_addition",
      render: (value: string, record: any) => {
        return value ? (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2 10H18"
              stroke="#FF6700"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10 18L10 2"
              stroke="#FF6700"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        ) : (
          "-"
        );
      },
    },

    // {
    //   title: t("FactoryAddition"),
    //   dataIndex: "total_water_factory",
    //   render: (value: string, record: any) => {
    //     return value ? value : "-";
    //   },
    // },
  ];

  componentDidMount = async () => {
    try {
      this.getFactoryLastDlump();
      const currentLanguage = i18n.language;
      moment.locale(currentLanguage);

      this.getSensorData();
      this.getMixtures();
      var c = this;
      this.settingData = JSON.parse(localStorage.getItem("deliveryData"));

      setInterval(function async() {
        c.getSensorData();
        c.getFactoryLastDlump();
      }, 3000);

      // this.sensor_number = this.getSensorNumber();
    } catch (error) {
      console.error(error);
    }
  };

  getSensorData = async () => {
    this.factoryId = this.props!.authStore!.user.factory_id;
    let sensors = await axios.get(
      `${process.env.REACT_APP_API_URL}/sensors?factory_id=${this.factoryId}&sensor_type=1`
    );

    this.sensors = sensors.data.sensors_data;

    this.sensor_number = await this.getSensorNumber();
    this.saveTrackNumberToLovalStorege(this.sensor_number);
    this.sensors = sensors.data.sensors_data;
    this.setBoxData();
  };


  getSensorLastScr = async (sensor_id:any) => {
    let last_scr = await axios.get(
      `${process.env.REACT_APP_API_URL}/iot/last-scr/${sensor_id}`
    );
   
    return last_scr.data;
  };


  getMixtures = async () => {
    this.factoryId = this.props!.authStore!.user.factory_id;
    let mixture = await axios.get(`${process.env.REACT_APP_API_URL}/mixture`);

    this.mixture_data = mixture.data;
    console.log({ data: this.mixture_data });
  };

  getFactoryLastDlump = async () => {
    this.factoryId = this.props!.authStore!.user.factory_id;

    let urlParams: Record<string, any> = {};
    urlParams.factory_id = this.factoryId;

    try {
      let rsp = await axios.get(
        `${process.env.REACT_APP_API_URL}/calculate/slump/getLatest?` +
          parseObjectToUrlParams(urlParams)
      );

      this.data = rsp.data.percentages.slice(0, 5);
    } catch (error) {
      console.error(error);
    }
  };

  saveTrackNumberToLovalStorege = async (track_number: string) => {
    localStorage.setItem("track_number", track_number);
  };

  getSensorNumber = () => {
    const track_number = localStorage.getItem("track_number");
    return track_number;
  };

  changeSensorNumber = (track_number: string) => {
    this.sensor_number = track_number;
    this.saveTrackNumberToLovalStorege(track_number);
    this.getTrackHistory(track_number);
  };

  openPopAddons = (sensor_number: string) => {
    this.sensor_number = sensor_number;
    this.litter_to_add_display = true;
    this.saveTrackNumberToLovalStorege(sensor_number);
    this.getTrackHistory(sensor_number);
  };

  getTrackHistory = async (track_number: string) => {
    try {
      const now = Date.now();
      const yesterdayDate = now - 24 * 60 * 60 * 1000;
      const yesterday = new Date(yesterdayDate).getTime();

      let track_history_data = await axios.get(
        `${process.env.REACT_APP_API_URL}/flow_commands?` +
          parseObjectToUrlParams({
            sensor_id: this.sensor_number,
            date: {
              start: yesterday,
              end: now,
            },
          })
      );
      let threeMinAgo = moment().subtract(3, "minutes").toDate().getTime();
      const updatedColumns = track_history_data?.data?.map((record: any) => {
        // Check if the conditions are met
        if (record.time_stamp < threeMinAgo && record.status === "2") {
          // If conditions are met, update the status to 12
          return { ...record, status: "12" };
        } else {
          // If conditions are not met, return the record as-is
          return record;
        }
      });
      this.track_history_data = updatedColumns;
    } catch (error) {
      console.error(error);
    }
  };

  setBoxData = () => {
    this.boxData = [
      {
        title: t("active"),
        sum: this.sensors?.filter((sensor: any) => sensor.delivery_status === 1)
          .length,
        description: t("onWay"),
      },
      {
        title: t("waiting"),
        sum: this.sensors?.filter((sensor: any) => sensor.delivery_status === 2)
          .length,
        description: t("beLoaded"),
      },
      {
        title: t("empty"),
        sum: this.sensors?.filter((sensor: any) => sensor.delivery_status === 3)
          .length,
        description: t("wayToFactory"),
      },
      {
        title: t("off"),
        sum: this.sensors?.filter((sensor: any) => sensor.delivery_status === 0)
          .length,
      },

      {
        title: t("total"),
        sum: this.sensors?.length,
      },
    ];
  };

  getClassName = (slump_live_prediction: any, slump_orderd: any) => {
    let className = "sensor_name";

    if (slump_live_prediction === 0) {
      return className;
    }

    const difference = slump_live_prediction - slump_orderd;

    if (difference > 40) {
      className += " green_2";
    } else if (difference > 20) {
      className += " green_1";
    } else if (difference < -40) {
      className += " red_2";
    } else if (difference < -20) {
      className += " red_1";
    }

    return className;
  };
  render() {
    return (
      <WaterAddonsStyled>
        {this.litter_to_add_display && <div className="fixed-scree"></div>}
        <div
          className={
            "flex  gap20 wrap-page " + (!this.show_bar ? "full_data" : "")
          }
        >
          <div className={"sensor_group_2 flex1 padding20 full"}>
            <div className="flex full">
              {this.boxData.map((item: any) => {
                return (
                  <div className="box-sum layout-box" key={item.id}>
                    <div className="flex gap10">
                      <div className="box-sum">{item.sum}</div>
                      <div className="flex flexCol">
                        <div
                          className={
                            "box-title " + (item.description ? "" : "no-desc")
                          }
                        >
                          {item.title}
                        </div>
                        <div className="box-desc">{item.description}</div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="f18 bold marginHor20">{t("load")}</div>
            <div className="sensor_status    flex gap20  ">
              {this.sensors?.some(
                (sensor: any) => sensor.delivery_status === 1
              ) ? (
                this.sensors?.map((sensor: any) => {
                  if (sensor.delivery_status === 1) {
                    return (
                      <div
                        key={sensor.sensor_id}
                        className={
                          "sensor " +
                          (sensor.sensor_id === Number(this.sensor_number)
                            ? "active"
                            : "")
                        }
                        onClick={() =>
                          this.changeSensorNumber(sensor.sensor_id)
                        }
                      >
                        <div
                          className={
                            "sensor-wrap relative" +
                            (sensor.delivery_status === 1 ? "" : " non_active")
                          }
                        >
                          <div
                            className={
                              sensor.product_name
                                ? this.getClassName(
                                    sensor.delivery_status,
                                    sensor.slump_orderd
                                  )
                                : this.getClassName(0, 0)
                            }
                          >
                            <div className="bold t1">
                              {t("sensor")} : {sensor.sensor_id}
                            </div>
                            {sensor.delivery_status === 1 && (
                              <>
                                <div className="t2">
                                  {t("slumpLive")}:{" "}
                                  {sensor.slump_live_prediction}
                                </div>
                           

                                <div className="t2">
                                  {t("temperature")}: {sensor.last_temp}
                                </div>
                           

                           
                                
                                {sensor.delivery_status === 1&& sensor.lastRecord && sensor.group_id === 3&&  (
                                          <>
                                            <div className="t2">{t("rpm")}: {sensor.lastRecord.rotation}</div>
                                          </>
                                )}

                                {sensor.last_event === "Unloading Start" && (
                                  <div className="unloading_mixer">
                                    <svg
                                      width="32"
                                      height="33"
                                      viewBox="0 0 32 33"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <rect
                                        y="0.5"
                                        width="32"
                                        height="32"
                                        rx="16"
                                        fill="#F0F5FF"
                                      />
                                      <path
                                        d="M30.3842 16.2819L28.9561 11.2797C28.7523 10.6039 28.1251 10.1318 27.4301 10.1318H21.659C20.781 10.1318 20.0669 10.8459 20.0669 11.7239V18.0921H17.6789C17.2395 18.0921 16.8828 18.4479 16.8828 18.8881C16.8828 19.3283 17.2395 19.6841 17.6789 19.6841H20.0669C20.9449 19.6841 21.659 18.9701 21.659 18.0921V11.7239H23.251V16.5C23.251 16.9402 23.6076 17.296 24.047 17.296H28.8232V21.2762H27.1181C26.7623 19.9062 25.5261 18.8881 24.047 18.8881C22.568 18.8881 21.3318 19.9062 20.976 21.2762H16.7698C16.414 19.9062 15.1778 18.8881 13.6987 18.8881C12.2197 18.8881 10.9835 19.9062 10.6277 21.2762H8.92261V19.6841H10.1485C10.5879 19.6841 10.9445 19.3283 10.9445 18.8881C10.9445 18.4479 10.5879 18.0921 10.1485 18.0921H8.92261C8.0446 18.0921 7.33057 18.8061 7.33057 19.6841V21.2762C7.33057 22.1542 8.0446 22.8682 8.92261 22.8682H10.6277C10.9835 24.2382 12.2197 25.2563 13.6987 25.2563C15.1778 25.2563 16.414 24.2382 16.7698 22.8682H20.976C21.3318 24.2382 22.568 25.2563 24.047 25.2563C25.5261 25.2563 26.7623 24.2382 27.1181 22.8682H28.8232C29.7012 22.8682 30.4152 22.1542 30.4152 21.2762V16.5C30.4152 16.426 30.4049 16.352 30.3842 16.2819ZM13.6987 23.6642C12.8207 23.6642 12.1067 22.9502 12.1067 22.0722C12.1067 21.1942 12.8207 20.4801 13.6987 20.4801C14.5768 20.4801 15.2908 21.1942 15.2908 22.0722C15.2908 22.9502 14.5768 23.6642 13.6987 23.6642ZM24.047 23.6642C23.169 23.6642 22.455 22.9502 22.455 22.0722C22.455 21.1942 23.169 20.4801 24.047 20.4801C24.9251 20.4801 25.6391 21.1942 25.6391 22.0722C25.6391 22.9502 24.9251 23.6642 24.047 23.6642ZM24.8431 11.7239H27.4293L28.5645 15.704H24.8431V11.7239Z"
                                        fill="#0E9F6E"
                                      />
                                      <path
                                        d="M12.4543 17.8733C13.2423 18.0946 14.8217 18.0985 15.6949 18.089C16.2879 18.1312 16.8603 17.8223 17.1628 17.2993L19.0581 14.0157C19.3606 13.4928 19.3415 12.8543 19.0398 12.4014C18.8408 12.044 17.8179 10.2322 17.201 9.65194C16.553 9.0406 15.0262 8.11005 14.1028 7.87204C13.0059 7.58627 10.1872 7.86089 9.70402 7.91502C9.14999 7.92458 8.63417 8.22786 8.35874 8.70786L7.21486 10.686C6.93864 11.166 6.93386 11.7638 7.17108 12.1857C7.39794 12.6943 8.5673 15.2711 9.36253 16.0806C10.0328 16.7596 11.6009 17.6162 12.4543 17.8733ZM14.2549 9.62329C14.6983 9.83025 15.2181 10.1383 15.6232 10.4241L12.3357 16.1188C11.8851 15.9103 11.3598 15.615 10.9578 15.334L14.2549 9.62329ZM15.7299 16.4977C15.2236 16.5065 14.5494 16.4977 13.9731 16.4675L16.744 11.6683C17.0617 12.1578 17.4143 12.7509 17.6778 13.2213L15.7299 16.4977ZM9.80114 9.50229C10.6027 9.41871 11.718 9.34309 12.5808 9.33831L9.87358 14.0285C9.44372 13.2738 8.94302 12.2541 8.59437 11.4812L9.80114 9.50229Z"
                                        fill="#0E9F6E"
                                      />
                                      <path
                                        d="M0.779833 18.1002L4.27361 14.0689C4.92697 13.315 6.16033 13.8777 6.01925 14.8653L5.21299 20.5091C5.11185 21.217 4.32181 21.5931 3.70855 21.2251L1.02103 19.6126C0.488186 19.2929 0.372864 18.5698 0.779833 18.1002Z"
                                        fill="#FF6700"
                                      />
                                    </svg>
                                  </div>
                                )}
                                {sensor.product_name ?? (
                                  <div className="t2">
                                    {sensor.product_name}
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                          {/* {sensor.delivery_status === 1 && (
                            <div
                              className="addon-add"
                              onClick={() =>
                                this.openPopAddons(sensor.sensor_id)
                              }
                            >
                              +
                            </div>
                          )} */}
                        </div>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })
              ) : (
                <div>{t("noActiveShipemnt")}</div>
              )}
            </div>

            <div className="table-data">
              <div className="">
                <div className="title-2 marginTop20">
                  {/* <span className="f14"> {t("sensorNumber")}: </span> */}

                  {/* <span className="f14">
                    {" "}
                    {this.sensor_number && this.sensor_number !== "null"
                      ? this.sensor_number
                      : ""}
                  </span> */}
                  <div className="f18 bold marginTop60">
                    {t("LastOrdersSlump")}
                  </div>
                  <br />
                </div>
                <div className="flex full">
                  <div className="box full">
                    <Table
                      dataSource={this.data}
                      pagination={false}
                      columns={this.columns}
                      rowKey={(record) => record.date_upload}
                    />
                    {/* <TrackHistory trackHistory={this.track_history_data} /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.show_bar ? (
            <div className="sensor_group_1">
              <div className="sensor_status_item  scrollbar flex flexCol gap20 full">
                <div className="flex full justify-between">
                  <div className="title">{t("mixers")}</div>

                  <svg
                    className="arrow"
                    onClick={() => (this.show_bar = !this.show_bar)}
                    width="50"
                    height="50"
                    viewBox="0 0 50 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="50" height="50" rx="5" fill="#FF6700" />
                    <path
                      d="M21.6667 18.3333L28.3334 24.9999L21.6667 31.6666"
                      stroke="#2D2D2D"
                      strokeWidth="2"
                      strokeLinecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>

                <div className="flex select-filter">
                  <div className="item active">{t("all")}</div>
                  <div className="item">
                    {t("waiting")} (
                    {
                      this.sensors?.filter(
                        (sensor: any) => sensor.delivery_status === 2
                      ).length
                    }
                    )
                  </div>
                  <div className="item">
                    {t("off")} (
                    {
                      this.sensors?.filter(
                        (sensor: any) => sensor.delivery_status === 0
                      ).length
                    }
                    )
                  </div>
                </div>

                <div className="flex flex-col height-scroll scrollbar full">
                  {this.sensors.map((sensor: any) => {
                    return (
                      <div className="sensor" key={sensor.sensor_id}>
                        <div className="">
                          {t("sensor")}: {sensor.sensor_id}
                        </div>

                        <div className="flex full">
                          <ul className="full">
                            <li className="flex full justify-between">
                              <div className="name">{t("timeInRest")}</div>
                              <div className="item">
                                {" "}
                                {sensor.last_unloading
                                  ? moment(sensor.last_unloading).fromNow()
                                  : "-"}
                              </div>
                            </li>
                            <li className="flex full justify-between">
                              <div className="name">
                                {" "}
                                {t("numberOfLoadsToday")}
                              </div>
                              <div className="item">
                                {" "}
                                {sensor.total_delivery}
                              </div>
                            </li>
                            <li className="flex full justify-between">
                              <div className="name">
                                {t("lastLoadDuration")}
                              </div>
                              <div className="item">
                                {sensor.last_delivery_time
                                  ? sensor.last_delivery_time +
                                    " " +
                                    t("minuts")
                                  : "-"}
                              </div>
                            </li>
                            <li className="flex full justify-between">
                              <div className="name">
                                {" "}
                                {t("driverIntervention")}
                              </div>
                              <div className="item">
                                {" "}
                                {sensor.driver_addons
                                  ? sensor.driver_addons
                                  : "-"}
                              </div>
                            </li>
                            <li className="flex full justify-between">
                              <div className="name">
                                {" "}
                                {t("differencesInCertified")}
                              </div>
                              <div className="item">
                                {" "}
                                {sensor.slump_diff_sum
                                  ? sensor.slump_diff_sum
                                  : "-"}
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : (
            <div className="sensor_group_1 close_bar">
              <div className="sensor_status_item   flex flexCol gap20">
                <div className="flex">
                  <svg
                    className="arrow"
                    onClick={() => (this.show_bar = !this.show_bar)}
                    width="50"
                    height="50"
                    viewBox="0 0 50 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="50"
                      y="50"
                      width="50"
                      height="50"
                      rx="5"
                      transform="rotate(-180 50 50)"
                      fill="#FF6700"
                    />
                    <path
                      d="M28.3333 31.6666L21.6666 25L28.3333 18.3333"
                      stroke="#2D2D2D"
                      strokeWidth="2"
                      strokeLinecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>

                <div className="flex flex-col height-scroll ">
                  {this.sensors?.map((sensor: any) => {
                    return (
                      <div className="sensor" key={sensor.sensor_id}>
                        <div className="">{sensor.sensor_id}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
        {this.litter_to_add_display ? (
          <div className="alert_add marginTop20">
            <>
              <div>{this.litter_to_add_message}</div>
              <div className="flex flexCol gap10 full">
                <div className="relative">
                  <AddItem2
                    recordId={
                      this.track_history_data
                        ? this.track_history_data[
                            this.track_history_data.length - 1
                          ].id
                        : null
                    }
                    recordStatus={
                      this.track_history_data &&
                      this.track_history_data[
                        this.track_history_data.length - 1
                      ].flow_command !== "STOP_FLOW"
                        ? Number(
                            this.track_history_data[
                              this.track_history_data.length - 1
                            ].status
                          )
                        : null
                    }
                    // slump_live_prediction={thissensor?.slump_live_prediction}
                    sensorId={this.sensor_number}
                  />
                </div>

                <div
                  className="close"
                  onClick={() => (this.litter_to_add_display = false)}
                >
                  x
                </div>
              </div>
            </>
          </div>
        ) : null}
      </WaterAddonsStyled>
    );
  }
}
export default withRouter(WaterAddons);
