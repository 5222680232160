import { t } from "i18next";

const roleToString = (role: string): string => {
  switch (role) {
    case "admin":
      return t("manger");

    case "tech":
      return t("factory");

    case "driver":
      return t("driver");
  }
};
export default roleToString;
