import CementStateStyled from "./styled";

import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Card, List, Select, Statistic } from "antd";
import { observable } from "mobx";
import axios from "axios";
import { observer } from "mobx-react";
import ConcreteTruck from "../../assets/concrete-mixer.svg";
import RPMSvg from "../../assets/rpm.svg";
import { parseSensorToReal } from "../../utils/cementStateToFillRate";
import moment from "moment";
import SlumpSlider from "../SlumpSlider/SlumpSlider";
import GroupsHistory from "./groupsHistory/Groups";
import { useQuery } from "react-query";

const getPredictionData = async (deviceId: string | number) => {
  const rsp = await axios.get<number>(
    `${process.env.REACT_APP_API_URL}/iot/getCurrentPediction/${deviceId}`
  );

  return rsp.data;
};

const usePredictionData = (deviceId: any): any => {
  const { data: calculate_data, isLoading: isLoadingPredictionData } = useQuery(
    ["current-prediction", deviceId],
    () => getPredictionData(deviceId),
    { refetchInterval: 5000 }
  );

  return { calculate_data, isLoadingPredictionData };
};

interface Props extends RouteComponentProps<any> {
  cementType: string;
  deviceId?: number | string;
}

const CementState = ({ deviceId }: Props) => {
  const { calculate_data, isLoadingPredictionData } =
    usePredictionData(deviceId);

  return (
    <CementStateStyled rpmRate={60 / calculate_data?.rpm + "s"}>
      <div className="content-page">
        <Card className="flex flexRow data-card justify-betweenl card-mixer-info">
          <div className="flex">
            <List className="data-list  container">
              <div className="flex flexRow">
                <div className="title">רמת מילוי</div>
                <div className="value_date">{calculate_data?.fullness}</div>
              </div>

              <div className="flex flexRow">
                <div className="title">חיזוי</div>
                <div className="value_date">{calculate_data?.slump_avg}</div>
              </div>

              <div className="flex flexRow">
                <div className="title">יחס מים צמנט</div>
                <div className="value_date">{calculate_data?.wc}</div>
              </div>

              <div className="flex flexRow">
                <div className="title">רמת שקיעה</div>
                <div className="value_date">{calculate_data?.wslump_avgc}</div>
              </div>

              <SlumpSlider slumpRate={calculate_data?.level} />

              <List.Item>{`עדכון אחרון: ${moment(
                calculate_data?.updatedAt
              ).format("HH:mm DD/MM/YY")} `}</List.Item>
            </List>
            <div className="pretty-cement-data flex justify-between container">
              <div className="paddingVer20">
                <img
                  className="concrete-truck"
                  src={ConcreteTruck}
                  alt="concrete truck"
                  height={180}
                />
              </div>
              <div className="flex flexRow">
                {/* <img
                    className="rpm-icon"
                    src={RPMSvg}
                    alt="spin"
                    height={120}
                  /> */}

                <div className="title">סל"ד</div>

                <div className="value_date">{calculate_data?.rpm}</div>
              </div>
            </div>
          </div>
          {/* )} */}

          <GroupsHistory deviceId={deviceId} />
        </Card>
      </div>
    </CementStateStyled>
  );
};

export default withRouter(CementState);
