const axios = require("axios");

export type OptionsRequest = {
  abort: any;
  promise: Promise<any>;
};

interface BaseService {
  get(path: string, options?: {}): any;
  post(path: string, data?: {}, options?: {}): OptionsRequest;
  put(path: string, data?: {}, options?: {}): OptionsRequest;
  del(path: string, options?: {}): OptionsRequest;
}
interface ICommonOptions {
  method?: "GET" | "POST" | "PUT" | "DELETE";
  mode?: "no-cors" | "cors" | "same-origin";
  cache?: "default" | "no-cache" | "reload" | "force-cache" | "only-if-cached";
  credentials?: "include" | "same-origin" | "omit";
  headers?: { [key: string]: string };
  redirect?: "manual" | "follow" | "error";
  referrerPolicy?:
    | "no-referrer"
    | "no-referrer-when-downgrade"
    | "origin"
    | "origin-when-cross-origin"
    | "same-origin"
    | "strict-origin"
    | "strict-origin-when-cross-origin"
    | "unsafe-url";
  body?: any;
}

const isServer = typeof window === "undefined";

class BaseHttpService implements BaseService {
  baseUrl: string = "http://localhost:3001/";

  commonOptions: ICommonOptions = {
    method: "GET",
    mode: "no-cors",
    cache: "no-cache",
    // credentials: "same-origin",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  };

  constructor(baseUrl: string = "") {
    this.baseUrl = baseUrl;
  }

  // get(path: string, options: {} = {}): OptionsRequest {
  //   const controller = isServer ? ({} as any) : new AbortController();
  //   const { signal } = controller;

  //   const response: Promise<any> = fetch(`${this.baseUrl}${path}`, {
  //     ...this.commonOptions,
  //     ...options,
  //     ...{ method: "GET" },
  //     signal,
  //   });

  //   return {
  //     promise: this.throwByStatusCode(response),
  //     abort: isServer ? () => {} : controller.abort.bind(controller),
  //   } as OptionsRequest;
  // }

  get(path: string, options: {} = {}) {
    axios.get(`${this.baseUrl}${path}`).then(function (response: any) {
      // handle success
      return response.data;
    });
  }

  post(path: string, data?: {}, options: ICommonOptions = {}): OptionsRequest {
    const controller = isServer ? ({} as any) : new AbortController();
    const { signal } = controller;
    const response: Promise<any> = fetch(`${this.baseUrl}${path}`, {
      ...this.commonOptions,
      ...options,
      ...{ method: "POST" },
      signal,
      body: JSON.stringify(data),
    });

    return {
      promise: this.throwByStatusCode(response),
      abort: isServer ? () => {} : controller.abort.bind(controller),
    } as OptionsRequest;
  }

  buildFormData = (
    formData: FormData,
    data: any,
    parentKey?: string | undefined
  ) => {
    if (
      data &&
      typeof data === "object" &&
      !(data instanceof Date) &&
      !(data instanceof File)
    ) {
      Object.keys(data).forEach((key) => {
        this.buildFormData(
          formData,
          data[key],
          parentKey ? `${parentKey}[${key}]` : key
        );
      });
    } else {
      const value = data == null ? "" : data;

      formData.append(parentKey || "", value);
    }
  };

  formData(
    path: string,
    data: any = {},
    options: ICommonOptions = {}
  ): OptionsRequest {
    const controller = isServer ? ({} as any) : new AbortController();
    const { signal } = controller;

    let formData = new FormData();

    this.buildFormData(formData, data);

    const response: Promise<any> = fetch(`${this.baseUrl}${path}`, {
      ...options,
      ...{ method: data.id ? "PUT" : "POST", credentials: "include" },
      signal,
      body: formData,
    });

    return {
      promise: this.throwByStatusCode(response),
      abort: isServer ? () => {} : controller.abort.bind(controller),
    } as OptionsRequest;
  }

  put(path: string, data?: {}, options: ICommonOptions = {}): OptionsRequest {
    const controller = isServer ? ({} as any) : new AbortController();
    const { signal } = controller;
    const response: Promise<any> = fetch(`${this.baseUrl}${path}`, {
      ...this.commonOptions,
      ...options,
      ...{ method: "PUT" },
      signal,
      body: JSON.stringify(data),
    });

    return {
      promise: this.throwByStatusCode(response),
      abort: isServer ? () => {} : controller.abort.bind(controller),
    } as OptionsRequest;
  }

  del(path: string, options: ICommonOptions = {}): OptionsRequest {
    const controller = isServer ? ({} as any) : new AbortController();
    const { signal } = controller;
    const response: Promise<any> = fetch(`${this.baseUrl}${path}`, {
      ...this.commonOptions,
      ...options,
      ...{ method: "DELETE" },
      signal,
    });

    return {
      promise: this.throwByStatusCode(response),
      abort: isServer ? () => {} : controller.abort.bind(controller),
    } as OptionsRequest;
  }

  parseObjectToUrlParams = (obj: any, prefix?: any): any => {
    var str = [],
      p;
    for (p in obj) {
      if (obj.hasOwnProperty(p)) {
        var k = prefix ? prefix + "[" + p + "]" : p,
          v = obj[p];
        str.push(
          v !== null && typeof v === "object"
            ? this.parseObjectToUrlParams(v, k)
            : // : k + "=" + v
              encodeURIComponent(k) + "=" + encodeURIComponent(v)
        );
      }
    }
    return str.join("&");
  };

  throwByStatusCode = (promise: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      promise
        .then((response: any) => {
          if (!response.ok) {
            reject(response);
          }
          resolve(response);
        })
        .catch((rejected: any) => {
          reject(rejected);
        });
    });
  };

  toJSON(): { [key: string]: any } {
    return {};
  }
}

export default BaseHttpService;
